import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth'
import axios from 'axios'
import store from './store'

const LOGIN_URL = '/smotrivezde/login/'
const ERROR_MESSAGES = {
  BLOCK: 'Уважаемый клиент, на Вашем счете установлена добровольная блокировка. Для возобновления пользования услугой обратитесь в контактный центр "АльянсТелеком" или снимите блокировку в Личном кабинете на сайте www.inetvl.ru',
  DEBT: 'Уважаемый клиент, услуга заблокирована в связи с отсутствием денежных средств на счете.<br/><br/>Пополните баланс счета №<b><u>{{abonement}}</u> <span style="color:#f23f3f">{{balance}}</span></b> рублей',
  INVALID_CREDS: 'Неправильный абонемент или пароль! Проверьте правильность ввода данных и попробуйте еще раз.',
  UNKNOWN: 'Неизвестная ошибка',
}
let blocked = false

function AuthError(message) {
  this.name = 'AuthError';
  this.message = message;
  this.stack = (new Error()).stack;
}
AuthError.prototype = Object.create(Error.prototype);
AuthError.prototype.constructor = AuthError;

export default {
  install(Vue) {
    Vue.use(VueAxios, axios)
    Vue.use(VueAuth, {
      http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
      router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
      loginData: {url: LOGIN_URL, method: 'GET', fetchUser: false},
      fetchData: {enabled: false},
      refreshData: {enabled: false},
      logoutData: {redirect: '/login'},
      auth: {
        request: function (req, token) {
          req.params.authkey = token
          req.params.mac = store.state.deviceInfo.mac
        },
        response: function (res) {
          return (res.data || {}).authkey
        }
      }
    })
    this.$auth = Vue.auth
    Vue.prototype.$appAuth = this
  },

  doLogin(abonement, password, mac) {
    return this.$auth.login({
      params: {abonement, password, mac},
    }).then(res => {
      if (res.data.account_id > 0) {
        store.commit('setSetting', {name: 'abonement', value: abonement})
        store.commit('setSetting', {name: 'password', value: password})
        location.reload()
        return
      }
      if (ERROR_MESSAGES[res.data.status_reason]) {
        throw new AuthError(this.formatMessage(ERROR_MESSAGES[res.data.status_reason], res.data))
      } else {
        throw new AuthError(ERROR_MESSAGES.INVALID_CREDS)
      }
    }, () => {throw new AuthError(ERROR_MESSAGES.UNKNOWN)})
  },

  doLogout() {
    store.commit('setSetting', {name: 'abonement', value: null})
    store.commit('setSetting', {name: 'password', value: null})
    store.dispatch('stop')
    this.$auth.logout()
  },

  checkBlock() {
    if (!this.$auth.check()) {
      return Promise.resolve()
    }
    return store.dispatch('updateAccountInfo').then(info => {
      if (info.frozen) {
        return
      }
      if (info.active) {
        if (blocked) {
          location.reload()
        }
      } else {
        blocked = true
        store.dispatch('stop')
        let errMessage = ERROR_MESSAGES[info.status]
        if (errMessage) {
          throw new AuthError(this.formatMessage(errMessage, info))
        } else {
          this.$auth.logout()
        }
      }
    })
  },

  getToken() {
    return this.$auth.token()
  },

  formatMessage(errMessage, info) {
    Object.keys(info).forEach(k => errMessage = errMessage.replace('{{'+k+'}}', info[k]))
    return errMessage
  }
}
