<template>
  <div class="account-view">
    <modal ref="confirmLogout" @confirm="logout" :need-confirm="true">
      Вы уверены, что хотите сменить учетную запись?
    </modal>
    <modal ref="freezeMsg">
      {{ freezeMsg }}
    </modal>
    <modal ref="deviceInfo">
      <div class="device-info">
        <div v-if="accountInfo.ip_address">
          <div class="info title">IP адрес</div>
          <div class="info value">{{accountInfo.ip_address}}</div>
        </div>
        <div>
          <div class="info title">MAC адрес</div>
          <div class="info value">{{deviceInfo.mac}}</div>
        </div>
        <div>
          <div class="info title">Модель</div>
          <div class="info value">{{deviceInfo.model}}</div>
        </div>
        <div v-if="deviceInfo.serial">
          <div class="info title">Серийный номер</div>
          <div class="info value">{{deviceInfo.serial}}</div>
        </div>
        <div>
          <div class="info title">Версия прошивки</div>
          <div class="info value">{{deviceInfo.firmware}}</div>
        </div>
        <div>
          <div class="info title">Версия приложения</div>
          <div class="info value">{{appVersion}}</div>
        </div>
        <div>
          <div class="info title">Разрешение</div>
          <div class="info value">{{deviceInfo.width}}x{{deviceInfo.height}}</div>
        </div>
        <div>
          <div class="info title">Соотношение px</div>
          <div class="info value">{{pixelRatio}}</div>
        </div>
        <br style="clear:both;"/>
      </div>
    </modal>
    <parent-code @confirm="changeParentCodeStep = 2" @close="closeKb" v-if="changeParentCodeStep == 1">
      Старый ПИН-код
    </parent-code>
    <virtual-keyboard v-model="parentCode" @input="setParentCode" @close="closeKb" :digital="true" v-if="changeParentCodeStep == 2">
      Новый ПИН-код
    </virtual-keyboard>
    <progress-loader ref="pbInfo"/>
    <div class="header-title">Личный кабинет</div>
    <div class="account-info">
      <div>
        <div class="info title">№ лицевого счета</div>
        <div class="info value">{{accountInfo.abonement}}</div>
      </div>
      <div v-if="!accountInfo.frozen">
        <div class="info title">Баланс</div>
        <div class="info value">{{accountInfo.balance}} руб.</div>
      </div>
    </div>
    <br style="clear:both"/>
    <ul class="account-menu">
      <li v-for="(item, index) in menuItems" :key="item.label" :class="{active: menuIndex === index}" @mouseover="menuIndex = index" @click="item.action">
        {{item.label}}
      </li>
    </ul>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="blue" @click="btnBlue"/>
        <span>Тех. инфо</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus v-if="changeParentCodeStep == 0"
      @up="btnUp"
      @down="btnDown"
      @enter="btnEnter"
      @blue="btnBlue"
      @keydown="checkDebugCombination"
    />
    <controls-event-bus @back="btnBack"/>
  </div>
</template>

<script>
import ProgressLoader from '@/components/ProgressLoader.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import ParentCode from '@/components/ParentCode.vue'
import Modal from '@/components/Modal.vue'
import utils from '@/utils'
import { mapState } from 'vuex'

const DEBUG_COMBINATION = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right']
let keyCombination = []
let keyTimeout
let lastMenuIndex = 0

export default {
  data() {
    return {
      menuIndex: lastMenuIndex,
      menuItems: [
        {label: 'Управление пакетами каналов', action: this.chPackets},
        {label: 'Настройки', action: this.settings},
        {label: 'Сменить ПИН-код', action: this.changeParentCode},
        {label: 'Техническая информация', action: this.btnBlue},
        {label: 'Руководство пользователя', action: this.instruction},
        {label: 'Сменить учетную запись', action: this.btnRed},
      ],
      parentCode: '',
      changeParentCodeStep: 0,
      freezeMsg: '',
    }
  },
  methods: {
    btnUp() {
      if (--this.menuIndex < 0) {
        this.menuIndex = this.menuItems.length - 1
      }
    },
    btnDown() {
      if (++this.menuIndex >= this.menuItems.length) {
        this.menuIndex = 0
      }
    },
    btnEnter() {
      this.menuItems[this.menuIndex].action()
    },
    btnRed() {
      this.$refs.confirmLogout.show()
    },
    changeParentCode() {
      this.changeParentCodeStep = 1
    },
    settings() {
      this.$router.replace({name: 'settings'})
    },
    chPackets() {
      if (this.accountInfo.frozen) {
        this.freezeMsg = utils.winkMigrationMessage()
        this.$refs.freezeMsg.show()
      } else {
        this.$router.replace({name: 'chpackets'})
      }
    },
    instruction() {
      this.$router.replace({name: 'instruction'})
    },
    closeKb() {
      this.parentCode = ''
      this.changeParentCodeStep = 0
    },
    setParentCode() {
      this.$refs.pbInfo.show('loader', 'Установка нового ПИН-кода')
      this.$api.accountEdit(this.parentCode).then(res => {
        this.$refs.pbInfo.show(res.type, res.message, 3000)
        if (res.type == 'success') {
          this.closeKb()
          this.$store.dispatch('updateAccountInfo')
        }
      }).catch(() => this.$refs.pbInfo.show('error', 'Не удалось выполнить операцию! Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    },
    btnBlue() {
      this.$refs.deviceInfo.show()
    },
    btnBack() {
      if (this.changeParentCodeStep) {
        this.parentCode = ''
        this.changeParentCodeStep = 0
      } else {
        this.$router.replace({name: 'home'})
      }
    },
    logout() {
      this.$appAuth.doLogout()
    },
    checkDebugCombination(e) {
      clearTimeout(keyTimeout)
      keyCombination.push(e.keyName)
      while (keyCombination.length > DEBUG_COMBINATION.length) {
        keyCombination.shift()
      }
      if (
        keyCombination.length === DEBUG_COMBINATION.length
        && keyCombination.every(function(value, index) { return value === DEBUG_COMBINATION[index]})
      ) {
        this.$store.commit('toggleDebug')
      }
      keyTimeout = setTimeout(() => keyCombination = [], 1000)
    }
  },
  created() {
    this.$store.dispatch('updateAccountInfo')
  },
  beforeDestroy() {
    clearTimeout(keyTimeout)
    lastMenuIndex = this.menuIndex
  },
  computed: {
    ...mapState(['accountInfo', 'deviceInfo']),
    pixelRatio() {
      return window.devicePixelRatio
    },
    appVersion() {
      return APP_VERSION
    }
  },
  components: {
    ProgressLoader,
    VirtualKeyboard,
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    ParentCode,
    Modal,
  }
}
</script>

<style lang="scss" scoped>
  .account-info {
    width: 600px;
    margin-left: 50px;
    .info {
      width: 300px;
      float: left;
      font-weight: 400;
      font-size: 28px;
      color: #868686;
      text-align: left;
      width: 300px;
      margin-bottom: 21px;
      height: 28px;
      line-height: 28px;
      &.value {
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .account-menu {
    width: 600px;
    margin-left: 40px;
    li {
      padding-left: 10px;
      color: #868686;
      font-size: 28px;
      font-weight: 400;
      height: 52px;
      line-height: 52px;
      margin: 5px 0 0 0;
      border-radius: 5px;
      &.active {
        color: $color-dark;
        background-color: $color-primary;
        .arrow-left, .arrow-right {
          display: block;
        }
      }
    }
  }
  .device-info {
    width: 600px;
    margin: -14px auto 0;
    .info {
      font-weight: 400;
      font-size: 20px;
      color: #868686;
      text-align: left;
      width: 200px;
      margin-top: 14px;
      float: left;
      height: 20px;
      line-height: 20px;
      &.value {
        width: 400px;
        color: white;
        margin-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
