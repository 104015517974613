import axios from 'axios'
import utils from './utils'

const API_URL = process.env.VUE_APP_API_URL || '/api'
const API_KEY = process.env.VUE_APP_API_KEY || 'DXOceDurVbuTNFarfTjU7QKBK8tmA0bFAZ7M54eepBviJ0cACqwsejU5SB9PHenD'

axios.defaults.baseURL = API_URL
axios.defaults.params = {}

export default {
  install(Vue) {
    Vue.prototype.$api = this
  },

  /**
   * @returns number
   */
  getUtcTime() {
    return axios.get('/smotrivezde/timestamp').then(res => res.data)
  },

  /**
   * @returns {version critical}
   */
  getVersionInfo() {
    return axios.get('version.json', {baseURL: '', params: {v: utils.getUnixTime()}}).then(res => res.data)
  },

  /**
   * @returns {version date log}
   */
  getChangeLog() {
    return axios.get('/smotrivezde/changelog', {params: {branch: APP_ENV}}).then(res => res.data)
  },

  /**
   * @returns {
   *  active
   *  status
   *  abonement
   *  balance
   *  packets: array
   *  parent_code
   *  redirect_url
   *  ip_address
   * }
   */
  getAccountInfo() {
    return axios.get('/smotrivezde/account/info').then(res => res.data)
  },

  accountSetDeviceInfo(deviceInfo) {
    return axios.get('/smotrivezde/account/set-device-info', {params: deviceInfo}).then(res => res.data)
  },

  /**
   * @returns {categories: [{title, blocks: [title, programs:[]]]}
   */
  getCatalogBlocks(type, offset) {
    return axios.get('/smotrivezde/catalog/blocks', {params: {type, offset}})
      .then(res => res.data.categories)
  },

  /**
   * @returns [{id, name, channel_id, begin_time, content_id}]
   */
  catalogSearch(q) {
    return axios.get('/smotrivezde/catalog/search', {params: {q}}).then(res => res.data)
  },

  catalogContentView(content_id, inc_watch) {
    return axios.get('/smotrivezde/catalog/content/view', {params: {content_id, inc_watch}}).then(res => res.data)
  },

  getCatalogContentDetails(id) {
    return axios.get('/smotrivezde/catalog/content/details', {params: {id}}).then(res => res.data)
  },

  getRecommendedPrograms() {
    return axios.get('/smotrivezde/program/recommended').then(res => {
      res.data.forEach(program => program.duration = Math.floor((program.end_time - program.begin_time) / 60))
      return res.data
    })
  },

  /**
   * @returns [{
   *  id
   *  category_id
   *  icon_id
   *  num
   *  name
   *  url
   *  archive_url
   *  archive_days
   *  is_parent_control
   *  packet_id
   *  has_epg
   * }]
   */
  getChannelList() {
    return axios.get('/smotrivezde/program/channel/list')
      .then(res => res.data)
      .then(channels => {
        channels.forEach(ch => {
          ch.has_record = ch.archive_days > 0
          ch.program_name = null
          ch.program_description = null
          ch.program_begin_time = null
          ch.program_end_time = null
          ch.begin_time = null
          ch.end_time = null
          ch.progress = null
          ch.is_avail = false
        })
        return channels
      })
  },

  /**
   * @returns {channel_id: [{id, name, episode, genre, description, year, country, begin_time, end_time, image_url, poster_url, type, rating}]}
   */
  getPrograms(cid, time_from, time_to) {
    return axios.get('/smotrivezde/program/list', {params: {cid, time_from, time_to}})
      .then(res => processPrograms(res.data))
  },

  /**
   * @returns {channel_id: [{id, name, episode, genre, description, year, country, begin_time, end_time, image_url, poster_url, type, rating}]}
   */
  getChannelsProgram(cids) {
    return axios.get('/smotrivezde/channels/program', {params: {cids}})
      .then(res => processPrograms(res.data))
  },

  /**
   * @returns [{id name is_adult}]
   */
  getCategoryList() {
    return axios.get('/smotrivezde/channels/category/list').then(res => res.data)
  },

  /**
   * @returns [{size url}]
   */
  getChannelIconSprites() {
    return axios.get('/smotrivezde/channels/icons/sprites').then(res => res.data)
  },

  getTextTemplates() {
    return axios.get('/smotrivezde/text/templates').then(res => res.data)
  },

  /**
   * @returns [{
   *  id
   *  title
   *  cost
   *  is_linked
   *  is_unlinkable
   *  is_demo_available
   *  link_date
   *  img
   *  min_link_days
   *  demo_days
   *  demo_period_until
   *  demo_period_until
   *  channels_count
   *  short_description
   *  description
   * }]
   */
  getPacketList() {
    return axios.get('/smotrivezde/packets/list')
      .then(res => res.data)
      .then(packets => {
        packets.forEach(packet => {
          packet.channels_text = packet.channels_count + ' ' + utils.plural(packet.channels_count, 'канал', 'канала', 'каналов')
          if (packet.is_unlinkable) {
            packet.class_name = 'disabled'
          } else if (packet.is_linked) {
            let can_unlink = true
            if (!packet.demo_period_until && packet.link_date) {
              can_unlink = utils.getUnixTime() - packet.link_date > packet.min_link_days * 86400
            }
            packet.cant_unlink = !can_unlink
            packet.class_name = can_unlink ? 'unlink' : 'cant-unlink'
          } else if (packet.is_demo_available) {
            const d = utils.dateFromUnixTime(utils.getUnixTime() + packet.demo_days * 86400)
            packet.demo_date = utils.getDay(d) + ' ' + utils.getMonth(d) + ' ' + utils.getYear(d)
            packet.class_name = 'demo'
          } else {
            packet.class_name = 'link'
          }
        })
        return packets
      })
  },

  linkPacket(packet_id) {
    return axios.get('/smotrivezde/packets/link', {params: {packet_id}})
      .then(res => simpleApiResponse(res.data))
  },

  unlinkPacket(packet_id) {
    return axios.get('/smotrivezde/packets/unlink', {params: {packet_id}})
      .then(res => simpleApiResponse(res.data))
  },

  accountEdit(parent_code) {
    return axios.get('/smotrivezde/account/edit', {params: {parent_code}})
      .then(res => simpleApiResponse(res.data))
  },

  winkMigMessageShown() {
    axios.get('/smotrivezde/account/wink-mig-shown')
  },

  winkMsgConfirm(confirm) {
    return axios.post('/smotrivezde/account/wink-msg-confirm', {confirm}).then(res => res.data)
  },

  statPush(channel_session, channel_id, timeshift_offset) {
    axios.get('/reports/api/stat/push/', {params: {
      channel_session,
      channel_id,
      timeshift_offset,
      client_id: 1,
      api_key: API_KEY,
    }})
  },

  pushChannelStat(channel_id, duration, archive) {
    axios.get('/smotrivezde/channel/stat', {params: {channel_id, duration, archive}})
  },

  appLog(event, param, param_int) {
    axios.get('/smotrivezde/app/log', {params: {event, param, param_int, dt: utils.getUnixTime() * 1000}})
  },
}

function simpleApiResponse(data) {
  if (data.success) {
    data.type = 'success'
    data.message = data.success
  } else {
    data.type = 'error'
    data.message = data.error
  }
  return data
}

function processPrograms(programs) {
  const now = utils.getUnixTime()
  Object.keys(programs).forEach(channel_id => {
    programs[channel_id].forEach(program => {
      const begin = utils.dateFromUnixTime(program.begin_time)
      const end = utils.dateFromUnixTime(program.end_time)
      program.time = utils.getTime(begin)
      program.time_to = utils.getTime(end)
      program.live = now >= program.begin_time && now < program.end_time
      program.full_name = program.name + (program.episode ? '. ' + program.episode : '')
    })
  })
  return programs
}
