/*
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import store from './store'
import auth from './auth'

const WS_HOST = process.env.VUE_APP_WS_HOST || window.location.hostname
const WS_PORT = process.env.VUE_APP_WS_PORT || window.location.port
*/
let channel

export default {
  listen(/*abonement, mac*/) {
    /*
    if (channel) {
      return
    }

    const echo = new Echo({
      broadcaster: 'pusher',
      client: new Pusher('OTT', {
        wsHost: WS_HOST,
        wsPort: WS_PORT,
        wsPath: '/ws',
        enabledTransports: ['ws'],
        disableStats: true,
        authEndpoint: '/broadcasting/auth',
        auth: {
          headers: {
            'X-OTT-Token': auth.getToken()
          }
        }
      }),
      namespace: 'App.Events.OTT',
    })

    channel = echo.private('user.'+abonement+'.'+mac.replace(/[^a-z]/gi, ''))
      .listen('TestEvent', e => {
        store.commit('log', 'WS TestEvent: ' + JSON.stringify(e))
      })
    */
  },

  send(eventName, data) {
    if (channel) {
      channel.whisper(eventName, data)
    }
  },

  on(eventName, cb) {
    if (channel) {
      channel.stopListening(eventName).listen(eventName, data => cb(Object.assign({event: eventName}, data)))
    }
    return this
  },

  off(eventName) {
    if (channel) {
      channel.stopListening(eventName)
    }
    return this
  },
}
