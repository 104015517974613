<template>
  <div class="home-view">
    <modal ref="exitModal" @close="exitCancel" @confirm="exitConfirm" :need-confirm="true">
      Вы уверены, что хотите закрыть приложение?
      <br/><br/>
      Кнопка "Нет" переведёт Вас в полноэкранный режим плеера.
    </modal>
    <div v-for="(item, index) in menuItems" :key="item.icon" class="menu-item" :class="{active: currentIndex === index}" @mouseover="currentIndex = index">
      <router-link v-if="item.route" :to="item.route" tag="span" replace>
        <div class="icon" :class="item.icon"></div>
        <div class="label">{{item.label}}</div>
      </router-link>
      <span v-else @click="item.action">
        <div class="icon" :class="item.icon"></div>
        <div class="label">{{item.label}}</div>
      </span>
    </div>
    <controls-hint-bar>
      <div class="btn-group arrows">
        <control-hint-btn type="up" @click="btnUp"/>
        <control-hint-btn type="down" @click="btnDown"/>
        <control-hint-btn type="left" @click="btnLeft"/>
        <control-hint-btn type="right" @click="btnRight"/>
        <span>Переход по меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="enter" @click="btnEnter"/>
        <span>Выбор</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Выход</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
      @back="btnBack"
      @keydown="cancelHide"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import Modal from '@/components/Modal.vue'
import device from '@/device'
import utils from '@/utils'

let hideTimeout
let lastMenuIndex = 0
let firstTimeMenu = true

export default {
  data() {
    return {
      currentIndex: lastMenuIndex,
      confirmBack: !firstTimeMenu,
      menuItems: [
        {icon: 'icon-categories', label: 'ТВ по интересам', route: 'categories'},
        {icon: 'icon-videoarchive', label: 'Видеоархив', route: 'catalog'},
        {icon: 'icon-tvchannels', label: 'Телеканалы', route: 'tvchannels'},
        {icon: 'icon-account', label: 'Личный кабинет', route: 'account'},
        {icon: 'icon-youtube', label: 'YouTube', action: () => device.runYouTubeApp()},
        {icon: 'icon-settings', label: 'Настройки', route: 'settings'},
      ]
    }
  },
  created() {
    if (utils.isWinkMigration() && !utils.is501()) {
      this.menuItems = [
        {icon: 'icon-categories', label: 'ТВ по интересам', route: 'categories'},
        {icon: 'icon-wink', label: 'Wink', action: () => device.runWinkApp()},
        {icon: 'icon-tvchannels', label: 'Телеканалы', route: 'tvchannels'},
        {icon: 'icon-videoarchive', label: 'Видеоархив', route: 'catalog'},
        {icon: 'icon-youtube', label: 'YouTube', action: () => device.runYouTubeApp()},
        {icon: 'icon-account', label: 'Личный кабинет', route: 'account'},
      ]
    }
  },
  mounted() {
    if (firstTimeMenu && this.$store.state.playing) {
      hideTimeout = setTimeout(utils.returnToPlayer, 5000)
    }
  },
  beforeDestroy() {
    clearTimeout(hideTimeout)
  },
  watch: {
    currentIndex(index) {
      lastMenuIndex = index
      firstTimeMenu = false
    }
  },
  methods: {
    btnUp() {
      let index = this.currentIndex - 2
      if (index < 0) {
        index = this.menuItems.length - 1
      }
      this.currentIndex = index
    },
    btnDown() {
      let index = this.currentIndex + 2
      if (index > this.menuItems.length-1) {
        index = index - this.menuItems.length
      }
      this.currentIndex = index
    },
    btnLeft() {
      let index = this.currentIndex - 1
      if (index < 0) {
        index = this.menuItems.length - 1
      }
      this.currentIndex = index
    },
    btnRight() {
      let index = this.currentIndex + 1
      if (index > this.menuItems.length - 1) {
        index = 0
      }
      this.currentIndex = index
    },
    btnEnter() {
      if (this.menuItems[this.currentIndex].route) {
        this.$router.replace(this.menuItems[this.currentIndex].route)
      } else {
        this.menuItems[this.currentIndex].action()
      }
    },
    btnBack() {
      if (this.$store.state.playing) {
        if (this.confirmBack) {
          this.$refs.exitModal.show()
        } else {
          utils.returnToPlayer()
        }
      } else {
        device.exitApp()
      }
    },
    exitCancel() {
      utils.returnToPlayer()
    },
    exitConfirm() {
      device.exitApp()
    },
    cancelHide() {
      clearTimeout(hideTimeout)
      this.confirmBack = true
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    Modal,
  }
}
</script>

<style lang="scss" scoped>
  .home-view {
    width: 980px;
    margin: 0 auto;
  }
  .menu-item {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 25px;
    font-weight: bold;
    color: #868686;
    cursor: pointer;
    height: 118px;
    line-height: 105px;
    margin: 23px 50px 0 0;
    position: relative;
    width: 434px;
    white-space: nowrap;
    display: inline-block;

    div {
      margin: 8px 0 0 28px;
      display: inline-block;
      vertical-align: middle;
    }
    &.active {
      background-color: $color-primary;
      border-radius: 7px;
      color: $color-dark;
      .icon {
        background-color: transparent;
      }
    }
    .icon {
      border: 0;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      background: #868686 no-repeat center center;
      &.icon-categories {
        background-image: url(../assets/img/icon-categories.svg);
      }
      &.icon-videoarchive {
        background-image: url(../assets/img/icon-videoarchive.svg);
      }
      &.icon-tvchannels {
        background-image: url(../assets/img/icon-tvchannels.svg);
      }
      &.icon-account {
        background-image: url(../assets/img/icon-account.svg);
      }
      &.icon-youtube {
        background-image: url(../assets/img/icon-youtube.svg);
      }
      &.icon-settings {
        background-image: url(../assets/img/icon-settings.svg);
      }
      &.icon-wink {
        background-image: url(../assets/img/icon-wink.svg);
      }
    }
  }
</style>
