<template>
  <div class="blocked-channel-link">
    <parent-code @confirm="linkPacketRequest" v-if="confirmPacketLink" />
    <div class="link-confirm" v-if="!linkConfirmed">
      <div class="block-hint">
        Уважаемый клиент, ознакомительный просмотр завершен.
        <br/><br/>
        Данный телеканал входит в пакет <nobr>«{{packet.title}}».</nobr>
        Нажмите <nobr>«Подключить пакет»,</nobr> чтобы продолжить просмотр.
      </div>
      <ul class="packet-info-buttons active">
        <li @mouseover="linkButton = true" :class="[{current: linkButton}, packet.class_name]" @click="linkPacket">
          Подключить пакет
        </li>
        <li @mouseover="linkButton = false" :class="{current: !linkButton}" @click="cancel">
          Отмена
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="pk-info">
        <img class="packet-info-img" :src="packet.img"/>
        <div class="packet-info-hint">Пакет каналов</div>
        <div class="packet-info-title">{{packet.title}}</div>
        <p class="packet-info-desc">{{packet.description}}</p>
      </div>
      <div class="right-col">
        <div class="pk-channels-count">
          В пакете {{packet.channels_text}}
          <div class="arrows" v-if="packetChannels.length > 5">
            <img src="@/assets/img/arrow-left-bold-yellow.svg" />
            <img src="@/assets/img/arrow-right-bold-yellow.svg" />
          </div>
        </div>
        <paged-list class="pk-channel-list" ref="chList"
          :items="packetChannels"
          :perPage="5"
        >
          <template slot-scope="{ item }">
            <i class="ch-icon chi-54" :class="`ch-${item.icon_id}`"/>
            {{item.name}}
          </template>
        </paged-list>
      </div>
      <ul class="packet-info-buttons active">
        <li @mouseover="linkButton = true" :class="[{current: linkButton}, packet.class_name]" @click="linkPacket">
          <span v-if="packet.is_demo_available">
            Подключить бесплатно на {{packet.demo_days}} дней
          </span>
          <span v-else>
            Подключить за <b>{{packet.cost}}</b> р/мес
          </span>
        </li>
        <li @mouseover="linkButton = false" :class="{current: !linkButton}" @click="cancel">
          Не интересует
        </li>
      </ul>
    </div>
    <progress-loader ref="pbInfo"/>
    <controls-event-bus v-if="!confirmPacketLink"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeftRight"
      @right="btnLeftRight"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import PagedList from '@/components/PagedList.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ParentCode from '@/components/ParentCode.vue'

export default {
  props: ['channel'],
  data() {
    return {
      linkConfirmed: false,
      linkButton: true,
      needParentCode: false,
    }
  },
  methods: {
    btnUp() {
      if (this.linkConfirmed) {
        this.$refs.chList.prevPage()
      }
    },
    btnDown() {
      if (this.linkConfirmed) {
        this.$refs.chList.nextPage()
      }
    },
    btnLeftRight() {
      this.linkButton = !this.linkButton
    },
    btnEnter() {
      if (this.linkButton) {
        this.linkPacket()
      } else {
        this.cancel()
      }
    },
    linkPacket() {
      if (this.linkConfirmed) {
        if (this.$store.state.settings.confirmPacketLink) {
          this.needParentCode = true
          return
        }
        this.linkPacketRequest()
      } else {
        this.linkConfirmed = true
      }
    },
    linkPacketRequest() {
      this.needParentCode = false
      this.$refs.pbInfo.show('loader', 'Выполняется подключение пакета, подождите…')
      this.$api.linkPacket(this.packet.id).then(res => {
        this.$refs.pbInfo.show(res.type, res.message, 3000)
        if (res.type == 'success') {
          this.$emit('linked')
        }
      }).catch(() => this.$refs.pbInfo.show('error', 'Не удалось выполнить операцию! Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    },
    cancel() {
      if (this.linkConfirmed) {
        this.linkButton = true
        this.linkConfirmed = false
      } else {
        this.$router.replace({name: 'tvchannels'})
      }
    },
  },
  computed: {
    packet() {
      return this.$store.state.packets.find(packet => packet.id == this.channel.packet_id) || {}
    },
    packetChannels() {
      return this.$store.state.channels.filter(chan => chan.packet_id == this.packet.id)
    },
    confirmPacketLink() {
      return this.needParentCode && this.$store.state.settings.confirmPacketLink
    },
  },
  components: {
    PagedList,
    ProgressLoader,
    ControlsEventBus,
    ParentCode,
  }
}
</script>

<style lang="scss">
.blocked-channel-link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $color-dark;
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  width: 690px;

  .link-confirm {
    padding: 10px;
  }
  .block-hint {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .right-col {
    float: right;
    width: 335px;
  }
  .pk-channels-count {
    color: $color-primary;
    font-size: 24px;
    margin-bottom: 10px;
    .arrows {
      float: right;
      margin-top: 4px;
      img {
        margin: 0 8px;
        transform: rotate(90deg);
      }
    }
  }
  .pk-channel-list {
    width: 100%;
    height: 380px;
    li {
      height: 74px;
      line-height: 74px;
      border-radius: 5px;
      color: #DDDDDD;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .ch-icon {
        margin: 10px 12px 6px 6px;
        border-radius: 3px;
        float: left;
      }
    }
  }
  .pk-info {
    display: inline-block;
    height: 100%;
    width: 320px;
  }
  .packet-info-hint {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .packet-info-title {
    color: $color-primary;
    font-size: 28px;
    line-height: 28px;
    font-weight: bold;
  }
  .packet-info-img {
    margin-bottom: 30px;
    width: 320px;
    height: 200px;
    border-radius: 4px;
    display: block;
  }
  .packet-info-desc {
    margin-bottom: 10px;
    color: #CCC;
    font-size: 16px;
    line-height: 18px;
    height: 110px;
    overflow: hidden;
  }
  .link-confirm .packet-info-buttons li {

  }
  .packet-info-buttons {
    width: 100%;
    &.active li.current {
      background-color: $color-primary;
      border-color: $color-primary;
    }
    li {
      border: 1px solid #FFFFFF;
      height: 38px;
      line-height: 38px;
      border-radius: 5px;
      background-color: #DDD;
      color: #000;
      font-size: 20px;
      text-align: center;
      background-repeat: no-repeat;
      width: 48%;
      display: inline-block;
      &:last-child {
        float: right;
      }
      &.demo {
        font-size: 18px;
      }
    }
  }
}
</style>
