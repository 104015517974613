<template>
  <div class="pg-details" v-if="program.name">
    <div class="title">{{ program.name }}</div>
    <div class="type">
      <span v-if="program.episode">
        <span v-if="program.season">{{ program.season }}, </span>
        {{ program.episode }}
      </span>
      <span v-else-if="program.type == 'Инфо' || program.type == 'Остальное'">{{ program.type }}</span>
      <span v-else-if="program.type == 'Спорт'">{{ program.genre }}</span>
      <span v-else-if="program.type == 'Досуг' || program.type == 'Позновательное'">
        <span v-if="program.genre">{{ program.genre }}</span>
        <span v-else>{{ program.type }}</span>
      </span>
      <span v-else-if="program.year || program.country">
        <span v-if="program.year">{{ program.year }} год.</span>
        {{ program.country }}
      </span>
      <span v-else>{{ program.type }}</span>
    </div>
    <div class="time">
      <template v-if="program.duration">
        {{ program.duration }} мин.
      </template>
      <template v-else-if="program.time">
        {{ program.time }} - {{ program.time_to }}
      </template>
      <div class="rating" v-if="program.rating">
        <img src="@/assets/img/star.svg" />
        <div>
          {{ parseFloat(program.rating.kinopoisk ? program.rating.kinopoisk : program.rating.imdb).toFixed(1) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['program']
}
</script>
