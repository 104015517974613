import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import utils from './utils'
import device from './device'
import WS from './WS'

Vue.use(Vuex)

const DEFAULT_SETTINGS = {
  'NV501WAC': {
    useAnimation: 0
  }
}
const DEFAULT_CATEGORY = {id: -1, name: 'Все каналы'}
const UNAVAIL_WATCH_TIME = 5400
const ADULT_UNAVAIL_WATCH_TIME = 360
const RESET_UNAVAIL_BLOCK_TIME = 86400
const UPDATE_PROGRAMS_INTERVAL = 290
const WATCH_PUSH_INTERVAL = 300
let pushStatInterval, unavailWatchTimeout, watchContentInterval, watchContentTimeout, channelSessionId = 0, lastUpdateProgramsTime = 0

export default new Vuex.Store({
  state: {
    envSym: APP_ENV == 'dev' ? 'α' : (APP_ENV == 'beta' ? 'β' : ''),
    loaded: false,
    accountInfo: {},
    deviceInfo: {},
    categories: [DEFAULT_CATEGORY],
    recommended: [],
    channelsProgram: {},
    channels: [],
    packets: [],
    epgStack: [],
    channelId: 0,
    channelMiId: 0,
    parentCodeValid: false,
    playing: false,
    modalVisible: false,
    debug: false,
    log: [],
    player: {
      paused: false,
      rewindTime: 0,
      archiveTime: 0,
    },
    settings: {
      lastAppVersion: null,
      lastChannelId: 0,
      archiveTime: 0,
      volume: 100,
      abonement: null,
      password: null,
      confirmPacketLink: 0,
      hideAdultChannels: 0,
      hideUnlinkedChannels: 0,
      useAnimation: 1,
    },
    unavailWatchLimit: {},
  },
  mutations: {
    setSetting(state, setting) {
      if (state.settings[setting.name] == setting.value || !state.loaded) {
        return
      }
      state.settings[setting.name] = setting.value
      device.setSetting('app-settings', JSON.stringify(state.settings))
    },
    skipParentCode(state, value) {
      state.parentCodeValid = value
    },
    playerState(state, player) {
      Object.assign(state.player, player)
    },
    resetPlayerState(state) {
      state.player = {
        paused: false,
        rewindTime: 0,
        archiveTime: 0,
      }
    },
    rewindTime(state, delta) {
      state.player.rewindTime = delta
    },
    paused(state, paused) {
      state.player.paused = paused
    },
    showModal(state, visible) {
      state.modalVisible = visible
    },
    loaded(state) {
      state.loaded = true
    },
    epgStack(state, epg) {
      Object.keys(epg).forEach(channel_id => {
        epg[channel_id].forEach(program => {
          const dayStart = utils.getDayStart(program.begin_time)
          const stackItem = state.epgStack.find(item => item.channel_id == channel_id && item.day_start == dayStart)
          if (stackItem) {
            if (stackItem.data.findIndex(prog => prog.id == program.id) < 0) {
              stackItem.data.push(program)
            }
          } else {
            state.epgStack.push({channel_id, day_start: dayStart, data: [program]})
          }
        })
      })
      while (state.epgStack.length > 30) {
        state.epgStack.shift()
      }
    },
    toggleDebug(state) {
      state.debug = !state.debug
      if (state.debug) {
        state.log.push('Debug enabled')
        state.log.push('Testing websockets...')
        WS.send('Test')
      } else {
        state.log = []
      }
    },
    log(state, message) {
      if (!state.debug) {
        return
      }
      state.log.push(utils.getTime(null, true) + ': ' + message)
      while (state.log.length > 10) {
        state.log.shift()
      }
    },
  },
  getters: {
    channelsMap(state) {
      const result = {}
      state.channels.forEach(ch => result[ch.id] = ch)
      return result
    },
    playingChannel(state, getters) {
      return getters.channelsMap[state.channelId] || {}
    },
    filteredChannels(state) {
      let channels = state.channels
      if (state.settings.hideAdultChannels && !state.parentCodeValid) {
        channels = channels.filter(channel => !channel.is_parent_control)
      }
      if (state.settings.hideUnlinkedChannels) {
        channels = channels.filter(channel => channel.is_avail)
      }
      return channels
    },
    channelEpg: state => id => {
      return [].concat(...state.epgStack
        .filter(item => item.channel_id == id)
        .map(item => item.data)
      ).sort((a, b) => a.begin_time - b.begin_time)
    },
  },
  actions: {
    setVolume({commit}, volume) {
      device.setVolume(volume)
      commit('setSetting', {name: 'volume', value: volume})
    },
    setLastChannelId({commit, state}, data) {
      if (data.save) {
        commit('setSetting', {name: 'lastChannelId', value: data.id})
      }
      state.channelId = data.id
    },
    play({state, getters, dispatch}, {url, contentId, duration}) {
      clearTimeout(unavailWatchTimeout)
      if (!state.accountInfo.active) {
        return
      }
      const channel = getters.channelsMap[state.channelId] || {}
      if (channel.is_parent_control && !state.parentCodeValid) {
        return
      }
      state.channelMiId = channel.icon_id
      if (!channel.is_avail) {
        const now = utils.getUnixTime()
        const channelId = channel.is_parent_control ? 'adult' : channel.id
        if (now - state.unavailWatchLimit[channelId] > RESET_UNAVAIL_BLOCK_TIME) {
          delete state.unavailWatchLimit[channelId]
        }
        if (state.unavailWatchLimit[channelId] < now) {
          utils.returnToPlayer()
          return
        }
        if (!state.unavailWatchLimit[channelId]) {
          const watchTime = channel.is_parent_control ? ADULT_UNAVAIL_WATCH_TIME : UNAVAIL_WATCH_TIME
          state.unavailWatchLimit[channelId] = now + watchTime
          device.setSetting('unavail-watch-limit', JSON.stringify(state.unavailWatchLimit))
        }
        unavailWatchTimeout = setTimeout(() => {
          dispatch('stop')
          utils.returnToPlayer()
        }, (state.unavailWatchLimit[channelId] - now) * 1000)
      }

      device.play(url)
      state.playing = true

      clearInterval(pushStatInterval);
      if (!contentId) {
        ++channelSessionId
        pushStatInterval = setInterval(() => {
          api.statPush(channelSessionId, state.channelMiId, 0)

          const now = utils.getUnixTime()
          const program = (
            state.channelsProgram[state.channelId] &&
            state.channelsProgram[state.channelId].find(prog => prog.begin_time <= now && prog.end_time > now)
          ) || {}
          const archive = !program.begin_time || (program.begin_time <= now + state.player.rewindTime) ? 0 : 1

          api.pushChannelStat(state.channelId, WATCH_PUSH_INTERVAL, archive)
        }, WATCH_PUSH_INTERVAL * 1000)
      }

      clearTimeout(watchContentTimeout)
      clearInterval(watchContentInterval)
      if (contentId && duration) {
        watchContentInterval = setInterval(() => {
          api.catalogContentView(contentId, WATCH_PUSH_INTERVAL)
        }, WATCH_PUSH_INTERVAL * 1000)
        watchContentTimeout = setTimeout(() => {
          clearInterval(watchContentInterval)
        }, duration * 1000)
      }
    },
    stop({state}) {
      device.stop()
      state.playing = false
      clearInterval(pushStatInterval)
      clearTimeout(watchContentTimeout)
      clearInterval(watchContentInterval)
    },
    packetsChanged({dispatch}) {
      dispatch('updatePackets')
      return dispatch('updateAccountInfo').then(() => dispatch('updatePrograms'))
    },
    updateAccountInfo({state}) {
      return api.getAccountInfo().then(info => {
        state.accountInfo = info
        if (info.redirect_url) {
          const url = document.createElement('a')
          url.href = info.redirect_url
          if (url.host != location.host) {
            location.replace(url.href)
          }
        }
        return info
      })
    },
    updatePackets({state}) {
      return api.getPacketList().then(packets => state.packets = packets)
    },
    updateCategories({state}) {
      return api.getCategoryList().then(categories => state.categories = [DEFAULT_CATEGORY, ...categories])
    },
    updateEpg({commit}, {channel_id, start, end}) {
      return api.getPrograms(channel_id, start, end).then(epg => commit('epgStack', epg))
    },
    updateRecommended({state}) {
      return api.getRecommendedPrograms().then(programs => state.recommended = programs)
    },
    updatePrograms({state}, force) {
      const now = utils.getUnixTime()
      const promise = force || state.channels.length == 0
        ? api.getChannelList().then(channels => state.channels = channels)
        : Promise.resolve()

      return promise.then(() => {
        if (now < lastUpdateProgramsTime + UPDATE_PROGRAMS_INTERVAL) {
          return []
        }
        const cids = []
        state.channels.forEach(ch => {
          if (!ch.has_epg) {
            return []
          }
          const progIndex = state.channelsProgram[ch.id] && state.channelsProgram[ch.id].findIndex(prog => prog.begin_time <= now && prog.end_time > now)
          if (progIndex !== 0) {
            cids.push(ch.id)
          }
        })
        if (!cids.length) {
          return []
        }
        lastUpdateProgramsTime = now
        return api.getChannelsProgram(cids.join(','))
      }).then(programs => {
        Object.keys(programs).forEach(channel_id => {
          state.channelsProgram[channel_id] = programs[channel_id]
        })
        state.channels.forEach(ch => {
          const program = (state.channelsProgram[ch.id] && state.channelsProgram[ch.id].find(prog => prog.begin_time <= now && prog.end_time > now)) || {}
          const begin = utils.dateFromUnixTime(program.begin_time)
          const end = utils.dateFromUnixTime(program.end_time)
          ch.next_program_name = null
          ch.program_begin_time = program.begin_time
          ch.program_end_time = program.end_time
          ch.program_name = program.full_name
          ch.program_description = program.description
          ch.begin_time = utils.getTime(begin)
          ch.end_time = utils.getTime(end)
          ch.progress = utils.getProgress(now, ch.program_begin_time, ch.program_end_time)
          ch.is_avail = state.accountInfo.packets && state.accountInfo.packets.includes(ch.packet_id)
        })
      })
    },
    loadSettings({state}) {
      return device.getInfo().then(info => state.deviceInfo = info)
        .then(() => device.getSetting('app-settings'))
        .then(val => {
          Object.assign(state.settings, DEFAULT_SETTINGS[state.deviceInfo.model])
          // eslint-disable-next-line
          try {Object.assign(state.settings, JSON.parse(val))} catch(e) {}

          state.channelId = state.settings.lastChannelId
          return device.getSetting('unavail-watch-limit')
        }).then(val => {
          // eslint-disable-next-line
          try {Object.assign(state.unavailWatchLimit, JSON.parse(val))} catch(e) {}
          const now = utils.getUnixTime()
          for (const channelId in state.unavailWatchLimit) {
            if (now - state.unavailWatchLimit[channelId] > RESET_UNAVAIL_BLOCK_TIME) {
              delete state.unavailWatchLimit[channelId]
            }
          }
          device.setSetting('unavail-watch-limit', JSON.stringify(state.unavailWatchLimit))
        })
    },
  }
})
