<template>
  <div :class="{active}">
    <ul>
      <li v-for="(program, index) in currentPrograms" :key="program.id" :class="{active: index == activeIndex}">
        <div class="pg-image" :style="{backgroundImage: `url(${program.image_url})`}" v-if="program.name" />
        <program-details :program="program" />
      </li>
    </ul>
    <controls-event-bus v-if="active"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ProgramDetails from '../ProgramDetails.vue'
import api from '@/api'

const ITEMS_COUNT = 9

export default {
  props: ['channel', 'program', 'active'],
  data() {
    return {
      offset: 0,
      activeIndex: 2,
    }
  },
  watch: {
    active() {
      if (this.active) {
        api.appLog('carousel-recommended-show', this.channel.name)
      }
    }
  },
  methods: {
    btnLeft() {
      if (--this.offset < 0) {
        this.offset = 0
      }
    },
    btnRight() {
      if (++this.offset > this.programs.length - 1) {
        this.offset = this.programs.length - 1
      }
    },
    btnEnter() {
      api.appLog('carousel-recommended-ok', this.channel.name)
      this.$router.replace({
        name: 'catalog-content',
        params: {id: this.activeProgram.content_id},
      })
    },
  },
  computed: {
    programs() {
      return this.$store.state.recommended
    },
    currentPrograms() {
      const programs = this.programs.slice()
      programs.splice(0, 0, ...Array(this.activeIndex).fill({}))
      while (programs.length < ITEMS_COUNT) {
        programs.push({})
      }
      return programs.slice(this.offset, this.offset + ITEMS_COUNT)
    },
    activeProgram() {
      return this.currentPrograms[this.activeIndex]
    }
  },
  components: {
    ControlsEventBus,
    ProgramDetails,
  }
}
</script>
