<template>
  <div class="categories-view">
    <parent-code @confirm="$store.commit('skipParentCode', true)" @close="categoryIndex = prevCatIndex" v-if="needParentCode"/>
    <div class="header-title">
      ТВ по интересам
    </div>
    <paged-list class="category-list"
      :active="categoriesActive && !needParentCode"
      :items="categories"
      :per-page="10"
      v-model="categoryIndex"
      @mouseover="categoriesActive = true"
      @item-selected="() => {categoriesActive = false}"
    >
      <template slot-scope="{ item }">
        {{ item.name }}
      </template>
    </paged-list>
    <paged-list class="channel-list" ref="chList"
      :active="!categoriesActive && !needParentCode"
      :items="categoryChannels"
      :per-page="10"
      v-model="channelIndex"
      @mouseover="categoriesActive = false"
      @item-selected="play"
    >
      <template slot-scope="{ item }">
        <i class="ch-icon chi-36" :class="[`ch-${item.icon_id}`, {locked: !item.is_avail}]"/>
        <div class="pg-time" v-if="item.program_begin_time">
          {{item.begin_time}} - {{item.end_time}}
          <div class="progress"><div :style="{width: item.progress}"/></div>
        </div>
        <div class="pg-name">
          {{item.program_name}}
          <span class="no-program" v-if="!item.program_name">Нет телепрограммы</span>
        </div>
      </template>
    </paged-list>
    <controls-hint-bar>
      <div class="btn-group arrows">
        <control-hint-btn type="up"/>
        <control-hint-btn type="down"/>
        <control-hint-btn type="left" @click="btnLeft"/>
        <control-hint-btn type="right" @click="btnRight"/>
        <span>Переход по меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="red" @click="btnRed"/>
        <span>Программа</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="enter"/>
        <span>Выбор</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="menu" @click="btnMenu"/>
        <span>Меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnMenu"/>
        <span>Выход</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus v-if="!needParentCode"
      @left="btnLeft"
      @right="btnRight"
      @red="btnRed"
      @back="btnMenu"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import ParentCode from '@/components/ParentCode.vue'
import PagedList from '@/components/PagedList.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      categoryIndex: 0,
      prevCatIndex: 0,
      channelIndex: 0,
      categoriesActive: true
    }
  },
  computed: {
    ...mapState(['categories']),
    channels() {
      return this.$store.getters.filteredChannels
    },
    categoryChannels() {
      if (this.categoryIndex <= 0) {
        return this.channels
      } else {
        return this.channels.filter(ch => ch.category_id == this.categories[this.categoryIndex].id)
      }
    },
    currentChannel() {
      return this.categoryChannels[this.channelIndex]
    },
    needParentCode() {
      return this.categories[this.categoryIndex].is_adult && this.$store.state.settings.hideAdultChannels && !this.$store.state.parentCodeValid
    }
  },
  watch: {
    categoryIndex(newIndex, prevIndex) {
      this.$refs.chList.reset()
      this.prevCatIndex = prevIndex
    }
  },
  methods: {
    play() {
      this.$router.replace({name: 'player', params: {id: this.currentChannel.id}})
    },
    btnLeft() {
      this.categoriesActive = true
      this.page = 0
      this.channelIndex = 0
    },
    btnRight() {
      if (this.categoriesActive) {
        this.categoriesActive = false
      } else  {
        this.$refs.chList.nextPage()
      }
    },
    btnRed() {
      if (!this.categoriesActive) {
        this.$router.replace({name: 'epg', params: {id: this.currentChannel.id}})
      }
    },
    btnMenu() {
      this.$router.replace({name: 'home'})
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    ParentCode,
    PagedList,
  }
}
</script>

<style lang="scss">
  .categories-view {
    margin: 0 auto;
    width: 1220px;

    .category-list {
      width: 320px;
      margin: 0 50px;
      li {
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #868686;
        cursor: pointer;
        font-size: 25px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 12px 0 11px 22px;
        border-radius: 5px;
      }
      &.active .current {
        color: $color-dark;
        background-color: $color-primary;
      }
      .current {
        color: $color-primary;
      }
    }
  }
</style>
