import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Categories from './views/Categories.vue'
import ChPackets from './views/ChPackets.vue'
import Account from './views/Account.vue'
import TvChannels from './views/TvChannels.vue'
import EPG from './views/EPG.vue'
import Player from './views/Player.vue'
import Settings from './views/Settings.vue'
import Catalog from './views/Catalog.vue'
import CatalogContent from './views/CatalogContent.vue'
import Instruction from './views/Instruction.vue'

Vue.use(Router)

export default new Router({
  routes: [
    { path: '/', name: 'home', component: Home, meta: {auth: true} },
    { path: '/login', name: 'login', component: Login, meta: {auth: false} },
    { path: '/categories', name: 'categories', component: Categories, meta: {auth: true} },
    { path: '/chpackets', name: 'chpackets', component: ChPackets, meta: {auth: true} },
    { path: '/account', name: 'account', component: Account, meta: {auth: true} },
    { path: '/tvchannels', name: 'tvchannels', component: TvChannels, meta: {auth: true} },
    { path: '/settings', name: 'settings', component: Settings, meta: {auth: true} },
    { path: '/epg/:id', name: 'epg', component: EPG, meta: {auth: true} },
    { path: '/player/:id', name: 'player', component: Player, meta: {auth: true} },
    { path: '/catalog', name: 'catalog', component: Catalog, meta: {auth: true} },
    { path: '/catalog-content/:id', name: 'catalog-content', component: CatalogContent, meta: {auth: true} },
    { path: '/instruction', name: 'instruction', component: Instruction, meta: {auth: true} },
    { path: '*', redirect: {name: 'home'} }
  ]
})
