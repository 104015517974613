<template>
  <div class="content-relevance">
    <div class="title">
      Рекомендации
    </div>
    <ul>
      <li v-for="(rel, index) in rels" :key="index" class="rel-item" :class="{active: active && index == relIndex}">
        <img class="rel-poster" :src="rel.poster_url"/>
        <div class="rel-name">
          {{ rel.name }}
        </div>
        <div class="rel-year" v-if="rel.year">{{ rel.year }} г</div>
        <div class="rel-rating" v-if="rel.rating">
          {{ rel.rating.kinopoisk ? rel.rating.kinopoisk : rel.rating.imdb }}
        </div>
      </li>
    </ul>
    <controls-event-bus v-if="active"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

const LINE_ITEMS = 3

export default {
  props: ['items', 'active'],

  data() {
    return {
      relIndex: 0,
      offset: 0,
    }
  },
  methods: {
    btnUp() {
      if (this.offset - LINE_ITEMS < 0) {
        this.$emit('bound-up')
      } else {
        this.offset -= LINE_ITEMS
      }
    },
    btnDown() {
      if (this.offset + LINE_ITEMS < this.items.length) {
        this.offset += LINE_ITEMS
        if (this.relIndex > this.rels.length - 1) {
          this.relIndex = this.rels.length - 1
        }
      }
    },
    btnLeft() {
      if (--this.relIndex < 0) {
        if (this.offset > 0) {
          this.relIndex = LINE_ITEMS - 1
          this.btnUp()
        } else {
          this.relIndex = 0
        }
      }
    },
    btnRight() {
      if (this.relIndex < this.rels.length - 1 && ++this.relIndex >= LINE_ITEMS) {
        this.relIndex = 0
        this.btnDown()
      }
    },
    btnEnter() {
      this.$emit('content-selected', this.rels[this.relIndex] || {})
    },
  },
  computed: {
    rels() {
      return this.items.slice(this.offset)
    }
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  ul {
    padding-left: 15px;
  }
  .rel-item {
    float: left;
    height: 96px;
    width: 240px;
    margin: 5px;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    &.active {
      background-color: $color-primary;
      .rel-name {
        color: #000;
      }
      .rel-rating {
        background-image: url(../../assets/img/star-orange.svg);
      }
      .rel-year, .rel-rating {
        color: #666;
      }
    }
  }
  .rel-poster {
    width: 66px;
    height: 96px;
    float: left;
    margin-right: 10px;
  }
  .rel-name {
    max-height: 42px;
    overflow: hidden;
  }
  .rel-year {
    color: #777;
    font-weight: bold;
    margin: 10px 0;
  }
  .rel-rating {
    color: #777;
    font-weight: bold;
    display: inline-block;
    padding-left: 25px;
    line-height: 20px;
    padding-top: 2px;
    background: url(../../assets/img/star.svg) no-repeat;
    background-size: 20px;
  }
</style>
