<template>
  <div class="catalog-content">
    <div v-if="details">
      <select-variant v-if="selectVariant" :episode="episode" @content-selected="playContent($event)"/>
      <div class="content-name">
        {{ details.name }}<span v-if="!details.series && !details.season && details.episode">, {{ details.episode }}</span>
        <div class="orig-name">
          <span class="year" v-if="details.year">{{ details.year }} г.</span>
          {{ details.orig_name }}
        </div>
      </div>
      <div class="poster-container">
        <div class="poster"
          :class="{'not-avail': notAvail, 'no-poster': true}"
          :style="{backgroundImage: `url(${details.poster_url ? details.poster_url : details.image_url})`}"
        />
      </div>
      <div class="content-details">
        <table>
          <tr v-if="details.rating">
            <td>Оценка:</td>
            <td class="rating">
              <span v-if="details.rating.kinopoisk">
                <span class="rate">{{ details.rating.kinopoisk }}</span>
                КиноПоиск
                &nbsp;&nbsp;&nbsp;
              </span>
              <span v-if="details.rating.imdb">
                <span class="rate">{{ details.rating.imdb }}</span>
                IMDb
              </span>
            </td>
          </tr>
          <tr v-if="details.genres_list">
            <td>Жанр:</td>
            <td><div>{{ details.genres_list }}</div></td>
          </tr>
          <tr v-if="details.country">
            <td>Страна:</td>
            <td><div>{{ details.country }}</div></td>
          </tr>
          <tr v-if="details.directors">
            <td>Режиссер:</td>
            <td><div>{{ details.directors }}</div></td>
          </tr>
          <tr v-if="details.artists">
            <td>В ролях:</td>
            <td><div class="artists">{{ details.artists }}</div></td>
          </tr>
        </table>
        <div class="description" v-html="details.description" />
      </div>
      <div class="content-play">
        <div class="title" v-if="notAvail">К сожалению запись в архиве недоступна</div>
        <div class="title" v-else>Смотреть из архива</div>
        <play-buttons v-if="details.play"
          :buttons="details.play"
          :content-id="details.content_id"
          :active="!relevanceActive && !selectVariant"
          @content-selected="playContent($event)"
          @bound-down="activateRelevance"
        />
        <series v-else-if="details.series && !(details.type != 'Сериал' && details.series.length <= 3)"
          :content-id="details.content_id"
          :series="details.series"
          :active="!relevanceActive && !selectVariant"
          @content-selected="playContent($event)"
          @select-variant="episode = $event; selectVariant = true"
          @description-changed="description = $event"
          @bound-down="activateRelevance"
        />
        <multi-series v-else
          :content-id="details.content_id"
          :series="details.series"
          :active="!relevanceActive && !selectVariant"
          @content-selected="playContent($event)"
          @bound-down="activateRelevance"
        />
        <relevance v-if="details.relevance.length"
          :items="details.relevance"
          :active="relevanceActive && !selectVariant"
          @bound-up="relevanceActive = false"
          @content-selected="openContent($event)"
        />
      </div>
    </div>

    <div class="bg-image" :style="{backgroundImage: `url(${background})`}" />

    <progress-loader ref="pbInfo"/>
    <controls-event-bus
      @back="btnBack"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import Series from '@/components/CatalogContent/Series.vue'
import PlayButtons from '@/components/CatalogContent/PlayButtons.vue'
import MultiSeries from '@/components/CatalogContent/MultiSeries.vue'
import Relevance from '@/components/CatalogContent/Relevance.vue'
import SelectVariant from '@/components/CatalogContent/SelectVariant.vue'
import api from '@/api'

export default {
  data() {
    return {
      details: null,
      relevanceActive: false,
      selectVariant: false,
      episode: {},
      history: [],
      background: '',
    }
  },
  mounted() {
    document.querySelector('header .clock').style.display = 'none'
    document.getElementById('app').style.backgroundColor = 'rgba(0,0,0,0.6)'
    this.loadDetails()
  },
  beforeDestroy() {
    document.querySelector('header .clock').style.display = ''
    document.getElementById('app').style.backgroundColor = ''
  },
  watch: {
    $route() {
      Object.assign(this.$data, this.$options.data.apply(this), {
        history: this.history,
        background: this.background,
      })
      this.loadDetails()
    }
  },
  methods: {
    loadDetails() {
      this.$refs.pbInfo.show('loader', 'Загрузка')
      api.getCatalogContentDetails(this.$route.params.id).then(details => {
        if (!details) {
          return this.$router.replace({name: 'catalog'})
        }
        this.details = details
        this.$refs.pbInfo.hide()
        this.background = details.image_url
      }).catch(() => this.$refs.pbInfo.show('error', 'Ошибка загрузки. Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    },
    activateRelevance() {
      if (this.relevance.length > 0) {
        this.relevanceActive = true
      }
    },
    btnBack() {
      if (this.selectVariant) {
        this.selectVariant = false
      } else if (this.history.length > 0) {
        this.$router.replace({name: 'catalog-content', params: {id: this.history.pop()}})
      } else {
        this.$router.replace({name: 'catalog'})
      }
    },
    playContent(content) {
      api.catalogContentView(content.content_id)
      api.appLog('content-play', null, content.content_id)
      const args = {
        name: 'player',
        params: {id: content.channel_id},
        query: {time: content.begin_time, content_id: content.content_id, duration: content.duration}
      }
      this.$router.replace(args)
    },
    openContent(content) {
      api.appLog('content-relevance-enter', null, content.content_id)
      this.history.push(this.$route.params.id)
      this.$router.replace({name: 'catalog-content', params: {id: content.content_id}})
    },
  },
  computed: {
    relevance() {
      return this.details.relevance || []
    },
    relevanceList() {
      return this.relevance.slice(this.relevanceOffset)
    },
    playDetails() {
      return this.details.play || []
    },
    notAvail() {
      return !this.details.play && !this.details.series
    }
  },
  components: {
    ControlsEventBus,
    ProgressLoader,
    PlayButtons,
    Relevance,
    Series,
    MultiSeries,
    SelectVariant,
  }
}
</script>

<style lang="scss" scoped>
  .bg-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(1px);
    background-position: center;
    background-size: cover;
  }
  .catalog-content {
    width: 1180px;
    margin: 0 auto;
    margin-top: -15px;
  }
  .content-name {
    color: #fff;
    font-size: 28px;
    position: absolute;
    left: 50px;
    top: 50px;
    width: 940px;
    overflow: hidden;
    white-space: nowrap;
    mask-image: linear-gradient(to left, transparent 10px, black 50px);

    .year {
      color: $color-primary;
      font-size: 22px;
      font-weight: bold;
    }
    .orig-name {
      margin-top: 5px;
      font-size: 18px;
      color: #777;
      text-shadow: 1px 1px 4px #44444480;
    }
  }
  .poster-container {
    margin-left: -10px;
    padding: 10px;
    background-color: #0000003A;
    float: left;

    .poster {
      width: 330px;
      height: 490px;
      background-size: 100% 100%;
      &.not-avail {
        filter: grayscale(100%);
      }
      &.no-poster {
        background-size: cover;
        background-position: center;
      }
    }
  }
  .content-details {
    display: inline-block;
    width: 800px;
    max-height: 240px;
    margin-left: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
    overflow: hidden;

    table {
      float: left;
      width: 450px;
      font-size: 19px;
      border-spacing: 0;
      tr:last-child td {
        padding-bottom: 0;
      }
      td {
        padding-right: 5px;
        padding-bottom: 10px;
        vertical-align: top;
        line-height: 24px;
      }
      td:first-child {
        color: #777;
        text-shadow: 1px 1px 4px #44444480;
        text-align: right;
        width: 1px;
      }
      td:last-child {
        color: #fff;
        &.rating {
          font-size: 16px;
          .rate {
            font-size: 20px;
            color: $color-primary;
          }
        }
        > div {
          max-height: 25px;
          overflow: hidden;
          &.artists {
            max-height: 75px;
          }
        }
      }
    }

    .description {
      float: right;
      font-size: 17px;
      color: #dadada;
      font-weight: 300;
      line-height: 20px;
      max-height: 240px;
      overflow: hidden;
      width: 330px;
      //mask-image: linear-gradient(to top, transparent 0, black 20px);
    }
  }
  .content-play {
    display: inline-block;
    margin-left: 30px;
    width: 800px;
    color: #fff;
    padding: 0;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .title {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
</style>
