<template>
  <div>
    <div class="ko-progress-circle" :data-progress="volume" v-if="visible || muted">
        <div class="ko-circle">
            <div class="full ko-progress-circle__slice">
                <div class="ko-progress-circle__fill"></div>
            </div>
            <div class="ko-progress-circle__slice">
                <div class="ko-progress-circle__fill"></div>
                <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
            </div>
        </div>
        <div class="ko-progress-circle__overlay" :class="{muted}"></div>
        <div class="volume-text">{{volume}}</div>
    </div>
    <controls-event-bus @vol_plus="volumeUp" @vol_minus="volumeDown" @mute="muted = !muted"/>
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import device from '@/device'

let hideTimeout

export default {
  data() {
    return {
      visible: false,
      muted: false,
    }
  },
  beforeDestroy() {
    clearTimeout(hideTimeout)
  },
  watch: {
    muted(mute) {
      if (mute) {
        device.setVolume(0)
      } else {
        device.setVolume(this.volume)
      }
    }
  },
  methods: {
    volumeUp() {
      this.show()
      if (this.volume < 100) {
        this.$store.dispatch('setVolume', this.volume + 1)
      }
    },
    volumeDown() {
      this.show()
      if (this.volume > 0) {
        this.$store.dispatch('setVolume', this.volume - 1)
      }
    },
    show() {
      this.muted = false
      this.visible = true
      clearTimeout(hideTimeout)
      hideTimeout = setTimeout(() => this.visible = false, 1000)
    },
  },
  computed: {
    volume() {
      return this.$store.state.settings.volume
    }
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  $circle-size: 170px;
  $circle-background: #D9D9D9;
  $circle-color: $color-primary;
  $shadow-color: #222;
  $inset-size: 100px;
  $inset-color: #444;

  .volume-text {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 5px #333;
    font-size: 30px;
  }

  .ko-progress-circle {
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    z-index: 10000;

    width:  $circle-size;
    height: $circle-size;

    box-shadow: 0 0 20px $shadow-color;

    background-color: $circle-background;
    border-radius: 50%;

    .ko-progress-circle__slice, .ko-progress-circle__fill {
      width:    $circle-size;
      height:   $circle-size;
      position: absolute;
      -webkit-backface-visibility: hidden;
      border-radius: 50%;
    }
    .ko-progress-circle__slice {
      clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
      .ko-progress-circle__fill {
        clip: rect(0px, $circle-size/2, $circle-size, 0px);
        background-color: $circle-color;
      }
    }
    .ko-progress-circle__overlay {
      width:       $inset-size;
      height:      $inset-size;
      position:    absolute;
      margin-left: ($circle-size - $inset-size)/2;
      margin-top:  ($circle-size - $inset-size)/2;

      box-shadow: 0 0 20px $shadow-color inset;

      background-color: $inset-color;
      border-radius: 50%;
      background-image: url(../assets/img/volume.svg);
      background-repeat: no-repeat;
      background-size: $inset-size - 40px;
      background-position: 20px;
      &.muted {
        background-image: url(../assets/img/volume-off.svg);
      }
    }

    $i: 0;
    $increment: 180deg / 100;
    @while $i <= 100 {
      &[data-progress='#{$i}'] {
          .ko-progress-circle__slice.full, .ko-progress-circle__fill {
            transform: rotate($increment * $i);
          }
          .ko-progress-circle__fill.ko-progress-circle__bar {
            transform: rotate($increment * $i * 2);
          }
        $i: $i + 1;
      }
    }
  }
</style>
