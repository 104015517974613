<template>
  <div class="modal-overlay" v-if="visible">
    <div class="modal-container">
      <div class="modal-body">
        <slot/>
      </div>
      <div class="modal-footer" v-if="!noClose">
        <div v-if="needConfirm">
          <div class="btn-enter" @click="confirmAction">
            <img src="@/assets/img/button-ok.svg"/>
          </div>
          {{ confirmText || 'Да' }}
          <div class="btn-close" @click="close">
            <img src="@/assets/img/button-back.svg"/>
          </div>
          {{ closeText || 'Нет' }}
        </div>
        <div v-else>
          <div class="btn-close" @click="close">
            <img src="@/assets/img/button-back.svg"/>
          </div>
          Закрыть
        </div>
      </div>
    </div>
    <global-events v-if="!noClose" @keydown="keyHandler"/>
  </div>
</template>

<script>
import GlobalEvents from '@/components/GlobalEvents.vue'
import keyMap from '@/keymap'

export default {
  props: ['needConfirm', 'noClose', 'confirmText', 'closeText'],
  data() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.$store.commit('showModal', true)
      this.visible = true
    },
    hide() {
      this.visible = false
      this.$store.commit('showModal', false)
    },
    keyHandler(e) {
      switch (keyMap[e.keyCode]) {
        case 'enter':
          this.confirmAction()
          break;
        case 'back':
          this.close()
          break;
      }
    },
    confirmAction() {
      if (this.needConfirm) {
        this.hide()
        this.$emit('confirm')
      }
    },
    close() {
      this.hide()
      this.$emit('close')
    }
  },
  components: {
    GlobalEvents
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    background-color: #252525;
    border-radius: 8px;
    text-align: center;
  }
  .modal-body {
    margin: 50px;
    color: #eeeeee;
    font-size: 28px;
    line-height: 30px;
    white-space: pre-wrap;
  }
  .modal-footer {
    background: $color-dark;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #272727;
    > div {
      border-top: 1px solid #393939;
      line-height: 60px;
    }
  }
  .btn-close, .btn-enter {
    width: 50px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #868686;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      background-color: #404040;
    }
  }
  .btn-enter + div {
    margin-left: 30px;
  }
</style>
