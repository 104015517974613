<template>
  <div class="catalog-view">
    <div class="catalog-menu" :class="{active: menuActive}" v-if="categories.length">
      <div class="menu-item search" :class="{active: -1 == menuIndex}" @click="menuActive = true; menuIndex = -1">Поиск</div>
      <div v-for="(category, index) in categories" :key="category.title"
        class="menu-item"
        :class="{active: index == menuIndex}"
        @click="menuActive = true; menuIndex = index"
      >
        {{ category.title }}
      </div>
    </div>
    <catalog-search v-if="searchActive"
      :active="!menuActive"
      @bound-top="menuActive = true"
      @content-selected="contentSelected($event)"
    />
    <div class="catalog-blocks">
      <catalog-block v-for="(block, index) in blocks"
        :key="block.title"
        :block="block"
        :category-name="currentCategory.name"
        :active="index == activeRow && !searchActive && !menuActive"
        :class="{hidden: index < activeRow}"
        @content-selected="contentSelected($event)"
        @bound-up="blockUp"
        @bound-down="blockDown"
      />
    </div>
    <progress-loader ref="pbInfo" :class="{hidden: -1 == menuIndex}"/>
    <controls-event-bus v-if="menuActive"
      @down="menuActive = false"
      @left="btnLeft"
      @right="btnRight"
    />
    <controls-event-bus @back="btnBack" />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import CatalogSearch from '@/components/CatalogSearch.vue'
import CatalogBlock from '@/components/CatalogBlock.vue'
import utils from '@/utils'
import api from '@/api'

let lastMenuIndex = 0
let lastActiveRow = 0
let lastCategories = []
let lastUpdate = 0

export default {
  data() {
    return {
      activeRow: lastActiveRow,
      menuIndex: lastMenuIndex,
      categories: lastCategories,
      menuActive: false,
    }
  },
  mounted() {
    document.querySelector('header .clock').style.display = 'none'
    if (utils.getUnixTime() - lastUpdate > 600) {
      this.categories.forEach(category => category.blocks = [])
      this.$refs.pbInfo.show('loader', 'Загрузка')
      api.getCatalogBlocks().then(categories => {
        this.categories = categories.filter(category => category.blocks.length)
        this.$refs.pbInfo.hide()
        this.activeRow = 0
        lastActiveRow = 0
        lastUpdate = utils.getUnixTime()
        lastCategories = this.categories
      }).catch(() => this.$refs.pbInfo.show('error', 'Ошибка загрузки каталога. Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    }
  },
  beforeDestroy() {
    document.querySelector('header .clock').style.display = ''
    lastMenuIndex = this.menuIndex
    lastActiveRow = this.activeRow
  },
  watch: {
    activeRow(row) {
      if (row > this.blocks.length - 2) {
        api.getCatalogBlocks(this.currentCategory.name, this.blocks.length).then(categories => {
          if (categories.length && categories[0].blocks.length) {
            this.blocks.push(...categories[0].blocks)
          }
        })
      }
    },
  },
  computed: {
    currentCategory() {
      return this.categories[this.menuIndex] || {}
    },
    blocks() {
      return this.currentCategory.blocks || []
    },
    searchActive() {
      return this.menuIndex == -1
    }
  },
  methods: {
    blockUp() {
      if (--this.activeRow < 0) {
        this.activeRow = 0
        this.menuActive = true
      }
    },
    blockDown() {
      if (++this.activeRow > this.blocks.length - 1) {
        this.activeRow = this.blocks.length - 1
      }
    },
    btnLeft() {
      if (--this.menuIndex < 0) {
        this.menuIndex = -1
        this.menuActive = false
      }
      this.activeRow = 0
    },
    btnRight() {
      if (++this.menuIndex > this.categories.length - 1) {
        this.menuIndex = this.categories.length - 1
      }
      this.activeRow = 0
    },
    btnBack() {
      if (this.menuActive) {
        this.$router.replace({name: 'home'})
      } else {
        this.menuActive = true
      }
    },
    contentSelected(program) {
      lastUpdate = utils.getUnixTime()
      const args = {
        name: 'catalog-content',
        params: {id: program.content_id},
      }
      this.$router.replace(args)
    }
  },
  components: {
    CatalogBlock,
    CatalogSearch,
    ProgressLoader,
    ControlsEventBus,
  }
}
</script>

<style lang="scss" scoped>
  .catalog-view {
    height: 100%;
  }
  .catalog-menu {
    color: #878787;
    position: absolute;
    top: 55px;
    left: 40px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;

    &.active {
      .menu-item.active {
        background-color: $color-primary;
        color: #000;
        border: 0;
        &.search {
          background-image: url(../assets/img/search-black.svg);
        }
      }
    }
    .menu-item {
      border-radius: 2px;
      margin-right: 10px;
      padding: 10px;
      display: inline-block;
      cursor: pointer;
      &.active {
        color: #fff;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: 0;
          height: 3px;
          background-color: $color-primary;
        }
        &.search {
          background-image: url(../assets/img/search-white.svg);
        }
      }
    }
    .search {
      padding-left: 35px;
      background: url(../assets/img/search.svg) no-repeat 20px;
      background-position: 8px;
    }
  }
  .catalog-blocks {
    margin: 0 auto;
    width: 1194px;
  }
  .hidden {
    display: none;
  }
</style>
