<template>
  <div class="vkb-overlay">
    <div class="keyboard" :class="{digital, symbols: symbolsKb, shift: shiftPressed}">
      <div class="title"><slot/></div>
      <pre class="input">{{displayVal}}</pre>
      <table>
        <tr v-for="(row, rowIndex) in layout" :key="rowIndex">
          <td v-for="(col, colIndex) in row" :key="colIndex"
            :class="[col.class, {
              active: !inactive &&
              currentRow == rowIndex &&
              currentCol >= colIndex &&
              currentCol < colIndex + (col.cols || 1)
            }]"
            :colspan="col.cols"
            v-if="!col.skip"
          >
            <span v-if="col.t" @click="clickBtn(rowIndex, colIndex)">{{col.t}}</span>
          </td>
        </tr>
      </table>
    </div>
    <controls-event-bus v-if="!inactive"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter.prevent="btnEnter"
      @back="btnBack"
      @digit="btnDigit"
      @del="backspace"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

let blinkInterval

export default {
  props: ['value', 'digital', 'lang', 'inactive', 'withBounds', 'liveUpdate', 'noCursor'],
  data() {
    return {
      val: '',
      symbolsKb: false,
      shiftPressed: false,
      langRu: this.lang == 'ru',
      inputIndex: 0,
      currentRow: 0,
      currentCol: 0,
      cursorVisible: false,
      layouts: {
        digital: [
          [{t: '1'}, {t: '2'}, {t: '3'}],
          [{t: '4'}, {t: '5'}, {t: '6'}],
          [{t: '7'}, {t: '8'}, {t: '9'}],
          [
            {t: 'Left', class: 'fnKey kLeft', action: this.arrowLeft},
            {t: '0'},
            {t: 'Right', class: 'fnKey kRight', action: this.arrowRight}
          ],
          [
            {t: 'Backspace', class: 'fnKey kBackspace', action: this.backspace},
            {t: 'Enter', class: 'fnKey kEnter', cols: 2, action: this.kbEnter},
          ]
        ],
        symbols: [
          [{t: '_'}, {t: '|'}, {t: ':'}, {t: ';'}, {t: '('}, {t: ')'}, {t: '/'}, {}, {t: 'Backspace', class: 'fnKey kBackspace', cols: 2, action: this.backspace}],
          [{t: '='}, {t: '%'}, {t: '$'}, {t: '&'}, {t: '@'}, {t: '"'}, {t: '*'}, {}, {t: 'Enter', class: 'fnKey kEnter', cols: 2, action: this.kbEnter}],
          [{t: '?'}, {t: '!'}, {t: "'"}, {t: '`'}, {t: '<'}, {t: '>'}, {t: '-'}, {}, {t: 'ABC', class: 'fnKey', cols: 2, action: this.symbolsToggle}],
          [
            {t: '['}, {t: ']'}, {t: "{"}, {t: '}'}, {t: '#'}, {t: ','}, {t: '+'}, {},
            {t: 'Left', class: 'fnKey kLeft', action: this.arrowLeft}, {t: 'Right', class: 'fnKey kRight', action: this.arrowRight},
          ],
        ],
        'lang-en': [
          [{t: '1'}, {t: '2'}, {t: '3'}, {t: '4'}, {t: '5'}, {t: '6'}, {t: '7'}, {t: '8'}, {t: '9'}, {t: '0'}, {t: '-'}],
          [{t: 'q'}, {t: 'w'}, {t: 'e'}, {t: 'r'}, {t: 't'}, {t: 'y'}, {t: 'u'}, {t: 'i'}, {t: 'o'}, {t: 'p'}, {t: '.'}],
          [{t: 'a'}, {t: 's'}, {t: 'd'}, {t: 'f'}, {t: 'g'}, {t: 'h'}, {t: 'j'}, {t: 'k'}, {t: 'l'}, {t: 'Backspace', class: 'fnKey kBackspace', cols: 2, action: this.backspace}],
          [
            {t: 'Shift', class: 'fnKey kShift', action: this.shiftToggle},
            {t: 'z'}, {t: 'x'}, {t: 'c'}, {t: 'v'}, {t: 'b'}, {t: 'n'}, {t: 'm'}, {t: ','},
            {t: 'Left', class: 'fnKey kLeft', action: this.arrowLeft}, {t: 'Right', class: 'fnKey kRight', action: this.arrowRight},
          ],
          [
            {t: 'RU', class: 'fnKey', action: this.langToggle},
            {t: '?!#', class: 'fnKey kAbc', action: this.symbolsToggle},
            {t: ' ', class: 'kSpace', cols: 7},
            {t: 'ВВОД', class: 'fnKey kEnter', cols: 2, action: this.kbEnter}
          ],
        ],
        'lang-ru': [
          [{t: '1'}, {t: '2'}, {t: '3'}, {t: '4'}, {t: '5'}, {t: '6'}, {t: '7'}, {t: '8'}, {t: '9'}, {t: '0'}, {t: '-'}, {t: 'э'}],
          [{t: 'й'}, {t: 'ц'}, {t: 'у'}, {t: 'к'}, {t: 'е'}, {t: 'н'}, {t: 'г'}, {t: 'ш'}, {t: 'щ'}, {t: 'з'}, {t: 'х'}, {t: 'ъ'}],
          [{t: 'ф'}, {t: 'ы'}, {t: 'в'}, {t: 'а'}, {t: 'п'}, {t: 'р'}, {t: 'о'}, {t: 'л'}, {t: 'д'}, {t: 'ж'}, {t: 'Backspace', class: 'fnKey kBackspace', cols: 2, action: this.backspace}],
          [
            {t: 'Shift', class: 'fnKey kShift', action: this.shiftToggle},
            {t: 'я'}, {t: 'ч'}, {t: 'с'}, {t: 'м'}, {t: 'и'}, {t: 'т'}, {t: 'ь'}, {t: 'б'}, {t: 'ю'},
            {t: 'Left', class: 'fnKey kLeft', action: this.arrowLeft}, {t: 'Right', class: 'fnKey kRight', action: this.arrowRight},
          ],
          [
            {t: 'EN', class: 'fnKey', action: this.langToggle},
            {t: '?!#', class: 'fnKey kAbc', action: this.symbolsToggle},
            {t: ' ', class: 'kSpace', cols: 8},
            {t: 'ВВОД', class: 'fnKey kEnter', cols: 2, action: this.kbEnter}
          ],
        ],
      }
    }
  },
  mounted() {
    this.val = this.value
    if (this.val) {
      this.inputIndex = this.val.length
    }
    if (!this.noCursor) {
      blinkInterval = setInterval(() => this.cursorVisible = !this.cursorVisible, 1000)
    }
    Object.keys(this.layouts).forEach(layout => {
      this.layouts[layout].forEach(row => {
        for (let i = 0; i < row.length; ++i) {
          const col = row[i]
          if (col.cols > 1) {
            const emptyCol = Object.assign({}, col)
            emptyCol.skip = true
            row.splice(i + 1, 0, ...Array(col.cols - 1).fill(emptyCol))
            i += col.cols - 1
          }
        }
      })
    })
  },
  beforeDestroy() {
    clearInterval(blinkInterval)
  },
  computed: {
    displayVal() {
      return this.val.slice(0, this.inputIndex) + (this.cursorVisible ? '|' : ' ') + this.val.slice(this.inputIndex)
    },
    currentLayout() {
      if (this.digital) {
        return 'digital'
      }
      if (this.symbolsKb) {
        return 'symbols'
      }
      return 'lang-' + (this.langRu ? 'ru' : 'en')
    },
    layout() {
      return this.layouts[this.currentLayout]
    },
    maxRow() {
      return this.layout.length - 1
    },
    maxCol() {
      return this.layout[this.currentRow] ? this.layout[this.currentRow].length - 1 : 0
    },
    currentBtn() {
      return this.layout[this.currentRow][this.currentCol]
    },
  },
  watch: {
    val() {
      if (this.liveUpdate) {
        this.kbEnter()
      }
    },
    currentCol(col, prevCol) {
      if (!this.layout[this.currentRow]) {
        return
      }
      if (this.currentCol < 0) {
        if (this.withBounds) {
          this.currentCol = 0
          return this.$emit('bound-left')
        }
        return this.currentCol = this.maxCol
      }
      if (this.currentCol > this.maxCol) {
        if (this.withBounds) {
          this.currentCol = this.maxCol
          return this.$emit('bound-right')
        }
        return this.currentCol = 0
      }
      const prevBtn = this.layout[this.currentRow][prevCol]
      if (!this.currentBtn.t || (prevBtn && this.currentBtn.t == prevBtn.t)) {
        this.currentCol += col - prevCol
      }
    }
  },
  methods: {
    btnDigit(e) {
      this.addChar(e.digit)
    },
    btnUp() {
      if (--this.currentRow < 0) {
        if (this.withBounds) {
          this.currentRow = 0
          return this.$emit('bound-top')
        }
        this.currentRow = this.maxRow
      }
    },
    btnDown() {
      if (++this.currentRow > this.maxRow) {
        if (this.withBounds) {
          this.currentRow = this.maxRow
          return this.$emit('bound-bottom')
        }
        this.currentRow = 0
      }
    },
    btnLeft() {
      --this.currentCol
    },
    btnRight() {
      ++this.currentCol
    },
    btnEnter() {
      const btn = this.currentBtn
      if (btn.action) {
        btn.action()
      } else {
        this.addChar(this.shiftPressed ? btn.t.toUpperCase() : btn.t)
      }
    },
    btnBack() {
      this.$emit('close')
    },
    clickBtn(rowIndex, colIndex) {
      this.currentRow = rowIndex
      this.currentCol = colIndex
      this.btnEnter()
    },
    kbEnter() {
      const val = this.val || ''
      this.$emit('input', val.trim())
    },
    arrowLeft() {
      if (--this.inputIndex < 0) {
        this.inputIndex = 0
      }
    },
    arrowRight() {
      if (++this.inputIndex > this.val.length) {
        this.inputIndex = this.val.length
      }
    },
    backspace() {
      if (this.inputIndex <= 0) {
        return
      }
      if (!this.val) {
        this.val = ''
      }
      this.val = this.val.slice(0, this.inputIndex - 1) + this.val.slice(this.inputIndex);
      --this.inputIndex
    },
    addChar(char) {
      if (!this.val) {
        this.val = ''
      }
      this.val = this.val.slice(0, this.inputIndex) + char + this.val.slice(this.inputIndex);
      this.arrowRight()
    },
    langToggle() {
      this.langRu = !this.langRu
    },
    shiftToggle() {
      this.shiftPressed = !this.shiftPressed
    },
    symbolsToggle() {
      this.symbolsKb = !this.symbolsKb
      this.$nextTick(() => {
        this.currentRow = 0
        this.currentCol = 0
      })
    },
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  .keyboard {
    background-color: #313131;
    border-radius: 10px;
    margin: 100px auto;
    padding: 20px;
    width: 980px;

    &.digital {
      width: 310px;
    }
    &.symbols {
      width: 630px;
    }
    &.digital, &.symbols {
      .kEnter span {
        background-image: url(../assets/img/vkb/enter.png);
        font-size: 0px !important;
        color: transparent;
        text-shadow: none;
      }
    }
    &.shift td {
      text-transform:uppercase;
      &.kShift span {
        background-color: #DDD;
        color: #333;
      }
    }
    .title {
      color: #fff;
      font-size: 32px;
      padding-bottom: 10px;
    }
  }
  .input {
    background-color: #e4e4e4;
    border: 2px solid #d2d2d2;
    color: #5a5a5a;
    font-size: 32px;
    font-weight: normal;
    height: 50px;
    line-height: 50px;
    margin: 0 0 20px;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: monospace;
  }
  table {
    margin: 5px 0;
    width: 100%;
    border-spacing: 2px 6px;
  }
  td {
    margin: 0;
    padding: 30px;
    position: relative;

    span {
      color: #fff;
      font-family: Tahoma, Sans-Serif;
      font-size: 32px;
      text-align: center;
      cursor: pointer;
      border: 0px;
      overflow: hidden;
      text-shadow: 0 0 10px rgba(0,0,0,0.4);
      border-radius: 6px;
      position: absolute;
      top: 0;
      left: 3px;
      bottom: 0;
      right: 3px;
      margin: 0;
      padding: 0;
      background-position: center;
      background-repeat: no-repeat;
      line-height: 14px;

      box-shadow: inset 0px 1px 0px rgba(255,255,255,0.4), inset 0px -1px 1px rgba(0,0,0,1);
      padding-top: 23px;
      background-color: #525152;
    }

    &.fnKey span {
      background-color: #212021;
    }
    &.kLeft span {
      background-image: url(../assets/img/vkb/left.png);
      font-size: 0px !important;
    }
    &.kRight span {
      background-image: url(../assets/img/vkb/right.png);
      font-size: 0px !important;
    }
    &.kShift span {
      background-image: url(../assets/img/vkb/shift.png);
      font-size: 0px !important;
    }
    &.kBackspace {
      span {
        background-image: url(../assets/img/vkb/backspace.png);
        font-size: 0px !important;
        color: transparent;
        text-shadow: none;
      }
    }
    &.kSpace {
      span {
        background-color: #212421;
      }
    }
    &.kAbc span {
      font-size: 22px;
    }

    &.active span, span.active {
      background-color: #DDD;
      color: #333;
    }
  }
</style>
