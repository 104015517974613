<template>
  <div class="modal-overlay">
    <div class="variants">
      <div class="variant-button" v-for="(variant, vIndex) in episode.play" :key="variant.type"
        :class="{active: vIndex == variantIndex, locked: !variant.is_avail}">
        {{ variant.title }}
      </div>
    </div>
    <controls-event-bus
      @up="variantUp"
      @down="variantDown"
      @enter="variantSelected"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

export default {
  props: ['episode'],

  data() {
    return {
      variantIndex: 0,
    }
  },
  methods: {
    variantUp() {
      if (--this.variantIndex < 0) {
        this.variantIndex = 0
      }
    },
    variantDown() {
      if (++this.variantIndex > this.episode.play.length - 1) {
        this.variantIndex = this.episode.play.length - 1
      }
    },
    variantSelected() {
      const play = this.episode.play[this.variantIndex]
      if (play) {
        this.$emit('content-selected', Object.assign({},
          play,
          {content_id: this.episode.content_id}
        ))
      }
    },
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  .variants {
    background-color: $color-dark;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    margin: 300px auto;
  }
  .variant-button {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url(../../assets/img/play-circle-outline.svg) no-repeat;
    background-color: #ddd;
    background-position: 10px 5px;
    background-size: 30px;
    padding-left: 45px;
    &:last-child {
      margin: 0;
    }
    &.active {
      background-color: $color-primary;
    }
    &.locked {
      background-image: url(../../assets/img/lock-black.svg);
      background-size: 26px;
      background-position: 12px 7px;
    }
  }
</style>
