<template>
  <global-events @keydown="keyHandler"/>
</template>

<script>
import GlobalEvents from '@/components/GlobalEvents'
import keyMap from '@/keymap'

const textKeyMap = {ColorF0Red: 112, ColorF1Green: 113, ColorF2Yellow: 114, ColorF3Blue: 115}

export default {
  methods: {
    keyHandler(e) {
      e.preventDefault()
      if (this.$store.state.modalVisible) {
        return
      }
      const keyName = keyMap[e.keyCode === 0 ? textKeyMap[e.key] : e.keyCode]
      if (keyName) {
        e.keyName = keyName
        if (keyName == 'digit') {
          const delta = e.keyCode > 57 ? 96 : 48
          e.digit = (e.keyCode - delta) + ''
        }
        this.$emit(keyName, e)
        this.$emit('keydown', e)
      }
    }
  },
  components: {
    GlobalEvents
  }
}
</script>
