<template>
  <div :class="{active}">
    <ul :class="animationClass">
      <li v-for="(program, index) in currentPrograms" :key="program.id" :class="{active: index == activeIndex}">
        <div class="epg-item" v-if="program.begin_time == -1">
          <div class="pg-image">
            <i class="ch-icon chi-81" :class="`ch-${channel.icon_id}`"/>
          </div>
          <div class="pg-details">
            <div class="title">{{ channel.name }}</div>
            <div class="label">программа и архив</div>
          </div>
        </div>
        <template v-else>
          <div class="pg-image" :class="{inactive: isInactive(program)}" :style="{backgroundImage: `url(${program.image_url})`}" v-if="program.name">
            <div class="badge" :class="badgeClass(program)">
              {{ badgeText(program) }}
            </div>
          </div>
          <program-details :program="program" />
        </template>
      </li>
    </ul>
    <controls-event-bus v-if="active"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ProgramDetails from '../ProgramDetails.vue'
import utils from '@/utils'
import api from '@/api'

const ITEMS_COUNT = 9

export default {
  props: ['channel', 'active', 'startActiveTime', 'playTime'],
  data() {
    return {
      activeIndex: 2,
      activeTime: 0,
      animationClass: '',
    }
  },
  mounted() {
    this.activeTime = this.startActiveTime
  },
  watch: {
    active() {
      if (this.active) {
        api.appLog('carousel-programs-show', this.channel.name)
      }
    },
    activeTime() {
      if (!this.activeProgram || (this.activeProgram.begin_time <= this.playTime && this.activeProgram.end_time > this.playTime)) {
        this.activeTime = 0
      }
      this.$emit('change-active-time', this.activeTime)
    },
    startActiveTime(time) {
      this.activeTime = time
    }
  },
  methods: {
    btnLeft() {
      if (this.currentPrograms[this.activeIndex - 1].begin_time) {
        this.activeTime = this.currentPrograms[this.activeIndex - 1].begin_time
        /*if (this.$store.state.settings.useAnimation) {
          this.animationClass = 'to-left'
          this.$nextTick(() => setTimeout(() => this.animationClass = '', 20))
        }*/
      }
    },
    btnRight() {
      if (this.currentPrograms[this.activeIndex + 1].name) {
        this.activeTime = this.currentPrograms[this.activeIndex + 1].begin_time
        /*if (this.$store.state.settings.useAnimation) {
          this.animationClass = 'to-right'
          this.$nextTick(() => setTimeout(() => this.animationClass = '', 20))
        }*/
        if (this.hasRecord(this.activeProgram) && !this.currentPrograms[ITEMS_COUNT - 1].name) {
          this.$store.dispatch('updateEpg', {
            channel_id: this.channel.id,
            start: this.activeProgram.begin_time - 86400,
            end: this.activeProgram.begin_time
          })
        }
      }
    },
    btnEnter() {
      if (this.activeProgram.begin_time == -1) {
        this.$router.replace({name: 'epg', params: {id: this.channel.id}})
        return
      }
      if (this.hasRecord(this.activeProgram)) {
        api.appLog('carousel-programs-ok', this.channel.name)
        this.$emit('selected', this.activeProgram.begin_time)
        this.activeTime = 0
      } else {
        api.appLog('carousel-programs-message', this.channel.name)
        const now = utils.getUnixTime()
        let text = this.channel.has_record
          ? (this.activeProgram.begin_time > now ? 'Данная передача ещё не началась' : 'Данная передача не записана в архив')
          : 'Данный канал не имеет архива'
        this.$emit('error', text)
      }
    },
    badgeClass(program) {
      const now = utils.getUnixTime()
      if (program.begin_time > now) {
        return ''
      }
      if (program.begin_time <= now && program.end_time > now ) {
        return 'live'
      }
      return this.hasRecord(program) ? 'archive' : 'hidden'
    },
    badgeText(program) {
      const now = utils.getUnixTime()
      if (program.begin_time > now) {
        if (program.begin_time - 3600 < now) {
          return 'через '+Math.ceil((program.begin_time - now) / 60)+' мин.'
        } else {
          return 'в ' + program.time
        }
      }
      if (program.begin_time <= now && program.end_time > now ) {
        return 'эфир'
      }
      return 'архив'
    },
    hasRecord(program) {
      if (!program) {
        return false
      }
      const now = utils.getUnixTime()
      return this.channel.has_record && program.begin_time <= now && program.end_time > now - this.channel.archive_days * 86400
    },
    isInactive(program) {
      const now = utils.getUnixTime()
      return program.begin_time > now || (program.end_time < now && !this.hasRecord(program))
    }
  },
  computed: {
    programs() {
      return this.$store.getters.channelEpg(this.channel.id)
    },
    currentPrograms() {
      const programTime = this.activeTime ? this.activeTime : this.playTime
      const programs = this.programs.slice()
      programs.reverse()
      const now = utils.getUnixTime()
      const liveIndex = programs.findIndex(prog => prog.begin_time <= now && prog.end_time > now)
      if (liveIndex > 0) {
        programs.splice(0, liveIndex - 2, {begin_time: -1, end_time: 0})
      }
      let programIndex = programs.findIndex(prog => prog.begin_time <= programTime && prog.end_time > programTime)
      if (programIndex >= 0) {
        while (!programs[programIndex - this.activeIndex]) {
          programs.splice(0, 0, {})
          ++programIndex
        }
        while (!programs[programIndex + ITEMS_COUNT]) {
          programs.push({})
        }
        return programs.slice(programIndex - this.activeIndex, programIndex + (ITEMS_COUNT - this.activeIndex))
      }
      return []
    },
    activeProgram() {
      return this.currentPrograms[this.activeIndex]
    }
  },
  components: {
    ControlsEventBus,
    ProgramDetails,
  }
}
</script>

<style lang="scss" scoped>
  .epg-item {
    background-color: #fff;
    .pg-image {
      box-sizing: border-box;
      text-align: center;
      padding-top: 45px;
    }
    .pg-details {
      text-align: center;
      > div {
        box-sizing: border-box;
      }
      .title {
        color: #333;
      }
      .label {
        padding-top: 10px;
        color: #777;
      }
    }
  }
</style>
