<template>
  <div class="ch-program-view">
    <div class="header-title">
      <i class="ch-icon chi-54" :class="`ch-${channel.icon_id}`"/>
      <div class="ch-info">
        <div class="ch-name">{{channel.name}}</div>
        <div class="page-title">Программа передач и архив</div>
      </div>
    </div>
    <div class="day-control">
      <img src="@/assets/img/arrow-left-bold-yellow.svg" />
      <span>{{weekDay}}, {{day}} {{month}}</span>
      <img src="@/assets/img/arrow-right-bold-yellow.svg" />
    </div>
    <ul class="epg-menu">
      <li v-for="(program, index) in pagedPrograms" :key="program.id" :class="{current: programIndex == index}" @mouseover="programIndex = index" @click="play">
        <div class="pg-type" :class="{live: program.live}" v-if="hasRecord(program) || program.live">
          <span v-if="program.live">эфир</span>
          <span v-else>архив</span>
        </div>
        <div class="pg-time">{{program.time}}</div>
        <div class="pg-name">{{program.full_name}}</div>
      </li>
    </ul>
    <div class="epg-info" v-if="!emptyEpg">
      {{currentProgram.full_name}}
      <br/><br/>
      <div class="desc-text">
        {{currentProgram.description}}
      </div>
    </div>
    <div class="empty-epg" v-if="emptyEpg && !loading">
      <div class="empty-epg-text">
        Извините, программа передач для данного телеканала отсутствует.
      </div>
    </div>
    <progress-loader ref="pbInfo"/>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="red" @click="btnRed"/>
        <span>Сегодня</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="left" @click="btnLeft"/>
        <control-hint-btn type="right" @click="btnRight"/>
        <span>День недели</span>
      </div>
      <div class="btn-group" :class="{inactive: !hasRecord(currentProgram)}">
        <control-hint-btn type="enter" @click="btnEnter"/>
        <span>Просмотр</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="menu" @click="btnMenu"/>
        <span>Меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus
      @up="btnUp"
      @down="btnDown"
      @red="btnRed"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
      @back="btnBack"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import utils from '@/utils'

const PER_PAGE = 14
let updateLiveInterval

export default {
  data() {
    return {
      page: 0,
      dayOffset: 0,
      programIndex: 0,
      loading: true,
      leftUpdated: false,
      rightUpdated: false,
    }
  },
  mounted() {
    this.$refs.pbInfo.show('loader', 'Загрузка телепрограммы')
    const progTime = this.$route.query.time
    if (progTime) {
      this.dayOffset = utils.getDayOffset(progTime, utils.getUnixTime())
    }
    const start = utils.getDayRange(this.dayOffset - 2).start
    const end = utils.getDayRange(this.dayOffset + 1).end
    document.querySelector('header .clock').style.display = 'none'

    Promise.resolve(
      this.epg.length || !this.channel.has_epg ? 0 : this.$store.dispatch('updateEpg', {channel_id: this.$route.params.id, start, end})
    ).then(() => {
      const updateLive = () => {
        const now = utils.getUnixTime()
        const curLive = this.epg.find(prog => prog.live)
        const curProg = this.epg.find(prog => prog.begin_time <= now && prog.end_time > now)
        if (curLive === curProg) {
          return
        }
        if (curLive) {
          curLive.live = false
        }
        if (curProg) {
          curProg.live = true
        }
      }
      updateLiveInterval = setInterval(updateLive, 60000)
      updateLive()

      const currentIndex = this.programs.findIndex(prog => progTime ? progTime >= prog.begin_time && progTime < prog.end_time : prog.live)
      if (currentIndex > 0) {
        this.page = Math.floor(currentIndex / PER_PAGE)
        this.programIndex = currentIndex - this.page * PER_PAGE
      }

      this.$refs.pbInfo.hide()
      this.loading = false

      this.$nextTick(() => {
        if (start < this.epgStart && this.epg.length) {
          this.$store.dispatch('updateEpg', {channel_id: this.$route.params.id, start, end: this.epgStart})
        }
        if (end > this.epgEnd && this.epg.length) {
          this.$store.dispatch('updateEpg', {channel_id: this.$route.params.id, start: this.epgEnd, end})
        }
      })
    })
    .catch(() => this.$refs.pbInfo.show('error', 'Неудалось загрузить телепрограмму. Попробуйте позже или обратитесь в тех. поддержку.'))

    if (!this.channel.id) {
      this.$store.dispatch('updatePrograms')
    }
  },
  beforeDestroy() {
    clearInterval(updateLiveInterval)
    document.querySelector('header .clock').style.display = ''
  },
  computed: {
    epg() {
      return this.$store.getters.channelEpg(this.$route.params.id)
    },
    epgStart() {
      return Math.min(...this.epg.map(prog => prog.begin_time))
    },
    epgEnd() {
      return Math.max(...this.epg.map(prog => prog.end_time))
    },
    channel() {
      return this.$store.getters.channelsMap[this.$route.params.id] || {}
    },
    programs() {
      const range = utils.getDayRange(this.dayOffset)
      return this.epg
        .filter(program => program.begin_time >= range.start && program.begin_time < range.end)
        .sort((a, b) => a.begin_time - b.begin_time)
    },
    pagedPrograms() {
      const start = this.page * PER_PAGE
      return this.programs.slice(start, start + PER_PAGE)
    },
    lastPage() {
      return Math.ceil(this.programs.length / PER_PAGE) - 1
    },
    emptyEpg() {
      return this.programs.length == 0
    },
    currentProgram() {
      return this.pagedPrograms[this.programIndex] || {}
    },
    date() {
      return utils.dateFromUnixTime(utils.getDayRange(this.dayOffset).start)
    },
    weekDay() {
      return utils.getWeekDay(this.date)
    },
    day() {
      return utils.getDay(this.date)
    },
    month() {
      return utils.getMonth(this.date)
    }
  },
  methods: {
    play() {
      if (this.hasRecord(this.currentProgram)) {
        this.$store.commit('resetPlayerState')
        const query = {time: this.currentProgram.begin_time}
        if (this.currentProgram.kinopoisk_id) {
          query.content_id = this.currentProgram.content_id
          query.duration = this.currentProgram.end_time - this.currentProgram.begin_time
        }
        const args = {name: 'player', params: {id: this.$route.params.id}, query}
        this.$router.replace(args)
      }
    },
    hasRecord(program) {
      const now = utils.getUnixTime()
      return program.begin_time <= now && program.end_time > now - this.channel.archive_days * 86400
    },
    btnUp() {
      if (--this.programIndex < 0) {
        if (--this.page < 0) {
          this.btnLeft()
          this.page = this.lastPage
        }
        this.programIndex = this.pagedPrograms.length - 1
      }
    },
    btnDown() {
      if (++this.programIndex >= this.pagedPrograms.length) {
        if (++this.page > this.lastPage) {
          this.btnRight()
        }
        this.programIndex = 0
      }
    },
    btnRed() {
      this.dayOffset = 0
      const currentIndex = this.programs.findIndex(prog => prog.live)
      if (currentIndex > 0) {
        this.page = Math.floor(currentIndex / PER_PAGE)
        this.programIndex = currentIndex - this.page * PER_PAGE
      }
    },
    btnLeft() {
      const start = utils.getDayRange(-8).start
      const needUpdateEpg = !this.leftUpdated && this.epgStart != Infinity && start < this.epgStart
      if (needUpdateEpg) {
        this.$store.dispatch('updateEpg', {channel_id: this.$route.params.id, start, end: this.epgStart})
          .then(() => this.leftUpdated = true)
      }
      if (this.epgStart < utils.getDayRange(this.dayOffset - 1).end) {
        --this.dayOffset
        this.page = 0
        this.programIndex = 0
      }
    },
    btnRight() {
      const end = utils.getDayRange(7).end
      const needUpdateEpg = !this.rightUpdated && this.epgEnd != -Infinity && end > this.epgEnd
      if (needUpdateEpg) {
        this.$store.dispatch('updateEpg', {channel_id: this.$route.params.id, start: this.epgEnd, end})
          .then(() => this.rightUpdated = true)
      }
      if (this.epgEnd > utils.getDayRange(this.dayOffset + 1).start) {
        ++this.dayOffset
        this.page = 0
        this.programIndex = 0
      }
    },
    btnEnter() {
      this.play()
    },
    btnMenu() {
      this.$router.replace({name: 'home'})
    },
    btnBack() {
      if (this.$store.state.playing) {
        utils.returnToPlayer()
      } else {
        this.$router.replace({name: 'tvchannels'})
      }
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    ProgressLoader,
  }
}
</script>

<style lang="scss" scoped>
  .ch-icon {
    border-radius: 5px;
    float: left;
  }
  .ch-info {
    margin-left: 25px;
    float: left;
  }
  .header-title {
    margin-top: -15px;
  }
  .ch-name {
    font-weight: 500;
    font-size: 26px;
    height: 26px;
    color: #FFFFFF;
    margin: 4px 0;
    text-transform: none;
  }
  .page-title {
    font-weight: 500;
    font-size: 15px;
    color: $color-primary;
    text-transform: uppercase;
  }
  .day-control {
    position: absolute;
    top: 60px;
    right: 345px;
    color: $color-primary;
    font-weight: 500;
    font-size: 26px;
    span {
      margin: 0 10px;
    }
  }
  .epg-menu {
    float: left;
    margin-left: 122px;
    li {
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      height: 32px;
      line-height: 32px;
      margin: 0 0 3px 0;
      position: relative;
      width: 685px;
      border-radius: 5px;
      > div {
        display: inline-block;
        vertical-align: middle;
      }
      &.current {
        color: $color-dark;
        background-color: $color-primary;
        .pg-time {
          color: $color-dark;
        }
      }
      .pg-type {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        height: 18px;
        width: 50px;
        margin-top: 7px;
        background: #A58686;
        color: $color-dark;
        margin-left: -66px;
        float: left;
        text-align: center;
        border-radius: 3px;
        opacity: 0.7;
        &.live {
          background-color: #FDCE58;
        }
      }
      .pg-time {
        color: $color-primary;
        font-weight: 600;
        width: 52px;
        margin: 0 14px 0 7px;
      }
      .pg-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 610px;
      }
    }
  }
  .epg-info {
    margin: 0 20px 0 830px;
    background: rgba(0,0,0,0.4);
    color: #fff;
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
    padding: 20px;
    border-radius: 5px;
    .desc-text {
      margin-top: 10px;
      line-height: 24px;
      font-size: 18px;
      overflow: auto;
      height: 400px;
    }
  }
  .empty-epg {
    background: transparent url(../assets/img/smile-sad.svg) center center no-repeat;
    color: #aaaaaa;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    height: 400px;

    .empty-epg-text {
      position: absolute;
      top: 50%;
      margin-top: 50px;
      width: 100%;
      text-align: center;
    }
  }
</style>
