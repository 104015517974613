<template>
  <div class="player-view" @click="showInfo">
    <template v-if="frozen">
      <modal ref="freezeMsg" @close="btnBack">
        {{ freezeMsg }}
      </modal>
    </template>
    <template v-else>
      <parent-code @confirm="confirmParentCode" v-if="needParentCode"/>
      <blocked-channel-link :channel="channel" @linked="packetLinked" v-if="blocked && !needParentCode"/>
      <div class="channel-packet" v-if="!channel.is_avail && !blocked">
        <img src="@/assets/img/lock.svg" />
        Ознакомительный просмотр
      </div>
      <div class="info-top" v-if="infoVisible && !needParentCode && !blocked">
        <div class="channel-info">
          <i class="ch-icon chi-54" :class="`ch-${channel.icon_id}`"/>
          <div>
            <div class="channel-name">
              {{channel.name}}
            </div>
            <div class="next-program">
              <span v-if="program.next_name">Далее:</span>
              {{program.next_name}}
            </div>
          </div>
          <div class="ch-num">{{channel.num}}</div>
        </div>
      </div>
      <div class="info-bottom" v-if="infoVisible && !needParentCode && !blocked">
        <player-controls
          :active="activeRow == 0"
          :unix-time="unixTime"
          :channel="channel"
          :init-event="controlsInitEvent"
          @play="play($event)"
          @hide-up="infoVisible = false"
          @hide-down="activeRow = 1"
          @program-changed="program = $event"
        />
        <sliders
          :active="activeRow == 1"
          :channel="channel"
          :program="program"
          :archive-time="archiveTime"
          :programs-active-time="programsActiveTime"
          @hide-up="activeRow = 0"
          @change-active-time="programsActiveTime = $event"
          @program-selected="programSelected($event)"
          @channel-selected="channelSelected($event)"
          @error="showInfoText($event)"
        />
      </div>
      <div class="text-bar" v-if="infoText">{{infoText}}</div>
      <controls-event-bus v-if="!needParentCode && !blocked"
        @red="btnRed"
        @blue="btnBlue"
        @ch_plus="nextChannel"
        @ch_minus="prevChannel"
        @digit="changeChannel"
        @keydown="showInfo"
      />
      <controls-event-bus @back="btnBack"/>
      <global-events @videostart="videoStart" @videoerror="videoError" @videoBufferingBegin="videoBufferingBegin"/>
    </template>
  </div>
</template>

<script>
import BlockedChannelLink from '@/components/BlockedChannelLink.vue'
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import PlayerControls from '@/components/Player/Controls.vue'
import ParentCode from '@/components/ParentCode.vue'
import GlobalEvents from '@/components/GlobalEvents.vue'
import Sliders from '@/components/Player/Sliders.vue'
import Modal from '@/components/Modal.vue'
import utils from '@/utils'
import WS from '@/WS'
import { mapState } from 'vuex'

let infoTimeout, updateTimeInterval, infoTextTimeout, resetParentCodeTimeout, liveWatchTimeout
let lastProgramUpdate = 0
let playStartTime = 0
const MIN_PROGRAM_UPDATE_SECS = 60
const HIDE_INFO_TIMEOUT = 5000
const LIVE_WATCH_DURATION = 300000

export default {
  data() {
    return {
      infoVisible: false,
      unixTime: utils.getUnixTime(),
      program: {},
      infoText: '',
      videoErrorCount: 0,
      programsActiveTime: 0,
      activeRow: 0,
      controlsInitEvent: '',
      freezeMsg: '',
    }
  },
  mounted() {
    document.querySelector('header').style.display = 'none'
    document.querySelector('#app').style.backgroundColor = 'transparent'

    if (this.frozen) {
      this.freezeMsg = utils.winkMigrationMessage()
      this.$refs.freezeMsg.show()
      return
    }

    if (!this.channelUrl) {
      this.$store.dispatch('updatePrograms')
    }
    if (this.$route.query.time) {
      this.$store.dispatch('stop')
      if (!this.archiveLoaded) {
        this.updateEpg(this.$route.query.time)
      }
      this.$store.commit('rewindTime', this.$route.query.time - this.unixTime)
      this.play(this.channelResumeUrl)
    } else {
      if (this.channelUrl && (!this.playing || this.lastChannelId != this.channel.id)) {
        this.$store.commit('playerState', {rewindTime: 0, paused: false})
        this.play(this.channelUrl)
      }
      if (!this.epg.length && !this.needParentCode) {
        this.updateEpg(this.unixTime)
      }
    }
    updateTimeInterval = setInterval(() => {
      this.unixTime = utils.getUnixTime()
    }, 1000)
  },
  beforeDestroy() {
    document.querySelector('header').style.display = ''
    document.querySelector('#app').style.backgroundColor = ''
    clearTimeout(infoTimeout)
    clearTimeout(infoTextTimeout)
    clearTimeout(liveWatchTimeout)
    clearInterval(updateTimeInterval)
  },
  watch: {
    infoVisible(visible) {
      if (!visible) {
        clearTimeout(infoTimeout)
      }
    },
    channelUrl() {
      clearTimeout(liveWatchTimeout)
      if (!this.program.begin_time) {
        this.activeRow = 0
      }
      this.$store.commit('rewindTime', 0)
      this.programsActiveTime = 0
      this.updateEpg(this.unixTime)
      this.play(this.channelUrl);
    },
    isLive(live) {
      if (live) {
        this.showInfoText('Прямой эфир')
      }
    },
    rewindTime() {
      clearTimeout(liveWatchTimeout)
      if (!this.archiveLoaded && this.archiveTime < this.archiveStart + 21600 && this.unixTime > (lastProgramUpdate + MIN_PROGRAM_UPDATE_SECS)) {
        lastProgramUpdate = this.unixTime
        this.updateEpg(this.archiveTime - 43200)
      }
    },
    activeRow(row) {
      if (!this.paused && row > 0) {
        clearTimeout(infoTimeout)
        infoTimeout = setTimeout(() => this.infoVisible = false, HIDE_INFO_TIMEOUT * 2)
      }
    },
    unixTime(newTime) {
      if (this.program.has_record != this.channel.has_record && this.program.begin_time > this.archiveStart) {
        this.program.has_record = this.channel.has_record
      }
      if (!this.program.next_name && newTime > (lastProgramUpdate + MIN_PROGRAM_UPDATE_SECS)) {
        lastProgramUpdate = newTime
        this.updateEpg(newTime)
      }
    }
  },
  computed: {
    ...mapState({
      playing: 'playing',
      lastChannelId: 'channelId',
      rewindTime: state => state.player.rewindTime,
      paused: state => state.player.paused,
    }),
    needParentCode() {
      return this.channel.is_parent_control && !this.$store.state.parentCodeValid
    },
    channel() {
      return this.$store.getters.channelsMap[this.$route.params.id] || {}
    },
    channelUrl() {
      return this.channel.url
    },
    channelResumeUrl() {
      return this.isLive ? this.channel.url : this.channel.archive_url + this.archiveTime
    },
    epg() {
      return this.$store.getters.channelEpg(this.$route.params.id)
    },
    archiveLoaded() {
      return this.archiveStart <= this.unixTime - this.channel.archive_days * 86400
    },
    archiveStart() {
      return Math.min(...this.epg
        .filter(prog => this.hasRecord(prog))
        .map(prog => prog.begin_time))
    },
    archiveTime() {
      return this.unixTime + this.rewindTime
    },
    isLive() {
      return this.rewindTime >= 0
    },
    blocked() {
      const channelId = this.channel.is_parent_control ? 'adult' : this.channel.id
      return !this.channel.is_avail && this.$store.state.unavailWatchLimit[channelId] < this.unixTime
    },
    frozen() {
      return !this.channel.is_avail && this.$store.state.accountInfo.frozen
    },
  },
  methods: {
    hasRecord(program) {
      return program.begin_time <= this.unixTime && program.end_time > this.unixTime - this.channel.archive_days * 86400
    },
    packetLinked() {
      this.$store.dispatch('packetsChanged')
        .then(() => this.play(this.channelResumeUrl))
    },
    confirmParentCode() {
      this.updateEpg(this.archiveTime)
      this.$store.commit('skipParentCode', true)
      this.play(this.channelResumeUrl)
    },
    play(url) {
      this.$store.dispatch('stop')
      if (!this.needParentCode) {
        this.showInfo()
        if (!this.isLive && this.rewindTime > -120 * 60) {
          url += '&endless=1'
        }
        this.$store.commit('log', url)
        clearTimeout(resetParentCodeTimeout)
        if (!this.channel.is_parent_control) {
          resetParentCodeTimeout = setTimeout(() => this.$store.commit('skipParentCode', false), 60000)
        }
        this.setLiveWatchTimeout()
        this.$store.dispatch('setLastChannelId', {
          id: this.channel.id,
          save: !this.channel.is_parent_control && this.channel.is_avail
        })
        this.$store.dispatch('play', {
          url,
          contentId: this.$route.query.content_id,
          duration: this.$route.query.duration,
        })
        playStartTime = this.unixTime
      } else {
        this.infoVisible = false
        this.$store.dispatch('updateAccountInfo')
      }
    },
    setLiveWatchTimeout() {
      clearTimeout(liveWatchTimeout)
      if (this.isLive && this.channel.id != this.lastChannelId) {
        liveWatchTimeout = setTimeout(() => WS.send('LiveWatch', {channel_id: this.channel.id}), LIVE_WATCH_DURATION)
      }
    },
    gotoChannel(num) {
      const channel = this.$store.getters.filteredChannels.find(chan => chan.num == num)
      if (channel) {
        this.$router.replace({name: 'player', params: {id: channel.id}})
      }
    },
    changeChannel(e) {
      clearTimeout(infoTextTimeout)
      this.infoText += e.digit;
      if (this.infoText.length > 3) {
        this.infoText = ''
      }
      infoTextTimeout = setTimeout(() => {
        this.gotoChannel(this.infoText)
        this.infoText = ''
      }, 1500)
    },
    nextChannel() {
      const channels = this.$store.getters.filteredChannels
      let nextIndex = channels.findIndex(chan => chan.id == this.channel.id) + 1
      if (nextIndex >= channels.length) {
        nextIndex = 0
      }
      this.$router.replace({name: 'player', params: {id: channels[nextIndex].id}})
    },
    prevChannel() {
      const channels = this.$store.getters.filteredChannels
      let nextIndex = channels.findIndex(chan => chan.id == this.channel.id) - 1
      if (nextIndex < 0) {
        nextIndex = channels.length - 1
      }
      this.$router.replace({name: 'player', params: {id: channels[nextIndex].id}})
    },
    updateEpg(startTime) {
      if (!this.channel.has_epg) {
        return
      }
      return this.$store.dispatch('updateEpg', {
        channel_id: this.$route.params.id,
        start: startTime - 86400,
        end: this.unixTime + 43200
      })
    },
    showInfo(e) {
      if (this.needParentCode) {
        return
      }
      clearTimeout(infoTimeout)
      if (e) {
        this.controlsInitEvent = e.keyName
        if (e.keyName == 'back') {
          return
        }
        if (e.keyName == 'vol_plus' || e.keyName == 'vol_minus') {
          this.infoVisible = false
          return
        }
        if (e.keyName == 'info') {
          this.infoVisible = !this.infoVisible
          return
        }
        if (!this.infoVisible) {
          this.activeRow = 0
        }
      }
      this.infoVisible = true
      if (!this.paused) {
        infoTimeout = setTimeout(() => this.infoVisible = false, this.activeRow > 0 ? HIDE_INFO_TIMEOUT * 2 : HIDE_INFO_TIMEOUT)
      }
    },
    showInfoText(text) {
      clearTimeout(infoTextTimeout)
      this.infoText = text
      infoTextTimeout = setTimeout(() => this.infoText = '', 1500)
    },
    programPlay(program) {
      if (program.channel_id != this.channel.id) {
        this.$router.replace({name: 'player', params: {id: program.channel_id}, query: {time: program.begin_time}})
        this.activeRow = 0
      } else {
        this.programSelected(program.begin_time)
      }
    },
    programSelected(programTime) {
      this.$store.commit('rewindTime', programTime - this.unixTime)
      this.$nextTick(() => this.play(this.channel.archive_url + this.archiveTime))
      this.activeRow = 0
    },
    channelSelected(channelId) {
      this.$router.replace({name: 'player', params: {id: channelId}})
      this.activeRow = 0
    },
    videoStart() {
      this.videoErrorCount = 0
      this.showInfo()
    },
    videoError() {
      if (!this.isLive && ++this.videoErrorCount >= 3) {
        this.$store.commit('rewindTime', 0)
        this.play(this.channel.url)
      }
    },
    videoBufferingBegin() {
      if (this.unixTime - playStartTime > 60) {
        this.play(this.channelResumeUrl)
      }
    },
    btnRed() {
      const args = {name: 'epg', params: {id: this.$route.params.id}}
      if (!this.isLive) {
        args.query = {time: this.archiveTime}
      }
      this.$router.replace(args)
    },
    btnBlue() {
      const args = {name: 'tvchannels'}
      this.$router.replace(args)
    },
    btnBack() {
      if (this.infoVisible && !this.blocked) {
        this.infoVisible = false
        return
      }
      if (this.$route.query.content_id) {
        this.$router.replace({name: 'catalog-content', params: {id: this.$route.query.content_id}})
      } else {
        this.btnBlue()
      }
    },
  },
  components: {
    BlockedChannelLink,
    ControlsEventBus,
    PlayerControls,
    GlobalEvents,
    ParentCode,
    Sliders,
    Modal,
  }
}
</script>

<style lang="scss" scoped>
  .player-view {
    width: 100%;
    height: 100%;
  }
  .info-top {
    height: 200px;
    background-image: linear-gradient(#000, transparent);
    .channel-info {
      padding-top: 44px;
      padding-left: 50px;
      .ch-icon {
        border-radius: 5px;
        float: left;
      }
      .ch-num {
        color: #fff;
        font-size: 45px;
        line-height: 54px;
        margin-left: 0;
        margin-right: 50px;
        opacity: 0.75;
        float: right;
      }
      > div {
        margin-left: 24px;
        float: left;
        .channel-name {
          font-weight: 500;
          font-size: 26px;
          line-height: 26px;
          color: #fff;
          margin: 4px 0;
        }
        .next-program {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 1024px;
          font-weight: 500;
          font-size: 15px;
          line-height: 15px;
          color: $color-primary;
          text-transform: uppercase;
        }
      }
    }
  }
  .channel-packet {
    color: #fff;
    opacity: 0.8;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: -1;
  }
  .info-bottom {
    height: 300px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(transparent, rgba(0,0,0,0.6) 45%, rgba(0,0,0,0.77));
    color: #fff;
  }
  .text-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.5);
    color: $color-primary;
    font-size: 32px;
    font-weight: 500;
    height: 100px;
    line-height: 100px;
    overflow: hidden;
    padding: 0 40px;
    text-align: center;
    text-overflow: ellipsis;
    min-width: 100px;
    z-index: 1;
    border-radius: 5px;
  }
</style>
