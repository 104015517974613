import api from './api'
import store from './store'
import router from './router'

const MONTH_NAMES = ['января','февраля','марта','апреля','мая','июня','июля','августа','сентября','октября','ноября','декабря']
const WEEK_DAYS = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']

let currentTime = Math.floor(new Date().getTime() / 1000)
let deltaSecs = 0

const timeSync = () => {
  if (--deltaSecs <= 0) {
    deltaSecs = 600
    api.getUtcTime().then(time => currentTime = time, () => ++currentTime)
  } else {
    ++currentTime
  }
}
setInterval(timeSync, 1000)
timeSync()

let textTemplates = {}
const syncTextTemplates = () => {
  api.getTextTemplates().then(data => textTemplates = data)
}
setInterval(syncTextTemplates, 3600 * 1000)
syncTextTemplates()

export default {
  getUnixTime() {
    return currentTime
  },
  getDate() {
    return new Date(currentTime * 1000)
  },
  getDay(d) {
    return d.getDate()
  },
  getMonth(d) {
    return MONTH_NAMES[d.getMonth()]
  },
  getYear(d) {
    return d.getFullYear()
  },
  getTime(date, withSeconds) {
    const d = date || this.getDate()
    const h = (d.getHours()<10 ? '0' : '') + d.getHours()
    const m = (d.getMinutes()<10 ? '0' : '') + d.getMinutes()
    const s = withSeconds ? (d.getSeconds()<10 ? ':0' : ':') + d.getSeconds() : ''
    return h + ':' + m + s
  },
  getAbsoluteTime(d) {
    let h = d.getUTCHours() + ((d.getUTCDate() - 1) * 24)
    if (h < 10) {
      h = '0' + h
    }
    const m = (d.getUTCMinutes()<10 ? '0' : '') + d.getUTCMinutes()
    const s = (d.getUTCSeconds()<10 ? '0' : '') + d.getUTCSeconds()
    return h + ':' + m + ':'+s
  },
  getWeekDay(d) {
    return WEEK_DAYS[d.getDay()]
  },
  getDayRange(offset) {
    const range = {}
    const d = this.getDate()
    d.setHours(0, 0, 0, 0)
    range.start = (d.getTime() / 1000) + offset * 86400
    d.setHours(23, 59, 59, 0)
    range.end = (d.getTime() / 1000) + offset * 86400
    return range
  },
  getDayOffset(tsFrom, tsTo) {
    const d1 = this.dateFromUnixTime(tsFrom)
    const d2 = this.dateFromUnixTime(tsTo)
    d1.setHours(0,0,0,0)
    d2.setHours(0,0,0,0)
    return parseInt((d1 - d2) / 86400000)
  },
  getDayStart(ts) {
    const d = this.dateFromUnixTime(ts)
    d.setHours(0, 0, 0, 0)
    return Math.floor(d.getTime() / 1000)
  },
  dateFromUnixTime(time) {
    return new Date(time * 1000)
  },
  getProgress(current, start, end) {
    const total = end - start
    const delta = current - start
    return (delta/total*100) + '%'
  },
  plural(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  },
  dispatchEvent(name, data) {
    const e = document.createEvent('Events');
    e.initEvent(name, true, true);
    if (data) {
      Object.assign(e, data)
    }
    document.dispatchEvent(e);
  },
  returnToPlayer() {
    if (store.state.channelId > 0) {
      router.replace({name: 'player', params: {id: store.state.channelId}})
    }
  },
  hasVolumeControl() {
    return store.state.deviceInfo.model == 'NV501WAC' || store.state.deviceInfo.deviceType == 'browser'
  },
  textMessage(alias, replacements = {}) {
    if (!textTemplates[alias]) {
      return null
    }
    let msg = textTemplates[alias].text
    Object.keys(replacements).forEach(k => msg = msg.replace('%'+k+'%', replacements[k]))
    return msg
  },
  isWinkMigration() {
    return !!store.state.accountInfo.wink_migration
  },
  is501() {
    return (store.state.deviceInfo.model || '').toUpperCase() !== 'NV711WAC'
  },
  winkMigrationMsgMap() {
    const is501 = this.is501()
    return {
      'offer': is501 ? '501_MSG_OFFER_COMMON' : '711_MSG_OFFER_COMMON',
      'wait_activation': is501 ? '501_TEXT_OFFER_COMMON_WAITING' : '711_TEXT_OFFER_COMMON_WAITING',
      'offer_test': is501 ? '501_MSG_OFFER_TEST' : '711_MSG_OFFER_TEST',
      'wait_test_activation': is501 ? '501_MSG_OFFER_TEST_WAITING' : '711_MSG_OFFER_TEST_WAITING',
      'test_period_expired': is501 ? '501_CONFIRM_SWITCH_TO_WINK' : '711_CONFIRM_SWITCH_TO_WINK',
      'wink_accepted': is501 ? '501_TEXT_FINAL' : '',
      'eol': 'NO_SUPPORTED',
    }
  },
  winkMigrationMessage() {
    const msgMap = this.winkMigrationMsgMap()
    const winkMig = store.state.accountInfo.wink_migration || {}
    const msgCode = msgMap[winkMig.status]
    return this.textMessage(msgCode, {WINK_OTT_CODE: winkMig.ott_code})
  },
  winkMigrationMessageFreq() {
    const msgMap = this.winkMigrationMsgMap()
    const winkMig = store.state.accountInfo.wink_migration || {}
    const msgCode = msgMap[winkMig.status]
    const tmpl = textTemplates[msgCode] || {}
    return tmpl.freq
  }
}
