<template>
  <ul :class="{active}" @mouseover="$emit('mouseover', $event)">
    <li v-for="(item, index) in pagedItems" :key="item.id" :class="{current: itemIndex == index}" @mouseover="itemIndex = index" @click="btnEnter">
      <slot :item="item"/>
    </li>
    <controls-event-bus v-if="active"
      @up="btnUp"
      @down="btnDown"
      @enter="btnEnter"
    />
  </ul>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

export default {
  props: {
    active: Boolean,
    items: Array,
    perPage: Number,
    value: Number,
    withBounds: Boolean,
  },
  data() {
    return {
      page: 0,
      itemIndex: 0,
    }
  },
  watch: {
    itemIndex() {
      this.itemChanged()
    },
    page() {
      this.itemChanged()
    },
    value() {
      this.page = Math.floor(this.value / this.perPage)
      this.itemIndex = this.value - this.page * this.perPage
    }
  },
  methods: {
    itemChanged() {
      const index = this.page * this.perPage + this.itemIndex
      this.$emit('input', index)
    },
    btnUp() {
      if (--this.itemIndex < 0) {
        if (this.withBounds && this.page == 0) {
          this.itemIndex = 0
          this.$emit('bound-top')
        } else {
          this.prevPage()
          this.itemIndex = this.pagedItems.length - 1
        }
      }
    },
    btnDown() {
      if (++this.itemIndex >= this.pagedItems.length) {
        if (this.withBounds && this.page == this.lastPage) {
          this.itemIndex = this.pagedItems.length - 1
          this.$emit('bound-bottom')
        } else {
          this.nextPage()
          this.itemIndex = 0
        }
      }
    },
    btnEnter() {
      if (this.active) {
        this.$emit('item-selected', this.page * this.perPage + this.itemIndex)
      }
    },
    prevPage() {
      if (--this.page < 0) {
        this.page = this.lastPage
      }
      if (this.itemIndex >= this.pagedItems.length) {
        this.itemIndex = this.pagedItems.length - 1
      }
    },
    nextPage() {
      if (++this.page > this.lastPage) {
        this.page = 0
      }
      if (this.itemIndex >= this.pagedItems.length) {
        this.itemIndex = this.pagedItems.length - 1
      }
    },
    reset() {
      this.page = 0
      this.itemIndex = 0
    }
  },
  mounted() {
    if (this.value > 0) {
      this.page = Math.floor(this.value / this.perPage)
      this.itemIndex = this.value - this.page * this.perPage
    }
  },
  computed: {
    pagedItems() {
      const start = this.page * this.perPage
      return this.items.slice(start, start + this.perPage)
    },
    lastPage() {
      return Math.ceil(this.items.length / this.perPage) - 1
    }
  },
  components: {
    ControlsEventBus
  }
}
</script>
