<template>
  <div class="content-series">
    <div v-for="(season, sIndex) in seasons" :key="season.title" :class="{hidden: sIndex < seasonIndex}">
      <div class="season-title">
        {{ season.title }}
        &nbsp;
        <span v-if="active && sIndex == seasonIndex">{{ currentEpisode.episode }}</span>
      </div>
      <ul class="series">
        <li v-for="(ep, eIndex) in season.series" :key="ep.content_id"
          :class="{
            active: active && sIndex == seasonIndex && eIndex == episodeIndex,
            hidden: sIndex == seasonIndex && eIndex < lineIndex,
            locked: isLocked(ep),
          }"
        >
          <div>{{ ep.episode_num ? `Серия ${ep.episode_num}` : ep.episode }}</div>
        </li>
      </ul>
    </div>
    <controls-event-bus v-if="active"
      @enter="btnEnter"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

const LINE_ITEMS = 6

export default {
  props: ['contentId', 'series', 'active'],
  data() {
    return {
      seasonIndex: 0,
      episodeIndex: 0,
    }
  },
  methods: {
    btnEnter() {
      if (this.currentEpisode.play.length > 1) {
        this.$emit('select-variant', this.currentEpisode)
      } else {
        this.$emit('content-selected', Object.assign({},
          this.currentEpisode.play[0],
          {content_id: this.currentEpisode.content_id}
        ))
      }
    },
    btnUp() {
      if (this.episodeIndex - LINE_ITEMS >= 0) {
        this.episodeIndex -= LINE_ITEMS
      } else {
        if (--this.seasonIndex < 0) {
          this.$emit('bound-up')
          this.seasonIndex = 0
        } else {
          const lastLineIndex = Math.floor(this.currentSeason.series.length / LINE_ITEMS) * LINE_ITEMS
          this.episodeIndex += lastLineIndex
          if (this.episodeIndex > this.currentSeason.series.length - 1) {
            this.episodeIndex = this.currentSeason.series.length - 1
          }
        }
      }
    },
    btnDown() {
      if (this.lineIndex + LINE_ITEMS < this.currentSeason.series.length) {
        this.episodeIndex += LINE_ITEMS
        if (this.episodeIndex > this.currentSeason.series.length - 1) {
          this.episodeIndex = this.currentSeason.series.length - 1
        }
      } else {
        const lineIndex = this.episodeIndex % LINE_ITEMS
        if (++this.seasonIndex > this.seasons.length - 1) {
          this.$emit('bound-down')
          this.seasonIndex = this.seasons.length - 1
        } else {
          this.episodeIndex = lineIndex
          if (this.episodeIndex > this.currentSeason.series.length - 1) {
            this.episodeIndex = this.currentSeason.series.length - 1
          }
        }
      }
    },
    btnLeft() {
      if (--this.episodeIndex < 0) {
        this.episodeIndex = 0
      }
    },
    btnRight() {
      if (++this.episodeIndex > this.currentSeason.series.length - 1) {
        this.episodeIndex = this.currentSeason.series.length - 1
      }
    },
    isLocked(ep) {
      return ep.play.every(play => !play.is_avail);
    },
  },
  computed: {
    seasons() {
      const result = []
      const seasons = {}
      this.series.forEach(ep => {
        const seasonName = ep.season || ''
        if (!seasons[seasonName]) {
          seasons[seasonName] = []
        }
        seasons[seasonName].push(ep)
      })
      Object.keys(seasons).forEach(season => {
        result.push({
          title: season,
          series: seasons[season]
        })
      })
      return result
    },
    currentSeason() {
      return this.seasons[this.seasonIndex] || {series: []}
    },
    currentEpisode() {
      return this.currentSeason.series[this.episodeIndex] || {play: [{}]}
    },
    lineIndex() {
      return Math.floor(this.episodeIndex / LINE_ITEMS) * LINE_ITEMS
    },
  },
  components: {
    ControlsEventBus,
  }
}
</script>

<style lang="scss" scoped>
  .season-title {
    font-size: 18px;
    color: #aaa;
    margin-bottom: 10px;
    > span {
      color: $color-primary;
      font-style: italic;
    }
  }
  ul.series li {
    display: inline-block;
    color: #aaa;
    font-size: 16px;
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
    background: url(../../assets/img/play-circle-outline-gray.svg) no-repeat;
    background-color: #22222280;
    border: 1px solid #222;
    background-position: 10px 5px;
    padding-left: 40px;
    width: 128px;
    box-sizing: border-box;
    > div {
      white-space: nowrap;
      overflow: hidden;
      mask-image: linear-gradient(to left, transparent 0, black 16px);
    }
    &.active {
      background-image: url(../../assets/img/play-circle-outline.svg);
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: #000;
      &.locked {
        background-image: url(../../assets/img/lock-black.svg);
      }
    }
    &.locked {
      background-image: url(../../assets/img/lock-gray.svg);
      background-size: 20px;
      background-position: 12px 7px;
    }
  }
  .hidden {
    display: none !important;
  }
</style>
