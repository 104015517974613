<template>
  <div class="block-container" :class="{active}" @mouseover="$emit('mouseover', $event)">
    <div class="block-title">
      {{ block.title }}
      &nbsp;
    </div>
    <ul class="catalog-block">
      <li v-for="(program, index) in programs" :key="program.id"
        v-if="program.name"
        :class="{active: index == activeIndex}"
        @mouseover="activeIndex = index"
        @click="activeIndex = index; btnEnter()"
      >
        <div class="pg-image"
          :class="{'no-poster': !program.poster_url}"
          :style="{backgroundImage: `url(${program.poster_url ? program.poster_url : program.image_url})`}"
        >
          <div class="pg-rating" v-if="active && index == activeIndex && program.rating && program.rating.kinopoisk">
            <img src="@/assets/img/star-black.svg"/>
            <div>
              {{ Number(program.rating.kinopoisk).toFixed(1) }}
            </div>
          </div>
        </div>
        <div class="pg-details">
          <div class="pg-name">
            {{ program.name }}
          </div>
          <div class="pg-info">
            <span v-if="program.episode">
              <span v-if="program.season">{{ program.season }}, </span>
              {{ program.episode }}
            </span>
            <span v-else>
              <span v-if="program.year">{{ program.year }}, </span>
              {{ program.genre }}
            </span>
          </div>
        </div>
      </li>
    </ul>
    <img class="lazy-loading" v-if="!isLoaded" src="@/assets/img/spinner-mini.svg" />
    <controls-event-bus v-if="active"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ProgramDetails from '@/components/ProgramDetails.vue'
import api from '@/api'

const LINE_ITEMS_COUNT = 6

export default {
  props: ['active', 'block', 'categoryName'],

  data() {
    return {
      offset: 0,
      activeIndex: 0,
    }
  },
  watch: {
    block() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    offset() {
      if (!this.isLoaded && this.offset > this.block.programs.length - LINE_ITEMS_COUNT*2 - 1) {
        api.getCatalogBlocks(
          this.categoryName,
          this.block.programs.length
        ).then(categories => {
          if (!categories.length || !categories[0].blocks.length || !categories[0].blocks[0].programs.length) {
            this.$set(this.block, 'loaded', true)
            return
          }
          const loadedContentsId = this.block.programs.map(prog => prog.content_id);
          const newPrograms = categories[0].blocks[0].programs.filter(prog => !loadedContentsId.includes(prog.content_id))
          if (newPrograms.length) {
            this.block.programs.push(...newPrograms)
          } else {
            this.$set(this.block, 'loaded', true)
          }
        })
      }
    },
  },
  mounted() {
    if (this.block.offset) {
      this.offset = this.block.offset
    }
    if (this.block.activeIndex) {
      this.activeIndex = this.block.activeIndex
    }
  },
  beforeDestroy() {
    this.block.offset = this.offset
    this.block.activeIndex = this.activeIndex
  },
  methods: {
    btnUp() {
      if (this.isGrid) {
        this.offset -= LINE_ITEMS_COUNT
        if (this.offset < 0) {
          this.offset = 0
          this.$emit('bound-up')
        }
      } else {
        this.$emit('bound-up')
      }
    },
    btnDown() {
      if (this.isGrid) {
        this.offset += LINE_ITEMS_COUNT
        if (this.offset > this.block.programs.length - 1) {
          this.offset -= LINE_ITEMS_COUNT
          this.$emit('bound-down')
        } else if (this.activeIndex > this.programs.length - 1) {
          this.activeIndex = this.programs.length - 1
        }
      } else {
        this.$emit('bound-down')
      }
    },
    btnLeft() {
      --this.activeIndex
      if (this.isGrid) {
        if (this.activeIndex < 0) {
          this.offset -= LINE_ITEMS_COUNT
          if (this.offset < 0) {
            this.offset = 0
            this.activeIndex = 0
          } else {
            this.activeIndex = LINE_ITEMS_COUNT - 1
          }
        }
      } else if (this.activeIndex < 1) {
        if (--this.offset < 0) {
          this.offset = 0
          this.activeIndex = 0
        } else {
          this.activeIndex = 1
        }
      }
    },
    btnRight() {
      ++this.activeIndex
      if (this.isGrid) {
        if (this.activeIndex > this.programs.length - 1) {
          this.activeIndex = this.programs.length - 1
        } else if (this.activeIndex > LINE_ITEMS_COUNT - 1) {
          this.offset += LINE_ITEMS_COUNT
          if (this.offset > this.block.programs.length - 1) {
            this.offset -= LINE_ITEMS_COUNT
          } else {
            this.activeIndex = 0
          }
        }
      } else if (this.activeIndex > LINE_ITEMS_COUNT - 2) {
        if (++this.offset > this.block.programs.length - LINE_ITEMS_COUNT) {
          this.offset = this.block.programs.length - LINE_ITEMS_COUNT
          this.activeIndex = LINE_ITEMS_COUNT - 1
        } else {
          this.activeIndex = LINE_ITEMS_COUNT - 2
        }
      }
    },
    btnEnter() {
      const program = this.block.programs[this.offset + this.activeIndex]
      if (program) {
        api.appLog(
          'content-enter',
          this.categoryName+'|'+(this.block.title ? this.block.title+'|' : '')+program.name,
          program.content_id
        )
        this.$emit('content-selected', program)
      }
    },
  },
  computed: {
    programs() {
      if (this.isGrid) {
        return this.block.programs.slice(this.offset, this.offset + LINE_ITEMS_COUNT*3)
      } else {
        return this.block.programs.slice(this.offset, this.offset + LINE_ITEMS_COUNT)
      }
    },
    isGrid() {
      return this.block.layout == 'grid'
    },
    isLoaded() {
      return !this.isGrid || this.block.programs.length < LINE_ITEMS_COUNT*2 || this.block.loaded
    },
  },
  components: {
    ControlsEventBus,
    ProgramDetails,
  }
}
</script>

<style lang="scss" scoped>
  .block-container {
    margin-bottom: 30px;
    &.active li.active {
      transform: scale(1.04);

      .pg-details {
        background-color: #fff;
      }
      .pg-name {
        color: #212121;
      }
      .pg-info {
        color: #444;
      }
    }
    .block-title {
      margin-left: 7px;
      margin-bottom: 10px;
      font-size: 22px;
      color: #fff;
    }
  }
  .catalog-block {
    li {
      width: 185px;
      display: inline-block;
      margin: 7px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  .pg-image {
    height: 276px;
    background-size: 100% 100%;
    position: relative;
    background-color: #22222280;

    &.no-poster {
      background-size: cover;
      background-position: center;
    }
  }
  .pg-rating {
    background-color: $color-primary;
    color: #212121;
    position: absolute;
    top: -2px;
    right: 10px;
    width: 34px;
    height: 46px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    box-shadow: 2px 2px 5px #333;
  }
  .pg-details {
    height: 70px;
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #181818CC;
    .pg-name {
      color: #ccc;
      font-size: 17px;
      font-weight: 700;
      white-space: normal;
      margin-bottom: 5px;
      max-height: 34px;
      overflow: hidden;
    }
    .pg-info {
      color: #999;
      font-size: 14px;
      white-space: nowrap;
      mask-image: linear-gradient(to left, transparent 10px, black 30px);
    }
  }
  .lazy-loading {
    margin: 0 auto;
    display: block;
    margin-top: 50px;
  }
</style>
