<template>
  <div class="progress-loader" :class="[type, {'with-text': text}]" v-if="type">
    {{text}}
  </div>
</template>

<script>
let pbTimeout, pbShowTimeout

export default {
  data() {
    return {
      type: null,
      text: null,
    }
  },
  beforeDestroy() {
    this.hide()
  },
  methods: {
    show(type, text, timeout) {
      clearTimeout(pbTimeout)
      clearTimeout(pbShowTimeout)
      pbShowTimeout = setTimeout(() => {
        this.type = type
        this.text = text
      }, 200)
      if (timeout) {
        pbTimeout = setTimeout(() => this.hide(), timeout)
      }
    },
    hide() {
      clearTimeout(pbShowTimeout)
      clearTimeout(pbTimeout)
      this.type = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .progress-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 5px;
    background: 20px center no-repeat;
    color: white;
    font-size: 22px;
    line-height: 26px;
    padding: 26px;
    padding-left: 90px;
    &.with-text {
      background-color: #353535;
    }
    &.loader {
      background-image: url(../assets/img/spinner-mini.svg)
    }
    &.success {
      background-image: url(../assets/img/icon-operation-success.png)
    }
    &.error {
      background-image: url(../assets/img/icon-operation-error.png)
    }
  }
</style>
