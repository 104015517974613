<template>
  <div id="app" :class="[modelClass, {playing, 'no-animation': !$store.state.settings.useAnimation}]" v-if="$auth.ready()">
    <div id="debug-panel" v-if="$store.state.debug">
      <div v-for="(message, k) in $store.state.log" :key="k">{{message}}</div>
    </div>
    <modal ref="blockError" :no-close="true">
      <span v-html="blockText"/>
    </modal>
    <modal ref="ottEol" :no-close="true">
      Уважаемый клиент, доводим до вашего сведения, что 2 апреля 2024г. интерактивное телевидение на платформе «Смотри везде» прекратило свою работу.
      <br/><br/>
      Чтобы продолжить пользоваться услугой «Интерактивное ТВ» на вашем устройстве обратитесь в техническую поддержку «АльянсТелеком» по номеру телефона<br/>
      8 (423) 205-01-01
    </modal>
    <modal ref="winkMig" :need-confirm="migConfirmWithRunWink" confirm-text="Запустить Wink" close-text="Закрыть" @confirm="runWinkApp">
      {{ winkMigMsg }}
      <div style="margin-top: 20px; display: flex; align-items: center; justify-content: space-between;" v-if="isWinkWaitActivation">
        <div style="margin-right: 100px">
          Рекомендуем открыть руководство по настройке<br/>
          Wink услуги <div style="text-decoration: underline;display: inline;color: rgb(253, 184, 19);">inetvl.ru/wi</div><br/>
          или отсканируйте QR код →
        </div>
        <div><img src="@/assets/img/qr-code-wink.png" width="150" /></div>
      </div>
    </modal>
    <WinkMsgConfirm ref="winkMsgConfirm" @confirmed="$store.dispatch('updateAccountInfo').then(() => winkMigCheck())">
      {{ winkMigMsg }}
    </WinkMsgConfirm>
    <WinkMsgTestPeriod ref="winkMsgTestPeriod" />
    <modal ref="loadError" @close="reloadApp">
      Ошибка подключения к сервису
      <br/><br/>
      Проверьте подключение устройства к сети, нажмите кнопку Home на пульте и попробуйте загрузить приложение еще раз.
    </modal>
    <change-log v-if="changeLogVisible" @close="changeLogVisible = false"/>
    <div class="first-loading" v-if="!$store.state.loaded && spinnerVisible">
      <div>Загрузка…</div>
    </div>
    <template v-if="$store.state.loaded">
      <header v-if="$auth.check()">
        <div id="top-info">
          <div class="clock">{{day}} <span>{{month}}</span> {{time}}</div>
          <img src="@/assets/img/logo-300x100.svg"/>
          <span class="env-sym">{{$store.state.envSym}}</span>
        </div>
      </header>
      <modal ref="updateConfirm" @confirm="reloadApp" :need-confirm="true">
        Вышла новая версия приложения
        <br/><br/>
        Желаете обновиться ?
      </modal>
      <router-view/>
      <volume-control v-if="canChangeVolume"/>
      <div class="invisible" v-for="sprite in sprites" :key="sprite.size">
        <link rel="stylesheet" type="text/css" :href="sprite.url"/>
        <i :class="`chi-${sprite.size}`"/>
      </div>
      <controls-event-bus
        @exit="btnExit"
        @menu="btnMenu"
        @power="btnPower"
        @record="btnRecord"
      />
    </template>
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import VolumeControl from '@/components/VolumeControl.vue'
import ChangeLog from '@/components/ChangeLog.vue'
import Modal from '@/components/Modal.vue'
import WinkMsgConfirm from '@/components/WinkMsgConfirm.vue'
import WinkMsgTestPeriod from '@/components/WinkMsgTestPeriod.vue'
import utils from '@/utils'
import device from '@/device'
import { mapState } from 'vuex'
import WS from '@/WS'

export default {
  data() {
    const now = utils.getDate()
    return {
      day: utils.getDay(now),
      month: utils.getMonth(now),
      time: utils.getTime(now),
      sprites: [],
      spinnerVisible: false,
      canChangeVolume: false,
      changeLogVisible: false,
      blockText: null,
      winkMigMsg: null,
      stopLoading: false,
      prevMig: {shown: 0},
    }
  },
  mounted() {
    setTimeout(() => this.spinnerVisible = true, 500)
    setInterval(() => {
      const now = utils.getDate()
      this.day = utils.getDay(now)
      this.month = utils.getMonth(now)
      this.time = utils.getTime(now)
    }, 1000)
    setInterval(() => this.$api.getVersionInfo().then(info => {
      if (info.version != APP_VERSION) {
        if (info.critical) {
          this.reloadApp()
        } else {
          this.$refs.updateConfirm.show()
        }
      }
    }), 3600000)

    this.$store.dispatch('loadSettings').then(() => {
      const checkBlock = () => {
        return this.$appAuth.checkBlock().then(() => {
          //this.winkMigCheck()
        }).catch(err => {
          if (err.name == 'AuthError') {
            this.blockText = err.message
            //this.$refs.blockError.show()
          }
        })
      }
      setInterval(checkBlock, 600000)
      return checkBlock()
    }).then(() => {
      if (!utils.is501()) {
        const mig = this.accountInfo.wink_migration || {}
        /*if (mig.status === 'test_period') {
          this.$refs.winkMsgTestPeriod.show()
        }*/
        const winkStatuses = ['wink_accepted', 'wink_moved']
        if (winkStatuses.includes(mig.status)) {
          this.stopLoading = true
          return device.runWinkApp()
        }
      }
      this.stopLoading = true
      this.$refs.ottEol.show()
      if (!this.stopLoading && this.$auth.check() && this.accountInfo.active) {
        return Promise.all([
          this.$store.dispatch('updateCategories'),
          this.$store.dispatch('updatePrograms'),
          this.$store.dispatch('updatePackets'),
        ]).then(() => {
          const channelId = this.$store.state.channelId
          if (channelId > 0) {
            const channel = this.$store.getters.channelsMap[channelId]
            if (channel && channel.is_avail) {
              const archiveTime = this.$store.state.settings.archiveTime
              if (archiveTime) {
                const rewindTime = archiveTime - utils.getUnixTime()
                this.$store.commit('rewindTime', rewindTime)
                return channel.archive_url + archiveTime
              }
              return channel.url
            }
          }
        }).then(url => {
          if (url) {
            this.$store.dispatch('play', {url})
          }
        }).then(() => {
          WS.listen(this.accountInfo.abonement, this.deviceInfo.mac)
        })
      }
    }).then(() => {
      if (utils.hasVolumeControl()) {
        device.setVolume(this.$store.state.settings.volume)
        this.canChangeVolume = true
      }
      if (this.stopLoading) {
        return
      }
      this.$api.accountSetDeviceInfo(this.deviceInfo)
      this.$store.commit('loaded')
      this.$store.commit('setSetting', {name: 'archiveTime', value: 0})
      /*if (this.$auth.check() && this.$store.state.settings.lastAppVersion != APP_VERSION) {
        this.$nextTick(() => this.changeLogVisible = true)
        this.$store.commit('setSetting', {name: 'lastAppVersion', value: APP_VERSION})
      }*/
    }).catch(e => {
      if (e.response && e.response.status == 403) {
        return
      }
      this.$refs.loadError.show()
    }).then(() => {
      if (this.stopLoading) {
        return
      }

      this.updateSprites()
      setInterval(() => {
        if (this.$auth.check()) {
          this.$store.dispatch('updatePrograms', true)
        }
      }, 3600000)
      setInterval(() => this.updateSprites(), 3605000)

      setInterval(() => {
        if (this.$auth.check()) {
          this.$store.dispatch('updatePrograms')
        }
      }, 60000)

      const updateRecommended = () => {
        if (this.$auth.check()) {
          this.$store.dispatch('updateRecommended')
        }
      }
      updateRecommended()
      setInterval(updateRecommended, 600000)
    })

    document.addEventListener('keydown', e => this.$store.commit('log', `keyCode = ${e.keyCode}, key = ${e.key}`))
  },
  methods: {
    reloadApp() {
      if (this.$store.state.player.rewindTime < 0) {
        this.$store.commit('setSetting', {name: 'archiveTime', value: utils.getUnixTime() + this.$store.state.player.rewindTime})
      }
      this.$router.replace({name: 'home'})
      this.$nextTick(() => location.reload())
    },
    updateSprites() {
      this.$api.getChannelIconSprites()
        .then(sprites => this.sprites = sprites)
    },
    btnExit() {
      utils.returnToPlayer()
    },
    btnMenu() {
      this.$router.replace({name: 'home'})
    },
    btnPower() {
      this.$store.dispatch('setLastChannelId', {
        id: this.$store.state.settings.lastChannelId,
        save: false,
      })
      this.$store.commit('skipParentCode', false)
      this.$store.dispatch('stop')
      this.btnMenu()
    },
    btnRecord() {
      if (APP_ENV == 'dev') {
        this.$store.commit('toggleDebug')
      }
    },
    winkMigCheck() {
      if (!utils.isWinkMigration()) {
        return
      }
      const mig = this.accountInfo.wink_migration || {}
      if (mig.status === 'eol') {
        this.stopLoading = true
        this.blockText = utils.winkMigrationMessage()
        this.$refs.blockError.show()
        return
      }
      if (this.prevMig.status != mig.status) {
        this.showMigMsg()
      } else {
        const msgFreq = utils.winkMigrationMessageFreq()
        if (msgFreq && (Date.now() - this.prevMig.shown) / 3600000 > msgFreq) {
          this.showMigMsg()
        }
      }
      this.prevMig.status = mig.status
    },
    showMigMsg(ignoreDayStatus) {
      const mig = this.accountInfo.wink_migration || {}
      const migMsg = utils.winkMigrationMessage()
      if (!migMsg) {
        return
      }
      const dayStatuses = ['offer', 'offer_test']
      if (!ignoreDayStatus && dayStatuses.includes(mig.status)) {
        const currentHour = utils.getDate().getHours()
        if (currentHour < 9 || currentHour > 20) {
          return
        }
      }
      this.winkMigMsg = migMsg
      mig.confirm ? this.$refs.winkMsgConfirm.show() : this.$refs.winkMig.show()
      this.$api.winkMigMessageShown()
      this.prevMig.shown = Date.now()
    },
    runWinkApp() {
      device.runWinkApp()
    },
  },
  watch: {
    $route(newRoute) {
      const winkMsgRoutes = ['settings', 'chpackets']
      if (winkMsgRoutes.includes(newRoute.name)) {
        this.showMigMsg(true)
      }
    }
  },
  computed: {
    ...mapState(['playing', 'deviceInfo', 'accountInfo']),
    modelClass() {
      let model = this.deviceInfo.model
      if (model) {
        model = model.replace(/[. ]/g, '').toLowerCase()
      }
      return 'model-' + model
    },
    isWinkWaitActivation() {
      const migStatus = (this.accountInfo.wink_migration || {}).status
      return ['wait_activation', 'wait_test_activation'].includes(migStatus)
    },
    migConfirmWithRunWink() {
      return this.isWinkWaitActivation && !utils.is501()
    },
  },
  components: {
    ControlsEventBus,
    VolumeControl,
    ChangeLog,
    Modal,
    WinkMsgConfirm,
    WinkMsgTestPeriod,
  }
}
</script>

<style src="@/assets/scss/app.scss" lang="scss"></style>
<style lang="scss">
  #app {
    background-color: $color-dark;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &.playing {
      background-color: rgba(0,0,0,0.6);
    }
  }
  .first-loading {
    background-image: url(assets/img/spinner.svg);
    background-repeat: no-repeat;
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    padding-top: 135px;
    text-align: center;
    color: white;
    font-size: 20px;
    background-size: 110px;
    background-position: center;
  }
  #debug-panel {
    background-color: #000;
    border: 1px dotted #aaa;
    border-top: 0;
    color: #fff;
    font-size: 14px;
    left: 50px;
    line-height: 15px;
    opacity: 0.7;
    padding: 5px;
    position: absolute;
    right: 50px;
    top: 0;
    z-index: 1100;
  }
  header {
    height: 140px;
  }
  #top-info {
    position: absolute;
    right: 40px;
    top: 30px;
    img {
      height: 80px;
      vertical-align: middle;
      margin-left: 85px;
    }
    .clock {
      display: inline-block;
      color: $color-primary;
      font-size: 26px;
      font-weight: 500;
      span {
        font-size: 22px;
      }
    }
  }
  .env-sym {
    position: absolute;
    color: #fff;
    font-size: 30px;
  }
</style>
