<template>
  <div class="login-view">
    <modal ref="loginError">
      <span v-html="errorMessage"/>
    </modal>
    <virtual-keyboard v-model="currentInput" :digital="digitalKb" @input="kbInput" @close="kbVisible = false" v-if="kbVisible"/>
    <div id="login-container">
      <img class="logo" src="@/assets/img/logo-300x100.svg"/>
      <span class="env-sym">{{$store.state.envSym}}</span>
      <div class="title">Введите ваши данные для входа в <br/>сервис «Смотри Везде»</div>
      <form class="login-form">
        <input v-model="username" placeholder="№ лицевого счета" type="text" :class="{active: focusIndex == 0}" @click="focusIndex = 0; btnRight()" @focus="blurInput"/>
        <input v-model="password" placeholder="пароль" type="password" :class="{active: focusIndex == 1}" @click="focusIndex = 1; btnRight()" @focus="blurInput"/>
        <button type="button" class="label-login" @click.prevent="doLogin" :class="{active: focusIndex == 2}">ВОЙТИ</button>
      </form>
    </div>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="enter" @click="btnEnter"/>
        <span>Далее</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="left" @click="btnLeft"/>
        <span>Стереть</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="right" @click="btnRight"/>
        <span>Клавиатура</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Закрыть</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus v-if="!kbVisible"
      @digit="btnDigit"
      @enter="btnEnter"
      @left="btnLeft"
      @right="btnRight"
      @up="btnUp"
      @down="btnDown"
      @back="btnBack"
    />
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import device from '@/device'

const FORM_FIELDS = ['username', 'password']

export default {
  data() {
    return {
      username: '',
      password: '',
      focusIndex: 0,
      kbVisible: false,
      errorMessage: null,
    }
  },
  computed: {
    currentInput: {
      get() {
        return this[FORM_FIELDS[this.focusIndex]]
      },
      set(value) {
        this[FORM_FIELDS[this.focusIndex]] = value
      },
    },
    digitalKb() {
      return this.focusIndex == 0
    },
  },
  mounted() {
    if (this.$store.state.settings.abonement && this.$store.state.settings.password) {
      this.username = this.$store.state.settings.abonement
      this.password = this.$store.state.settings.password
      this.doLogin()
    }
  },
  methods: {
    doLogin() {
      this.$appAuth.doLogin(this.username, this.password, this.$store.state.deviceInfo.mac).catch(err => {
        this.errorMessage = err.message
        this.$refs.loginError.show()
      })
    },
    blurInput(e) {
      e.target.blur()
    },
    kbInput() {
      this.kbVisible = false
    },
    btnDigit(e) {
      this.currentInput += e.digit
    },
    btnEnter() {
      if (this.focusIndex < 2 && !this.currentInput.length) {
        return this.btnRight()
      }
      if (this.focusIndex > 0) {
        this.doLogin()
      } else {
        ++this.focusIndex
      }
    },
    btnUp() {
      if (--this.focusIndex < 0) {
        this.focusIndex = 0
      }
    },
    btnDown() {
      if (++this.focusIndex > 2) {
        this.focusIndex = 2
      }
    },
    btnLeft() {
      this.currentInput = this.currentInput.slice(0, -1)
    },
    btnRight() {
      if (FORM_FIELDS[this.focusIndex]) {
        this.kbVisible = true
      }
    },
    btnBack() {
      device.exitApp()
    },
  },
  components: {
    Modal,
    VirtualKeyboard,
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn
  }
}
</script>

<style lang="scss" scoped>
  #login-container {
    width: 322px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
  }
  .title {
    font-size: 15px;
    margin: 22px 0 57px 0;
    line-height: 20px;
  }
  input, button {
    box-shadow: inset 0 0 0 1px #808080;
    background-color: #D8D8D8;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 500;
    font-size: 24px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 50px;
    &.active {
      box-shadow: inset 0 0 5px 2px #FD970D;
      background-color: #FDE9B7;
    }
  }
  button {
    background-color: $color-primary;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    &.active {
      box-shadow: inset 0 0 5px 2px #FD680D;
      background-color: #FDCE58;
    }
  }
</style>
