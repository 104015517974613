<template>
  <div class="tv-channels-view">
    <div class="header-title">
      Телеканалы
      <div class="category">Все</div>
    </div>
    <paged-list class="channel-list" ref="chList"
      :active="true"
      :per-page="10"
      :items="channels"
      v-model="channelIndex"
      @item-selected="play"
    >
      <template slot-scope="{ item }">
        <div class="ch-num">
          <span v-if="item.is_avail">{{item.num}}</span>
        </div>
        <i class="ch-icon chi-36" :class="[`ch-${item.icon_id}`, {locked: !item.is_avail}]"/>
        <div class="ch-name">
          {{item.name}}
        </div>
        <div class="pg-time" v-if="!details && item.program_begin_time">
          {{item.begin_time}} - {{item.end_time}}
          <div class="progress"><div :style="{width: item.progress}"/></div>
        </div>
        <div class="pg-name" v-if="!details">
          {{item.program_name}}
          <span class="no-program" v-if="!item.program_name">Нет телепрограммы</span>
        </div>
      </template>
    </paged-list>
    <div class="player-mode" v-if="details">
      <div class="ch-info">
        <div class="pg-name">{{currentChannel.program_name}}</div>
        <div class="pg-time" v-if="currentChannel.program_begin_time">
          <span>{{currentChannel.begin_time}}</span>
          <div class="progress"><div :style="{width: currentChannel.progress}"/></div>
          <span>{{currentChannel.end_time}}</span>
        </div>
        <div class="pg-description" v-if="currentChannel.program_description">
          {{currentChannel.program_description}}
        </div>
        <div class="description-shadow"/>
      </div>
    </div>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="enter" @click="btnEnter"/>
        <span>Просмотр</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="info" @click="btnInfo"/>
        <span v-if="details">Кратко</span>
        <span v-else>Подробно</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="red" @click="btnRed"/>
        <span>Программа</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="menu" @click="btnMenu"/>
        <span>Меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus
      @left="btnLeft"
      @right="btnRight"
      @info="btnInfo"
      @red="btnRed"
      @back="btnBack"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import PagedList from '@/components/PagedList.vue'
import utils from '@/utils'
import { mapState } from 'vuex'

let backToMenuTimeout

export default {
  data() {
    return {
      page: 0,
      channelIndex: 0,
      details: false,
      backToMenu: true,
    }
  },
  methods: {
    play() {
      this.$router.replace({name: 'player', params: {id: this.currentChannel.id}})
    },
    btnLeft() {
      this.$refs.chList.prevPage()
    },
    btnRight() {
      this.$refs.chList.nextPage()
    },
    btnEnter() {
      this.play()
    },
    btnInfo() {
      this.details = !this.details
    },
    btnRed() {
      this.$router.replace({name: 'epg', params: {id: this.currentChannel.id}})
    },
    btnMenu() {
      this.$router.replace({name: 'home'})
    },
    btnBack() {
      if (this.$store.state.playing && !this.backToMenu) {
        utils.returnToPlayer()
      } else {
        this.btnMenu()
      }
    },
  },
  created() {
    if (this.channelId) {
      this.channelIndex = this.channels.findIndex(prog => prog.id == this.channelId)
    }
  },
  mounted() {
    backToMenuTimeout = setTimeout(() => {
      this.backToMenu = false
    }, 1000)
  },
  beforeDestroy() {
    clearTimeout(backToMenuTimeout)
  },
  computed: {
    ...mapState(['channelId']),
    channels() {
      return this.$store.getters.filteredChannels
    },
    currentChannel() {
      return this.channels[this.channelIndex]
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    PagedList,
  }
}
</script>

<style lang="scss" scoped>
  .tv-channels-view {
    margin: 0 auto;
    width: 1180px;
  }
  .header-title .category {
    color: #868686;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
    text-transform: none;
  }
  .player-mode {
    width: 705px;
    float: right;
    .ch-info {
      background: #181818;
      color: #fff;
      height: 500px;
      overflow: hidden;
      padding: 10px;
      position: relative;
      box-sizing: border-box;
    }
    .pg-name {
      width: 610px;
      font-size: 20px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pg-time {
      span {
        color: #ccc;
        font-size: 16px;
      }
      .progress {
        background: #686868;
        height: 7px;
        width: 580px;
        margin: 0 10px;
        vertical-align: middle;
        display: inline-block;
        > div {
          background: #FD970D;
          height: 100%;
        }
      }
    }
    .pg-description {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #282828;
      line-height: 24px;
      font-size: 18px;
    }
    .description-shadow {
      box-shadow: #181818 0 -32px 17px -15px inset;
      height: 30px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
</style>
