let deviceType = 'browser'
if (window.AndroidApp) {
  deviceType = 'android'
} else if (window.QtApp) {
  deviceType = 'qt'
}
const appCall = args => import('./devices/'+deviceType).then(exp => exp.default(args))

const DEFAULT_DEVICE_INFO = {
  deviceType,
  mac: 'ba:ad:be:ef:ba:be',
  model: 'Unknown',
  firmware: '0.0.0',
  width: 1280,
  height: 720,
  apiVersion: 1,
}

export default {
  exitApp() {
    appCall({fName: 'exitApp'})
  },
  runYouTubeApp() {
    this.getInfo()
      .then(info => appCall({fName: 'runYouTubeApp', params: [info.model]}))
  },
  runWinkApp() {
    appCall({fName: 'runWinkApp'})
  },
  showSystemSettings(page = 'all') {
    appCall({fName: 'showSystemSettings', params: [page]})
  },
  setSetting(name, value) {
    appCall({fName: 'settingsSet', async: true, params: [name, value]})
  },
  getSetting(name) {
    return appCall({fName: 'settingsGet', needCb: true, params: [name]})
  },
  getInfo() {
    return appCall({fName: 'deviceInfo', needCb: true, async: true})
      .then(info => Object.assign({}, DEFAULT_DEVICE_INFO, info))
  },
  play(streamUrl) {
    appCall({fName: 'videoStop'})
    appCall({fName: 'videoLoadChannel', params: [streamUrl]})
    appCall({fName: 'videoPlay', async: true})
  },
  pause() {
    appCall({fName: 'videoPause'})
  },
  resume() {
    appCall({fName: 'videoPlay'})
  },
  stop() {
    appCall({fName: 'videoStop'})
  },
  seek(timestamp) {
    appCall({fName: "videoSeek", params: [timestamp * 1000]})
  },
  getVolume() {
    return appCall({fName: 'getVolume', needCb: true, async: true}).then(vol => parseInt(vol) || 0)
  },
  setVolume(volume) {
    appCall({fName: 'setVolume', async: true, params: [volume]})
  }
}
