<template>
  <div class="catalog-search">
    <div class="search-container">
      <div class="search-hint" v-if="!query">
        Введите запрос
      </div>
      <div class="nothing-found" v-if="nothingFound">
        Ничего не найдено
      </div>
      <paged-list class="search-result"
        :active="active && resultsActive && !carouselActive"
        :items="results.slice(0, 6)"
        :per-page="6"
        :with-bounds="true"
        v-model="resultIndex"
        @bound-top="$emit('bound-top')"
        @bound-bottom="activateCarousel"
        @mouseover="resultsActive = true"
        @item-selected="listEnter"
        v-slot="{ item }"
      >
        <span>{{ item.name }}</span>
      </paged-list>
      <div class="carousel-container" :class="{active: carouselActive}">
        <ul>
          <li v-for="(program, index) in carousel" :key="program.id" :class="{active: index == carouselIndex + 2}">
            <div class="pg-image" :style="{backgroundImage: `url(${program.image_url})`}" v-if="program.name" />
            <program-details :program="program" />
          </li>
        </ul>
      </div>
      <progress-loader ref="pbInfo"/>
      <virtual-keyboard
        v-model="query"
        lang="ru"
        :no-cursor="true"
        :live-update="true"
        :inactive="!active || resultsActive || carouselActive"
        :with-bounds="true"
        @bound-left="activateResults"
        @bound-top="$emit('bound-top')"
        @bound-bottom="activateCarousel"
      />
    </div>
    <controls-event-bus v-if="!carouselActive" @right="resultsActive = false" />
    <controls-event-bus v-if="carouselActive"
      @up="carouselActive = false"
      @right="carouselRight"
      @left="carouselLeft"
      @enter="carouselEnter"
    />
    <controls-event-bus @back="$emit('close')" />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import PagedList from '@/components/PagedList.vue'
import ProgramDetails from '@/components/ProgramDetails.vue'
import api from '@/api'

let lastResults = []
let lastQuery = ''

export default {
  props: ['active'],

  data() {
    return {
      query: lastQuery,
      results: lastResults,
      resultIndex: 0,
      resultsActive: false,
      carouselIndex: 0,
      carouselOffset: 0,
      carouselActive: false,
      searchPerformed: false,
    }
  },
  watch: {
    query(q, prevQ) {
      if (q.length >= 3 && !(this.nothingFound && q.length - prevQ.length > 0)) {
        this.$refs.pbInfo.show('loader')
        api.catalogSearch(q).then(results => {
          this.searchPerformed = true
          this.results = results
          this.$refs.pbInfo.hide()
        }).catch(() => this.$refs.pbInfo.hide())
      } else if(q.length < 3) {
        this.searchPerformed = false
      }
    }
  },
  mounted() {
    document.querySelector('#app').style.backgroundColor = '#303030'
  },
  beforeDestroy() {
    lastQuery = this.query
    lastResults = this.results
    document.querySelector('#app').style.backgroundColor = ''
  },
  methods: {
    activateResults() {
      if (this.results.length > 0) {
        this.resultsActive = true
      }
    },
    activateCarousel() {
      if (this.results.length > 0) {
        this.carouselActive = true
      }
    },
    carouselRight() {
      if (++this.carouselIndex > 3) {
        this.carouselIndex = 3
        if (++this.carouselOffset > this.results.length - 4) {
          this.carouselOffset = this.results.length - 4
        }
      }
    },
    carouselLeft() {
      if (--this.carouselIndex < 0) {
        this.carouselIndex = 0
        if (--this.carouselOffset < 0) {
          this.carouselOffset = 0
        }
      }
    },
    carouselEnter() {
      const program = this.carousel[this.carouselIndex + 2] || {}
      api.appLog('search-carousel-enter', this.query+'|'+program.name, program.content_id)
      this.$emit('content-selected', program)
    },
    listEnter() {
      api.appLog('search-list-enter', this.query+'|'+this.activeResult.name, this.activeResult.content_id)
      this.$emit('content-selected', this.activeResult)
    },
  },
  computed: {
    activeResult() {
      return this.results[this.resultIndex] || {}
    },
    carousel() {
      if (this.carouselOffset < 2) {
        return [...Array(2 - this.carouselOffset).fill({}), ...this.results]
      }
      return this.results.slice(this.carouselOffset - 2)
    },
    nothingFound() {
      return this.searchPerformed && this.results.length == 0
    },
  },
  components: {
    ControlsEventBus,
    VirtualKeyboard,
    ProgressLoader,
    PagedList,
    ProgramDetails,
  }
}
</script>

<style lang="scss">
  .catalog-search {
    height: 100%;
    .vkb-overlay {
      background: none;
    }
    .search-container {
      position: relative;
      width: 1280px;
      height: 100%;
      margin: 0 auto;
    }

    .keyboard {
      margin-top: -5px;
      margin-right: 20px;
      width: 650px;
      background: none;

      .input {
        position: absolute;
        left: 103px;
        top: -25px;
        width: 1150px;
        overflow: hidden;
        background: none;
        border: none;
        color: #fff;
      }
      td {
        padding: 20px;
        span {
          font-size: 24px;
          padding-top: 12px;
          box-shadow: none;
        }
      }
    }
    .search-hint {
      position: absolute;
      top: -15px;
      left: 114px;
      color: #ccc;
      z-index: 1000;
      font-weight: 400;
      font-size: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .nothing-found {
      position: absolute;
      top: 35px;
      left: 114px;
      color: #999;
      font-size: 24px;
    }

    .progress-loader {
      z-index: 1000;
      left: 200px;
      top: 180px;
    }
    .search-result {
      position: absolute;
      top: 35px;
      left: 114px;
      z-index: 1000;
      color: #999;

      li {
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;

        span {
          display: inline-block;
          border-radius: 5px;
          background-color: #373737;
          padding: 8px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 450px;
        }
      }
      &.active .current span {
        background-color: $color-primary;
        color: $color-dark;
      }
    }
    .carousel-container {
      position: absolute;
      top: 280px;
      .pg-details {
        color: #eee;
      }
    }
  }
</style>
