<template>
  <div class="modal-overlay">
    <div class="changelog-container">
      <div class="changelog-header">
        Список изменений
      </div>
      <div class="changelog-body">
        <div v-for="change in changeLog" :key="change.version">
          <h3>{{change.version}} ({{change.date}})</h3>
          <ul v-html="change.log"/>
        </div>
      </div>
      <div class="changelog-footer">
        <div>
          <div class="btn-close" @click="$emit('close')">
            <img src="@/assets/img/button-back.svg"/>
          </div>
          Закрыть
        </div>
      </div>
    </div>
    <global-events @keydown="keyHandler"/>
  </div>
</template>

<script>
import GlobalEvents from '@/components/GlobalEvents.vue'
import keyMap from '@/keymap'
import compareVersions from 'compare-versions'

export default {
  data() {
    return {
      changeLog: []
    }
  },
  mounted() {
    this.$store.commit('showModal', true)
    this.$api.getChangeLog()
      .then(changeLog => this.changeLog = changeLog.filter(change => {
        // eslint-disable-next-line
        try {return compareVersions(APP_VERSION, change.version) >= 0} catch (e) {}
      }))
  },
  beforeDestroy() {
    this.$store.commit('showModal', false)
  },
  methods: {
    keyHandler(e) {
      switch (keyMap[e.keyCode]) {
        case 'back':
          this.$emit('close')
          break
        case 'up':
          document.querySelector('.changelog-body').scrollTop -= 100
          break
        case 'down':
          document.querySelector('.changelog-body').scrollTop += 100
          break
      }
    },
  },
  components: {
    GlobalEvents
  }
}
</script>

<style lang="scss" scoped>
  .changelog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    background-color: #252525;
    border-radius: 8px;
  }
  .changelog-header {
    color: $color-primary;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: bold;
  }
  .changelog-body {
    overflow: auto;
    padding: 0 30px;
    color: #222;
    background-color: #fff;
    height: 400px;
    font-size: 20px;
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    ul {
      list-style-type: disc;
      line-height: 25px;
      margin-left: 30px;
    }
  }
  .changelog-footer {
    background: $color-dark;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #272727;
    > div {
      border-top: 1px solid #393939;
      line-height: 60px;
    }
  }
  .btn-close {
    width: 50px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #868686;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      background-color: #404040;
    }
  }
</style>
