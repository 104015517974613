<template>
  <div class="modal-overlay" v-if="visible">
    <div class="modal-container">
      <div class="modal-body">
        <slot/>
      </div>
      <div class="modal-footer">
        <div v-if="countdown">
          {{ countdown }}
        </div>
        <div v-else>
          <div class="btn-enter" :class="{active: answer === 'yes'}" @mouseenter="answer = 'yes'" @touchstart="answer = 'yes'" @click="confirmAction">
            Да
          </div>
          <div class="btn-close" :class="{active: answer === 'no'}" @mouseenter="answer = 'no'" @touchstart="answer = 'no'" @click="confirmAction">
            Нет
          </div>
        </div>
      </div>
    </div>
    <global-events @keydown="keyHandler" v-if="!countdown" />
  </div>
</template>

<script>
import GlobalEvents from '@/components/GlobalEvents.vue'
import keyMap from '@/keymap'

let countdownInterval
export default {
  data() {
    return {
      visible: false,
      countdown: 0,
      answer: null,
    }
  },
  methods: {
    keyHandler(e) {
      switch (keyMap[e.keyCode]) {
        case 'left':
          this.answer = 'yes'
          break;
        case 'right':
          this.answer = 'no'
          break;
        case 'enter':
          this.confirmAction()
          break;
      }
    },
    show() {
      this.$store.commit('showModal', true)
      this.countdown = 10
      this.answer = null
      this.visible = true
      clearInterval(countdownInterval)
      countdownInterval = setInterval(() => {
        if (--this.countdown <= 0) {
          clearInterval(countdownInterval)
        }
      }, 1000)
    },
    hide() {
      this.visible = false
      this.$store.commit('showModal', false)
    },
    confirmAction() {
      if (!this.answer) {
        return
      }
      this.hide()
      const confirm = this.answer === 'yes'
      this.$api.winkMsgConfirm(confirm).then(() => {
        if (confirm) {
          this.$emit('confirmed')
        }
      })
    },
  },
  components: {
    GlobalEvents
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    background-color: #252525;
    border-radius: 8px;
    text-align: center;
  }
  .modal-body {
    margin: 50px;
    color: #eeeeee;
    font-size: 28px;
    line-height: 30px;
    white-space: pre-wrap;
  }
  .modal-footer {
    background: $color-dark;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #272727;
    > div {
      border-top: 1px solid #393939;
      line-height: 60px;
    }
  }
  .btn-close, .btn-enter {
    width: 50px;
    height: 25px;
    line-height: 25px;
    color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    &.active {
      background-color: $color-primary;
      color: #000;
    }
  }
  .btn-enter + div {
    margin-left: 30px;
  }
</style>
