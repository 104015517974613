<template>
  <div class="sliders-block" :class="[{active}, `row-${activeRow}`]" :active="active">
    <div v-for="(carousel, index) in carousels" :key="index" class="carousel-container" :class="{active: active && activeRow == index + 1}">
      <div class="carousel-title">
        {{ carousel.title }}
      </div>
      <component
        :is="carousel.component"
        :channel="channel"
        :program="program"
        :active="active && activeRow == index + 1"
        :start-active-time="programsActiveTime"
        :play-time="archiveTime"
        @error="$emit('error', $event)"
        @selected="$emit(carousel.selectedEvent, $event)"
        @change-active-time="$emit('change-active-time', $event)"
      />
    </div>
    <controls-event-bus v-if="active"
      @up="btnUp"
      @down="btnDown"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ChannelPrograms from './ChannelPrograms.vue'
import Recommended from './Recommended.vue'

export default {
  props: ['active', 'channel', 'program', 'archiveTime', 'programsActiveTime'],
  data() {
    return {
      activeRow: 1,
      carousels: [],
    }
  },
  mounted() {
    if (!this.channel.is_parent_control) {
      this.carousels.push({
        title: 'Рекомендации',
        component: 'recommended',
        selectedEvent: 'recommended-selected',
      })
    }
    if (this.channel.has_epg) {
      this.carousels.push({
        title: 'Программа передач',
        component: 'channel-programs',
        selectedEvent: 'program-selected',
      })
    }
  },
  methods: {
    btnUp() {
      if (--this.activeRow < 1) {
        this.activeRow = 1
        this.$emit('hide-up')
      }
    },
    btnDown() {
      if (++this.activeRow > this.carousels.length) {
        this.activeRow = this.carousels.length
      }
    },
  },
  components: {
    ControlsEventBus,
    ChannelPrograms,
    Recommended,
  }
}
</script>

<style lang="scss" scoped>
  .sliders-block {
    height: 350px;
    position: absolute;
    width: 100%;
    overflow: hidden;
    left: 0;
    bottom: -245px;
    opacity: 0.2;
    //transition: 100ms linear;

    &.active {
      bottom: 0;
      opacity: 1;
    }
    &.row-2 .carousel-container:first-child {
      margin-top: -295px;
    }
  }
</style>
