<template>
  <div class="instruction">
    <div class="header-title">Руководство пользователя</div>
    <div class="details-title">
      Ознакомьтесь со всеми функциями устройства и приложения, его настройками и возможностями.
    </div>
    <div class="details">
      <div class="with-link">
        Руководство можно посмотреть <br/>с Вашего смартфона или планшета по ссылке
        <div class="link">https://vezdetv.ru/instruction</div>
      </div>
      или
      <div class="with-qr">
        отсканируйте QR код смартфоном <br/>с экрана телевизора
        <br/><img class="qr-code" src="@/assets/img/qr-code-instruction.png" />
      </div>
    </div>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus @back="btnBack"/>
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'

export default {
  methods: {
    btnBack() {
      this.$router.replace({name: 'account'})
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
  }
}
</script>

<style lang="scss" scoped>
  .instruction {
    color: #fff;
    font-size: 32px;
    text-align: center;
  }
  .details-title {
    width: 800px;
    margin: 50px auto;
    line-height: 1.2;
  }
  .details {
    width: 1050px;
    margin: 0 auto;
  }
  .with-link {
    float: left;
  }
  .with-qr {
    float: right;
  }
  .with-link, .with-qr {
    font-size: 24px;
    margin-top: -5px;
  }
  .qr-code, .link {
    margin-top: 30px;
  }
  .details, .link {
    font-size: 34px;
  }
  .link {
    color: $color-primary;
    text-decoration: underline;
  }
</style>
