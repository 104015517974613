<template>
  <div class="pcode-overlay">
    <modal ref="invalidParentCode">Неправильный ПИН-код!</modal>
    <virtual-keyboard v-model="parentCode" @input="checkParentCode" :digital="true">
      <slot>Введите ПИН-код:</slot>
    </virtual-keyboard>
    <controls-event-bus @back="close"/>
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import VirtualKeyboard from '@/components/VirtualKeyboard.vue'
import Modal from '@/components/Modal.vue'

export default {
  data() {
    return {
      parentCode: '',
    }
  },
  methods: {
    checkParentCode() {
      if (this.parentCode === this.$store.state.accountInfo.parent_code) {
        this.$emit('confirm')
      } else {
        this.$refs.invalidParentCode.show()
      }
    },
    close() {
      this.$emit('close')
    },
  },
  components: {
    ControlsEventBus,
    VirtualKeyboard,
    Modal,
  }
}
</script>

<style>
  .pcode-overlay ~ * {
    filter: blur(3px);
  }
</style>
