<template>
  <div class="controls-block" :class="{active, 'seeker-active': seekerActive}">
    <template v-if="program.begin_time">
      <div class="rewind-hint" :class="{visible: rewindProgress}">
        <div class="rewind-progress" :class="{fwd: rewindProgress > 0, rwd: rewindProgress < 0}">
          {{rewindProgressTime}}
        </div>
      </div>
      <div class="program-info">
        <div class="program-name">
          {{program.full_name}}
        </div>
        <div class="program-mode">
          <span v-if="isLive">ЭФИР</span>
          <span v-else>АРХИВ</span>
        </div>
      </div>
      <div class="progress-bar">
        <div>
          <div class="progress-avail" :style="{width: programProgress}"/>
          <div class="progress" :style="{width: seekerProgress}">
            <div class="progress-pin"/>
          </div>
        </div>
      </div>
      <div class="program-time">
        <div class="time-left">
          {{timeProgress}}
        </div>
        <div class="time-right">
          {{timeTotal}}
        </div>
      </div>
    </template>
    <div class="player-controls" @mouseover="seekerActive = false">
      <div class="controls">
        <div class="btn-red" v-if="hasEpg" @click="btnRed" ref="button-0" :class="{selected: buttonIndex == 0}" @mouseover="buttonIndex = 0"><span>ПРОГРАММА</span></div>
        <div class="no-program" v-else>Данный канал не имеет телепрограммы</div>
        <template v-if="channel.has_record">
          <div class="to-begin" @click="toBegin" ref="button-1" :class="{inactive: !canRewind, selected: buttonIndex == 1}" @mouseover="buttonIndex = 1">СНАЧАЛА</div>
          <div class="btn-rewind" @click="rewind" ref="button-2" :class="{inactive: !canRewind, selected: buttonIndex == 2}" @mouseover="buttonIndex = 2"/>
          <div class="play-pause" @click="playPause" ref="button-3" :class="{paused: paused, selected: buttonIndex == 3}" @mouseover="buttonIndex = 3"/>
          <div class="btn-forward" @click="forward" ref="button-4" :class="{inactive: !canForward, selected: buttonIndex == 4}" @mouseover="buttonIndex = 4"/>
          <div class="to-live" @click="toLive"  ref="button-5" :class="{inactive: isLive, selected: buttonIndex == 5}" @mouseover="buttonIndex = 5">В ЭФИР</div>
        </template>
        <div class="btn-blue" @click="btnBlue" ref="button-6"  :class="{selected: buttonIndex == 6}" @mouseover="buttonIndex = 6"><span>КАНАЛЫ</span></div>
      </div>
      <div class="control-hint" :class="{invisible: !hasEpg}">
        {{controlHint}}
      </div>
    </div>
    <controls-event-bus v-if="active"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
      @rew="rewind"
      @fwd="forward"
      @pause="playPause"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import { mapState } from 'vuex'
import device from '@/device'
import utils from '@/utils'

const SEEK_DELAY = 1000
const REWIND_STEP = 30
const PLAY_PAUSE_INDEX = 3
const BUTTONS_HINTS = [
  'Программа и Архив передач',
  'Смотреть с начала передачи',
  'Отмотать назад',
  ['Пауза', 'Продолжить'],
  'Отмотать вперед',
  'Переключиться на прямую трансляцию канала',
  'Список каналов'
]

let buttonIndex = PLAY_PAUSE_INDEX
let seekerActive = false
let seekTimeout

export default {
  props: ['active', 'channel', 'unixTime', 'initEvent'],
  data() {
    return {
      buttonIndex,
      seekerActive,
      rewindProgress: 0,
      pauseFromLive: true,
    }
  },
  mounted() {
    if (this.paused) {
      this.$store.commit('rewindTime', this.$store.state.player.archiveTime - this.unixTime)
    }
    this.$emit('program-changed', this.program)
    if (this.hasEpg) {
      if (!this.channel.has_record && this.buttonIndex > 0 && this.buttonIndex < BUTTONS_HINTS.length - 1) {
        this.buttonIndex = 0
      }
      switch (this.initEvent) {
        case 'up':
          this.seekerActive = true
          break;
        case 'down':
          this.seekerActive = false
          break;
        case 'left':
          if (!this.seekerActive) {
            this.buttonIndex = 0
          }
          break;
        case 'right':
          if (!this.seekerActive) {
            this.buttonIndex = BUTTONS_HINTS.length - 1
          }
          break;
        case 'enter':
          this.seekerActive = false
          if (this.channel.has_record) {
            this.buttonIndex = PLAY_PAUSE_INDEX
          }
          break;
      }
    } else {
      this.buttonIndex = BUTTONS_HINTS.length - 1
    }
  },
  beforeDestroy() {
    buttonIndex = this.buttonIndex
    seekerActive = this.seekerActive
    this.$store.commit('playerState', {archiveTime: this.archiveTime})
  },
  watch: {
    unixTime(time, prevTime) {
      if (this.paused) {
        this.$store.commit('rewindTime', this.rewindTime - (time - prevTime))
      }
    },
    channel() {
      if (!this.hasEpg) {
        this.seekerActive = false
        this.buttonIndex = BUTTONS_HINTS.length - 1
      }
    },
    program() {
      this.$emit('program-changed', this.program)
    },
  },
  methods: {
    hasRecord(program) {
      return program.begin_time <= this.unixTime && program.end_time > this.unixTime - this.channel.archive_days * 86400
    },
    btnRed() {
      const args = {name: 'epg', params: {id: this.$route.params.id}}
      if (!this.isLive) {
        args.query = {time: this.archiveTime}
      }
      this.$router.replace(args)
    },
    btnBlue() {
      const args = {name: 'tvchannels'}
      this.$router.replace(args)
    },
    toBegin() {
      this.$store.commit('playerState', {rewindTime: this.program.begin_time - this.unixTime, paused: false})
      this.$emit('play', this.channelResumeUrl)
    },
    rewind() {
      if (this.canRewind) {
        clearTimeout(seekTimeout)
        const rewindTime = this.archiveTime - REWIND_STEP > this.archiveStart ? this.rewindTime - REWIND_STEP : this.archiveStart - this.unixTime
        this.$store.commit('playerState', {rewindTime, paused: false})
        if (this.archiveTime > this.archiveStart) {
          this.rewindProgress -= REWIND_STEP
        }
        seekTimeout = setTimeout(() => {
          this.$emit('play', this.channelResumeUrl)
          this.rewindProgress = 0
        }, SEEK_DELAY)
      }
    },
    forward() {
      if (this.canForward) {
        clearTimeout(seekTimeout)
        const rewindTime = this.rewindTime + REWIND_STEP > 0 ? 0 : this.rewindTime + REWIND_STEP
        this.$store.commit('playerState', {rewindTime, paused: false})
        this.rewindProgress += REWIND_STEP
        seekTimeout = setTimeout(() => {
          this.$emit('play', this.channelResumeUrl)
          this.rewindProgress = 0
        }, SEEK_DELAY)
      }
    },
    playPause() {
      if (!this.channel.has_record) {
        return
      }
      if (this.paused) {
        if (this.pauseFromLive) {
          this.$emit('play', this.channelResumeUrl)
        } else {
          device.resume()
        }
      } else {
        this.pauseFromLive = this.isLive
        device.pause()
      }
      this.$store.commit('paused', !this.paused)
    },
    toLive() {
      this.$store.commit('playerState', {rewindTime: 0, paused: false})
      this.$emit('play', this.channel.url)
      this.buttonIndex = PLAY_PAUSE_INDEX
    },
    btnUp() {
      if (this.seekerActive || !this.program.begin_time) {
        this.$emit('hide-up')
      } else {
        this.seekerActive = true
      }
    },
    btnDown() {
      if (this.seekerActive) {
        this.seekerActive = false
      } else {
        this.$emit('hide-down')
      }
    },
    btnLeft() {
      if (this.seekerActive && this.channel.has_record) {
        this.rewind()
      } else if (this.hasEpg) {
        if (--this.buttonIndex < 0 || !this.channel.has_record || (!this.canRewind && this.buttonIndex < PLAY_PAUSE_INDEX)) {
          this.buttonIndex = 0
        } else if ((this.isLive && this.buttonIndex > PLAY_PAUSE_INDEX) || (!this.canForward && this.buttonIndex == PLAY_PAUSE_INDEX + 1)) {
          this.buttonIndex = PLAY_PAUSE_INDEX
        }
      }
    },
    btnRight() {
      if (this.seekerActive && this.channel.has_record) {
        this.forward()
      } else if (this.hasEpg) {
        if (++this.buttonIndex >= BUTTONS_HINTS.length || !this.channel.has_record || (this.isLive && this.buttonIndex > PLAY_PAUSE_INDEX)) {
          this.buttonIndex = BUTTONS_HINTS.length - 1
        } else if (!this.canRewind && this.buttonIndex < PLAY_PAUSE_INDEX) {
          this.buttonIndex = PLAY_PAUSE_INDEX
        } else if (!this.canForward && this.buttonIndex == PLAY_PAUSE_INDEX + 1) {
          ++this.buttonIndex
        }
      }
    },
    btnEnter() {
      if (!this.seekerActive) {
        this.$refs['button-'+this.buttonIndex].click()
      }
    },
  },
  computed: {
    ...mapState({
      rewindTime: state => state.player.rewindTime,
      paused: state => state.player.paused,
    }),
    epg() {
      return this.$store.getters.channelEpg(this.$route.params.id)
    },
    hasEpg() {
      return this.channel.has_epg
    },
    program() {
      if (this.epg.length) {
        const program = this.epg.find(prog => prog.begin_time <= this.archiveTime && prog.end_time > this.archiveTime) || {}
        if (program.end_time) {
          program.next_name = (this.epg.find(prog => prog.begin_time == program.end_time) || {}).full_name
        }
        program.has_record = this.hasRecord(program)
        return program
      } else {
        return {
          name: this.channel.program_name,
          next_name: this.channel.next_program_name,
          begin_time: this.channel.program_begin_time,
          end_time: this.channel.program_end_time,
          has_record: this.channel.has_record,
        }
      }
    },
    channelResumeUrl() {
      return this.isLive ? this.channel.url : this.channel.archive_url + this.archiveTime
    },
    controlHint() {
      if (this.buttonIndex == PLAY_PAUSE_INDEX) {
        return this.paused ? BUTTONS_HINTS[PLAY_PAUSE_INDEX][1] : BUTTONS_HINTS[PLAY_PAUSE_INDEX][0]
      }
      return BUTTONS_HINTS[this.buttonIndex]
    },
    archiveStart() {
      return Math.min(...this.epg
        .filter(prog => this.hasRecord(prog))
        .map(prog => prog.begin_time))
    },
    canRewind() {
      return this.archiveStart < this.archiveTime
    },
    canForward() {
      return !this.isLive
    },
    archiveTime() {
      return this.unixTime + this.rewindTime
    },
    isLive() {
      return this.rewindTime >= 0
    },
    rewindProgressTime() {
      return utils.getAbsoluteTime(utils.dateFromUnixTime(Math.abs(this.rewindProgress)))
    },
    programProgress() {
      return utils.getProgress(this.unixTime, this.program.begin_time, this.program.end_time)
    },
    seekerProgress() {
      return utils.getProgress(this.unixTime + this.rewindTime, this.program.begin_time, this.program.end_time)
    },
    timeProgress() {
      return utils.getAbsoluteTime(utils.dateFromUnixTime(this.unixTime + this.rewindTime - this.program.begin_time))
    },
    timeTotal() {
      return utils.getAbsoluteTime(utils.dateFromUnixTime(this.program.end_time - this.program.begin_time))
    },
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  .controls-block {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 75px;
    color: #fff;
    opacity: 0;
    //transition: 50ms linear;
    &.active {
      opacity: 1;
    }
    &.seeker-active {
      .progress-bar > div {
        margin-top: 8px;
        height: 8px;
        .progress-pin {
          width: 16px;
          height: 16px;
          top: -4px;
          right: -8px;
        }
      }
      .player-controls {
        .controls > div {
          background-color: transparent !important;
          border-color: transparent !important;
        }
        .control-hint {
          visibility: hidden;
        }
      }
      .rewind-hint {
        visibility: visible;
      }
    }
    .rewind-hint {
      width: 950px;
      margin: 0 auto;
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
      .rewind-progress {
        margin: 0 auto;
        width: 140px;
        border-radius: 5px;
        background-color: rgba(0,0,0,0.3);
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        height: 26px;
        text-align: center;
        background-repeat: no-repeat;
        background-position-y: center;
        &.rwd {
          background-image: url(../../assets/img/rewind-rwd.png);
          background-position-x: 10px;
        }
        &.fwd {
          background-image: url(../../assets/img/rewind-fwd.png);
          background-position-x: 119px;
        }
      }
    }
    .program-info {
      width: 950px;
      margin: 0 auto;
      margin-top: 10px;
      .program-name {
        font-size: 21px;
        height: 21px;
        width: 780px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        float: left;
      }
      .program-mode {
        font-size: 18px;
        font-weight: 500;
        height: 20px;
        color: rgba(255,255,255,0.5);
        float: right;
      }
    }
    .progress-bar {
      height: 25px;
      overflow: hidden;
      width: 970px;
      margin: 0 auto;
      > div {
        height: 4px;
        background-color: rgba(255,255,255,0.5);
        margin: 10px;
        position: relative;
      }
      .progress-avail {
        height: 100%;
        max-width: 100%;
        background-color: #C0B694;
      }
      .progress {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FDA90D;
        height: 100%;
        max-width: 100%;
      }
      .progress-pin {
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        right: -4px;
        top: -2px;
      }
    }
    .program-time {
      margin-top: -25px;
      .time-left, .time-right {
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
        height: 22px;
        position: absolute;
      }
      .time-left {
        left: 45px;
        float: left;
      }
      .time-right {
        right: 45px;
        float: right;
      }
    }
    .player-controls {
      width: 950px;
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 50%;
      margin-left: -475px;
      .controls {
        height: 50px;
        > div {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          display: inline-block;
          margin-left: 10px;
          width: 50px;
          height: 100%;
          vertical-align: middle;
          line-height: 50px;
          border-radius: 3px;
          background: no-repeat center center;
          background-color: rgba(255, 255, 255, 0.07);
          border: 1px solid transparent;
          cursor: pointer;
          &.selected {
            background-color: rgba(255,255,255,0.25);
            border: 1px solid rgba(255,255,255,0.30);
          }
          &.inactive {
            background-color: transparent;
            border-color: transparent;
            opacity: 0.15;
            cursor: default;
          }
          &.btn-red {
            margin-left: 0;
            width: 145px;
            text-align: left;
            background-position: 13px 17px;
            float: left;
            background-image: url(../../assets/img/btn-red.png);
          }
          &.no-program {
            margin-left: 0;
            float: left;
            width: auto;
            background: none;
            text-transform: none;
            opacity: 0.5;
          }
          span {
            padding-left: 46px;
          }
          &.rewind-time, &.current-time {
            background-color: transparent;
            width: 65px;
            cursor: default;
          }
          &.rewind-time {
            float: left;
          }
          &.current-time {
            float: right;
          }
          &.to-begin, &.to-live {
            width: 100px;
          }
          &.btn-rewind {
            background-image: url(../../assets/img/btn-rewind.png);
          }
          &.play-pause {
            background-image: url(../../assets/img/btn-pause.png);
            &.paused {
              background-image: url(../../assets/img/btn-play.png);
            }
          }
          &.btn-forward {
            background-image: url(../../assets/img/btn-forward.png);
          }
          &.btn-blue {
            width: 145px;
            text-align: left;
            background-position: 13px 17px;
            float: right;
            background-image: url(../../assets/img/btn-blue.png);
          }
        }
      }
      .controls.paused .play-pause {
        background-image: url(../../assets/img/btn-play.png);
      }
      .control-hint {
        clear: both;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #868686;
        text-transform: lowercase;
      }
    }
  }
</style>
