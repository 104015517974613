<template>
  <div class="settings-view">
    <parent-code @confirm="doConfirmed" @close="confirmAction = null" v-if="confirmAction && !confirmed"/>
    <div class="header-title">Настройки</div>
    <div class="settings-group" v-for="(group, gIndex) in menu" :key="gIndex">
      <div>{{group.title}}</div>
      <ul class="settings-menu">
        <li v-for="(item, iIndex) in group.items" :key="iIndex" :class="{current: gIndex == groupIndex && iIndex == itemIndex}">
          {{item.title}}
          <div class="value" v-if="item.values">
            <div class="arrow-left" @click="btnLeft"/>
            {{valueText(gIndex, iIndex)}}
            <div class="arrow-right" @click="btnRight"/>
          </div>
        </li>
      </ul>
    </div>
    <controls-hint-bar>
      <div class="btn-group">
        <control-hint-btn type="left" @click="btnLeft"/>
        <control-hint-btn type="right" @click="btnRight"/>
        <span>Изменить</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="enter" @click="btnEnter"/>
        <span>Выбор</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus v-if="confirmed || !confirmAction"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
      @enter="btnEnter"
      @back="btnBack"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import ParentCode from '@/components/ParentCode.vue'
import device from '@/device'
import { mapState } from 'vuex'

let lastGroupIndex = 0, lastItemIndex = 0

export default {
  data() {
    return {
      confirmAction: null,
      confirmed: false,
      groupIndex: lastGroupIndex,
      itemIndex: lastItemIndex,
      menu: [
        {title: 'Системные настройки', items: [
          {title: 'Wi-Fi', action: () => device.showSystemSettings('wifi')},
          {title: 'Экран', action: () => device.showSystemSettings('display')},
          {title: 'Общие', action: () => device.showSystemSettings('all')},
        ]},
        {title: 'Персональные настройки', items: [
          {name: 'confirmPacketLink', title: 'Подтверждать подключение пакета PIN-кодом', values: ['Нет', 'Да'], needConfirm: true},
          {name: 'hideAdultChannels', title: 'Скрывать в списке "Взрослые" каналы', values: ['Нет', 'Да'], needConfirm: true},
          {name: 'hideUnlinkedChannels', title: 'Скрывать не подключенные каналы', values: ['Нет', 'Да']},
        ]},
        /*{title: 'Прочее', items: [
          //{name: 'useAnimation', title: 'Анимация интерфейса', values: ['Нет', 'Да']},
          {name: 'bigCarousel', title: 'Размер карусели', values: ['Обычно', 'Крупно']},
        ]},*/
      ],
    }
  },
  beforeDestroy() {
    lastGroupIndex = this.groupIndex
    lastItemIndex = this.itemIndex
  },
  computed: {
    ...mapState(['settings']),
    menuGroup() {
      return this.menu[this.groupIndex]
    },
    menuItem() {
      return this.menuGroup.items[this.itemIndex]
    },
  },
  methods: {
    valueText(gIndex, iIndex) {
      const setting = this.menu[gIndex].items[iIndex]
      return setting.values[this.settings[setting.name]]
    },
    btnUp() {
      if (--this.itemIndex < 0) {
        if (--this.groupIndex < 0) {
          this.groupIndex = this.menu.length - 1
        }
        this.itemIndex = this.menu[this.groupIndex].items.length - 1
      }
    },
    btnDown() {
      if (++this.itemIndex >= this.menuGroup.items.length) {
        if (++this.groupIndex >= this.menu.length) {
          this.groupIndex = 0
        }
        this.itemIndex = 0
      }
    },
    btnEnter() {
      if (this.menuItem.action) {
        this.menuItem.action()
      }
    },
    doConfirmed() {
      this.confirmed = true
      this[this.confirmAction]()
    },
    btnLeft() {
      if (!this.menuItem.values) {
        return
      }
      if (this.menuItem.needConfirm && !this.confirmAction) {
        this.confirmAction = 'btnLeft'
        return
      }
      let settingIndex = this.settings[this.menuItem.name]
      if (--settingIndex < 0) {
        settingIndex = this.menuItem.values.length - 1
      }
      this.$store.commit('setSetting', {name: this.menuItem.name, value: settingIndex})
    },
    btnRight() {
      if (!this.menuItem.values) {
        return
      }
      if (this.menuItem.needConfirm && !this.confirmAction) {
        this.confirmAction = 'btnRight'
        return
      }
      let settingIndex = this.settings[this.menuItem.name]
      if (++settingIndex >= this.menuItem.values.length) {
        settingIndex = 0
      }
      this.$store.commit('setSetting', {name: this.menuItem.name, value: settingIndex})
    },
    btnBack() {
      this.$router.replace({name: 'home'})
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    ParentCode,
  }
}
</script>

<style lang="scss" scoped>
  .settings-group {
    margin-left: 50px;
    font-size: 28px;
    color: rgba(255,255,255,0.9);
  }
  .settings-menu {
    margin-bottom: 10px;
    width: 950px;
    li {
      color: rgba(255,255,255,0.6);
      font-size: 24px;
      font-weight: 400;
      line-height: 45px;
      margin-top: 5px;
      border-radius: 5px;
      padding-left: 25px;
      &.current {
        color: $color-dark;
        background-color: $color-primary;
        .arrow-left, .arrow-right {
          display: block;
        }
      }
      .label {
        float: left;
        padding: 0 0 0 16px;
      }
      .value {
        float: right;
        text-align: center;
        width: 220px;
        text-transform: uppercase;
        border-radius: 5px;
        font-size: 22px;
      }
    }
    .arrow-left {
      display: none;
      float: left;
      margin: 13px 0 0 16px;
      background: url("../assets/img/arrow-left-bold-dark.svg") no-repeat center center;
      width: 20px;
      height: 20px;
    }
    .arrow-right {
      display: none;
      float: right;
      margin: 13px 16px 0 0;
      background: url("../assets/img/arrow-right-bold-dark.svg") no-repeat center center;
      width: 20px;
      height: 20px;
    }
  }
</style>
