<template>
  <div class="multi-series">
    <div class="play-variant" v-for="(item, vIndex) in variants" :key="vIndex" :class="{active: active && vIndex == variantIndex, hidden: vIndex < variantIndex}">
      <div>{{ item.title }}</div>
      <div class="play-buttons">
        <div class="play-button" v-for="(ep, eIndex) in item.series" :key="eIndex" :class="{active: eIndex == btnIndex, locked: !ep.is_avail}">
          {{ ep.episode }}
        </div>
      </div>
    </div>
    <controls-event-bus v-if="active"
      @enter="btnEnter"
      @up="btnUp"
      @down="btnDown"
      @left="btnLeft"
      @right="btnRight"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

export default {
  props: ['contentId', 'series', 'active'],

  data() {
    return {
      variantIndex: 0,
      btnIndex: 0,
    }
  },
  methods: {
    btnEnter() {
      this.$emit('content-selected', this.currentVariant.series[this.btnIndex] || {})
    },
    btnUp() {
      if (--this.variantIndex < 0) {
        this.variantIndex = 0
      }
      if (this.btnIndex > this.currentVariant.series.length - 1) {
        this.btnIndex = this.currentVariant.series.length - 1
      }
    },
    btnDown() {
      if (++this.variantIndex > this.variants.length - 1) {
        this.variantIndex = this.variants.length - 1
        this.$emit('bound-down')
      }
      if (this.btnIndex > this.currentVariant.series.length - 1) {
        this.btnIndex = this.currentVariant.series.length - 1
      }
    },
    btnLeft() {
      if (--this.btnIndex < 0) {
        this.btnIndex = 0
      }
    },
    btnRight() {
      if (++this.btnIndex > this.currentVariant.series.length - 1) {
        this.btnIndex = this.currentVariant.series.length - 1
      }
    },
  },
  computed: {
    variants() {
      const result = []
      const variants = {}
      this.series.forEach(ep => {
        ep.play.forEach(play => {
          if (!variants[play.type]) {
            variants[play.type] = {
              title: play.title,
              type: play.type,
              series: []
            }
          }
          variants[play.type].series.push(Object.assign({episode: ep.episode, content_id: this.contentId}, play))
        })
      })
      Object.keys(variants).forEach(variant => result.push(variants[variant]))
      return result
    },
    currentVariant() {
      return this.variants[this.variantIndex] || {series: []}
    }
  },
  components: {
    ControlsEventBus
  }
}
</script>

<style lang="scss" scoped>
  .play-variant {
    font-size: 20px;
    color: #ccc;
    margin-bottom: 20px;
    &.active .play-button.active {
      background-color: $color-primary;
    }
  }
  .play-buttons {
    padding-left: 20px;
  }
  .play-button {
    display: inline-block;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    width: 195px;
    font-size: 20px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url(../../assets/img/play-circle-outline.svg) no-repeat;
    background-color: #ddd;
    background-position: 10px 5px;
    background-size: 30px;
    padding-left: 45px;
    &.locked {
      background-image: url(../../assets/img/lock-black.svg);
      background-size: 26px;
      background-position: 12px 7px;
    }
  }
  .hidden {
    display: none;
  }
</style>
