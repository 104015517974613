<template>
  <div class="play-buttons">
    <div class="play-button" v-for="(btn, index) in buttons"
      :key="btn.type"
      :class="[btn.type, {active: active && index == btnIndex, locked: !btn.is_avail}]"
    >
      {{ btn.title }}
    </div>
    <controls-event-bus v-if="active"
      @enter="btnEnter"
      @left="btnLeft"
      @right="btnRight"
      @down="$emit('bound-down')"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'

export default {
  props: ['buttons', 'contentId', 'active'],

  data() {
    return {
      btnIndex: 0
    }
  },
  methods: {
    btnEnter() {
      const content = this.buttons[this.btnIndex] || {}
      content.content_id = this.contentId
      this.$emit('content-selected', content)
    },
    btnLeft() {
      if (--this.btnIndex < 0) {
        this.btnIndex = 0
      }
    },
    btnRight() {
      if (++this.btnIndex > this.buttons.length - 1) {
        this.btnIndex = this.buttons.length - 1
      }
    },
  },
  components: {
    ControlsEventBus,
  }
}
</script>

<style lang="scss" scoped>
  .play-buttons {
    padding-left: 20px;
  }
  .play-button {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    width: 195px;
    font-size: 20px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url(../../assets/img/play-circle-outline.svg) no-repeat;
    background-color: #ddd;
    background-position: 10px 5px;
    background-size: 30px;
    padding-left: 45px;
    &.active {
      background-color: $color-primary;
    }
    &.locked {
      background-image: url(../../assets/img/lock-black.svg);
      background-size: 26px;
      background-position: 12px 7px;
    }
  }
</style>
