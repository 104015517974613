<template>
  <div class="ch-packets-view">
    <parent-code @confirm="linkPacket" v-if="confirmPacketLink" />
    <modal ref="confirmModal" @confirm="packetAction" :need-confirm="needConfirm">
      <span v-if="currentPacket.is_linked">
        <div class="cant-unlink-info" v-if="currentPacket.cant_unlink">
          <div class="warning-icon" />
          Подписка на пакет <nobr>«{{currentPacket.title}}»</nobr> активна до <nobr>{{canUnlinkDate}}.</nobr>
          <br/>
          Отключение пакета в разделе «Пакеты каналов» будет доступно <nobr>{{canUnlinkDate}}</nobr> или позже.
          <div class="contact-info">
            Подробная информация по телефону информационной службы: 8(423) 2-302-500
          </div>
        </div>
        <span v-else>
          Отключить пакет <nobr>«{{currentPacket.title}}»?</nobr>
        </span>
      </span>
      <span v-else-if="currentPacket.is_demo_available">
        Подключение ознакомительного просмотра пакета – «{{currentPacket.title}}».
        <b>Внимание!</b> Бесплатный ознакомительный просмотр закончится <nobr>{{currentPacket.demo_date}},</nobr>
        далее стоимость пакета <nobr>«{{currentPacket.title}}» составит - {{currentPacket.cost}} ₽/мес.</nobr><br />
        Подключить пакет?
      </span>
      <span v-else>
        Стоимость пакета <nobr>«{{currentPacket.title}}» – {{currentPacket.cost}} ₽/мес.</nobr><br />
        Минимальный срок подключения на {{currentPacket.min_link_days}} дней.<br /> Подключить пакет?
      </span>
    </modal>
    <div class="header-title">Пакеты каналов</div>
    <div class="packets-container" :class="{'channels-visible': channelsVisible}" v-if="currentPacket.packet_id">
      <paged-list class="pk-list"
        :active="activeColumn == 0"
        :items="packets"
        :perPage="8"
        v-model="packetIndex"
        @mouseover="activeColumn = 0"
      >
        <template slot-scope="{ item }">
          <div class="pk-name">{{item.title}}</div>
          <div class="pk-ch-count">{{item.channels_text}}</div>
          <div class="pk-description" v-if="!channelsVisible">{{item.short_description}}</div>
        </template>
      </paged-list>
      <div class="pk-sidebar">
        <paged-list class="pk-channel-list" ref="chList"
          :active="activeColumn == 1"
          :items="packetChannels"
          :perPage="6"
          v-model="channelIndex"
          @mouseover="activeColumn = 1"
        >
          <template slot-scope="{ item }">
            <i class="ch-icon chi-54" :class="`ch-${item.icon_id}`"/>
            {{item.name}}
          </template>
        </paged-list>
        <div class="pk-info" :class="{active: activeColumn == 2}">
          <div class="packet-info-title">{{currentPacket.title}}</div>
          <img class="packet-info-img" :src="currentPacket.img"/>
          <p class="packet-info-desc">{{currentPacket.description}}</p>
          <ul class="packet-info-buttons" @mouseover="activeColumn = 2">
            <li @mouseover="buttonIndex = 0" @click="btnInfo" :class="{current: buttonIndex == 0}">
              <span v-if="channelsVisible">Скрыть</span>
              <span v-else>Открыть</span>
              список каналов
            </li>

            <li @mouseover="() => {if(!currentPacket.is_unlinkable) {buttonIndex = 1}}" @click="linkUnlink" :class="[{current: buttonIndex == 1}, currentPacket.class_name]">
              <span v-if="currentPacket.is_unlinkable">
                Входит в Ваш тариф
              </span>
              <span v-else-if="currentPacket.is_linked">
                Отключить пакет
              </span>
              <span v-else-if="currentPacket.is_demo_available">
                Подключить бесплатно на {{currentPacket.demo_days}} дней
              </span>
              <span v-else>
                Подключить за <b>{{currentPacket.cost}}</b> р/мес
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <progress-loader ref="pbInfo"/>
    <controls-hint-bar>
      <div class="btn-group" :class="{inactive: currentPacket.is_linked || !currentPacket.id}">
        <control-hint-btn type="green" @click="btnGreen"/>
        <span>Подключить</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="info" @click="btnInfo"/>
        <span>Подробно</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="menu" @click="btnMenu"/>
        <span>Меню</span>
      </div>
      <div class="btn-group">
        <control-hint-btn type="back" @click="btnBack"/>
        <span>Назад</span>
      </div>
    </controls-hint-bar>
    <controls-event-bus v-if="!confirmPacketLink"
      @up="changeButton"
      @down="changeButton"
      @left="btnLeft"
      @right="btnRight"
      @green="btnGreen"
      @info="btnInfo"
      @back="btnBack"
      @enter="btnEnter"
    />
  </div>
</template>

<script>
import ControlsEventBus from '@/components/ControlsEventBus.vue'
import ControlsHintBar from '@/components/ControlsHintBar.vue'
import ControlHintBtn from '@/components/ControlHintBtn.vue'
import PagedList from '@/components/PagedList.vue'
import Modal from '@/components/Modal.vue'
import ParentCode from '@/components/ParentCode.vue'
import ProgressLoader from '@/components/ProgressLoader.vue'
import utils from '@/utils'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      packetIndex: 0,
      buttonIndex: 0,
      channelIndex: 0,
      activeColumn: 0,
      channelsVisible: false,
      modalText: null,
      parentCode: '',
      needParentCode: false,
    }
  },
  mounted() {
    this.$store.dispatch('updatePackets')
  },
  watch: {
    activeColumn(newColumn, oldColumn) {
      if (newColumn == 1 && !this.channelsVisible) {
        if (newColumn > oldColumn) {
          ++this.activeColumn
        } else {
          --this.activeColumn
        }
      }
      if (newColumn < 0) {
        this.activeColumn = 0
      } else if (newColumn > 2) {
        this.activeColumn = 2
      } else if (newColumn == 2) {
        if (this.currentPacket.is_linked || this.currentPacket.is_unlinkable) {
          this.buttonIndex = 0
        } else {
          this.buttonIndex = 1
        }
      }
    },
    packetIndex() {
      this.$refs.chList.reset()
      if (this.currentPacket.is_unlinkable) {
        this.buttonIndex = 0
      }
    }
  },
  methods: {
    changeButton() {
      if (this.activeColumn == 2) {
        if (this.currentPacket.is_unlinkable) {
          return
        }
        if (this.buttonIndex == 0) {
          this.buttonIndex = 1
        } else {
          this.buttonIndex = 0
        }
      }
    },
    btnLeft() {
      --this.activeColumn
    },
    btnRight() {
      ++this.activeColumn
    },
    btnEnter() {
      if (this.activeColumn == 0) {
        this.activeColumn = 2
      } else if (this.activeColumn == 2) {
        if (this.buttonIndex == 0) {
          this.btnInfo()
        } else {
          this.linkUnlink()
        }
      }
    },
    packetAction() {
      if (!this.needConfirm) {
        return
      }

      let action = this.$api.linkPacket
      if (this.currentPacket.is_linked) {
        action = this.$api.unlinkPacket
      } else if (this.$store.state.settings.confirmPacketLink) {
        this.needParentCode = true
        return
      }
      this.$refs.pbInfo.show('loader', 'Выполняется '+(this.currentPacket.is_linked ? 'отключение' : 'подключение')+' пакета, подождите…')
      action(this.currentPacket.id).then(res => {
        this.$refs.pbInfo.show(res.type, res.message, 3000)
        this.$store.dispatch('packetsChanged')
          .then(() => {
            if (!this.$store.getters.playingChannel.is_avail) {
              this.$store.dispatch('stop')
            }
          })
      }).catch(() => this.$refs.pbInfo.show('error', 'Не удалось выполнить операцию! Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    },
    linkPacket() {
      this.needParentCode = false
      this.$refs.pbInfo.show('loader', 'Выполняется подключение пакета, подождите…')
      this.$api.linkPacket(this.currentPacket.id).then(res => {
        this.$refs.pbInfo.show(res.type, res.message, 3000)
        this.$store.dispatch('packetsChanged')
      }).catch(() => this.$refs.pbInfo.show('error', 'Не удалось выполнить операцию! Попробуйте позже или обратитесь в тех. поддержку.', 3000))
    },
    linkUnlink() {
      if (!this.currentPacket.id || this.currentPacket.is_unlinkable) {
        return
      }
      this.activeColumn = 2
      this.$refs.confirmModal.show()
    },
    btnGreen() {
      if (!this.currentPacket.is_linked) {
        this.linkUnlink()
      }
    },
    btnInfo() {
      this.channelsVisible = !this.channelsVisible
      if (this.channelsVisible) {
        this.activeColumn = 1
      } else if (this.activeColumn == 1) {
        this.activeColumn = 2
      }
    },
    btnBack() {
      this.$router.replace({name: 'account'})
    },
    btnMenu() {
      this.$router.replace({name: 'home'})
    },
  },
  computed: {
    ...mapState(['packets', 'channels']),
    confirmPacketLink() {
      return this.needParentCode && this.$store.state.settings.confirmPacketLink
    },
    currentPacket() {
      return this.packets[this.packetIndex] || {}
    },
    packetChannels() {
      return this.channels.filter(channel => channel.packet_id == this.currentPacket.id)
    },
    needConfirm() {
      if (this.currentPacket.is_linked && this.currentPacket.cant_unlink) {
        return false
      }
      return !this.currentPacket.is_unlinkable
    },
    canUnlinkDate() {
      const d = utils.dateFromUnixTime(this.currentPacket.link_date + this.currentPacket.min_link_days * 86400)
      return utils.getDay(d) + ' ' + utils.getMonth(d) + ' ' + utils.getYear(d)
    },
  },
  components: {
    ControlsEventBus,
    ControlsHintBar,
    ControlHintBtn,
    PagedList,
    ProgressLoader,
    ParentCode,
    Modal,
  }
}
</script>

<style lang="scss">
.ch-packets-view {
  .cant-unlink-info {
    text-align: left;
    font-size: 22px;
    .warning-icon {
      width: 80px;
      height: 110px;
      float: left;
      margin-right: 50px;
      background-image: url(../assets/img/icon-operation-warning.png);
      background-size: 80px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .contact-info {
      margin-top: 20px;
      font-size: 16px;
      text-align: center;
    }
  }
  .packets-container {
    width: 1200px;
    margin: 0 auto;
    &.channels-visible {
      .pk-list {
        width: 420px;
      }
      .pk-sidebar {
        width: 730px;
      }
      .pk-channel-list {
        display: inline-block;
      }
    }
    li {
      cursor: pointer;
    }
  }
  .pk-list {
    display: inline-block;
    width: 790px;
    height: 485px;
    overflow: hidden;

    &.active .current {
      background-color: $color-primary;
      > div {
        color: #171717 !important;
      }
    }
    .current {
      background-color: #383838;
      > div {
        color: $color-primary !important;
      }
    }
    li {
      height: 60px;
      line-height: 60px;
      border-radius: 5px;
      > div {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .pk-name {
        color: $color-primary;
        font-size: 22px;
        font-weight: bold;
        padding-left: 26px;
        width: 225px;
      }
      .pk-ch-count {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
        width: 162px;
      }
      .pk-description {
        color: #BABABA;
        font-size: 16px;
        width: 370px;
      }
    }
  }
  .pk-sidebar {
    margin-left: 35px;
    display: inline-block;
    background-color: rgba(0,0,0,0.6);
    border-radius: 8px;
    width: 360px;
    height: 485px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    .pk-channel-list {
      display: none;
      width: 335px;
      height: 450px;
      margin: -6px 40px -6px -6px;
      &.active .current {
        background-color: $color-primary;
        color: #000;
      }
      .current {
        background-color: #383838;
      }
      li {
        height: 74px;
        line-height: 74px;
        border-radius: 5px;
        color: #DDDDDD;
        font-size: 18px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .ch-icon {
          margin: 10px 12px 6px 6px;
          border-radius: 3px;
          float: left;
        }
      }
    }
    .pk-info {
      float: right;
      height: 100%;
      width: 320px;
      &.active li.current {
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
    .packet-info-title {
      color: #ddd;
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 9px;
      font-weight: 400;
    }
    .packet-info-img {
      margin-bottom: 10px;
      width: 320px;
      height: 200px;
      border-radius: 4px;
      display: block;
    }
    .packet-info-desc {
      margin-bottom: 18px;
      color: #CCC;
      font-size: 16px;
      line-height: 18px;
      height: 72px;
      overflow: hidden;
    }
    .packet-info-buttons {
      width: 100%;
      li {
        border: 1px solid #FFFFFF;
        height: 38px;
        line-height: 38px;
        margin-bottom: 20px;
        border-radius: 5px;
        background-color: #DDD;
        color: #000;
        font-size: 20px;
        text-align: center;
        background-repeat: no-repeat;

        &.disabled {
          background: #1E1E1E url(../assets/img/packets/icon-disabled.png) no-repeat 30px center;
          border-color: #555;
          color: #666;
        }
        &.unlink {
          background-image: url(../assets/img/packets/icon-unlink.png);
          background-position: 32px center;
        }
        &.cant-unlink {
          background-image: url(../assets/img/packets/icon-cant-unlink.png);
          background-position: 32px center;
        }
        &.demo {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
