export default {
  48: "digit", 49: "digit", 50: "digit", 51: "digit", 52: "digit", 53: "digit", 54: "digit", 55: "digit", 56: "digit", 57: "digit",
  96: "digit", 97: "digit", 98: "digit", 99: "digit", 100: "digit", 101: "digit", 102: "digit", 103: "digit", 104: "digit", 105: "digit",
  33: "ch_plus", 412: "ch_plus", 166: "ch_plus",
  34: "ch_minus", 413: "ch_minus", 167: "ch_minus",
  38: "up", 19: "up",
  39: "right", 22: "right",
  40: "down", 20: "down",
  37: "left", 21: "left",
  13: "enter", 66: "enter", //23: "enter",
  27: "back", 111: "back", 4: "back",
  67: "exit", 117: "exit",
  8: "del",
  196: "info", 165: "info",
  112: "red", 120: "red", 269: "red", 514: "red", 312: "red", 131: "red", 183: "red", 225: "red", 220: "red",
  113: "green", 121: "green", 266: "green", 515: "green", 313: "green", 132: "green", 184: "green", 224: "green", 223: "green",
  114: "yellow", 122: "yellow", 267: "yellow", 516: "yellow", 314: "yellow", 133: "yellow", 185: "yellow",
  115: "blue", 123: "blue", 268: "blue", 517: "blue", 315: "blue", 134: "blue", 186: "blue",
  25: "vol_minus", 46: "vol_minus",
  24: "vol_plus", 45: "vol_plus",
  82: "menu",
  87: "fwd", 90: "fwd",
  88: "rew", 89: "rew",
  85: "pause",
  86: "power",
  116: "stop",
  164: "mute",
  140: "aspect",
  145: "record", 130: "record"
}
